import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AjoutprodComponent} from './components/ajoutprod/ajoutprod.component';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {ProduitComponent} from './components/produit/produit.component';
import { AchatComponent}from './components/achat/achat.component';
import { Achat2Component}from './components/achat2/achat2.component';
import { PrivacyComponent}from './components/privacy/privacy.component';
import { AssistanceComponent}from './components/assistance/assistance.component';
import { Achat3Component}from './components/achat3/achat3.component';
import { RechercheComponent}from './components/recherche/recherche.component';
import { Recherche2Component}from './components/recherche2/recherche2.component';
import { RecherchemobileComponent}from './components/recherchemobile/recherchemobile.component';
import {PvendeurComponent} from './components/pvendeur/pvendeur.component';
import{OrderComponent} from'./components/order/order.component';
import {DabonnerComponent} from'./components/dabonner/dabonner.component';
import {AbonnertailleComponent} from'./components/abonnertaille/abonnertaille.component';
import {AbonnerAdressComponent} from'./components/abonner-adress/abonner-adress.component';
import {AbonnerinfoComponent} from'./components/abonnerinfo/abonnerinfo.component';
import {IscriptionComponent} from'./components/iscription/iscription.component';
import {CommandesComponent} from'./components/commandes/commandes.component';
import {NotifComponent} from'./components/notif/notif.component';
import {VefnumComponent} from'./components/vefnum/vefnum.component';
import {PseudoimageComponent} from'./components/pseudoimage/pseudoimage.component';
import {TableaubordComponent} from'./components/tableaubord/tableaubord.component';
import {CommandeconfirmerComponent} from'./components/commandeconfirmer/commandeconfirmer.component';
import {CommandeenvoyerComponent }from'./components/commandeenvoyer/commandeenvoyer.component';
import {CommandelivrerComponent }from'./components/commandelivrer/commandelivrer.component';
import {CommandefinaliserComponent }from'./components/commandefinaliser/commandefinaliser.component';
import {CommandeannulerComponent }from'./components/commandeannuler/commandeannuler.component';
const routes: Routes = [
  // contact : localhost:4200/contact
 // {path: '', component: HomeComponent},
 {path: 'login', component: LoginComponent},
  {path: 'addpro', component: AjoutprodComponent},
  {path: 'home', component: HomeComponent},
  {path: '', component: HomeComponent},
  {path:'produit/:id' ,component: ProduitComponent},
 
  {path:'achat/:id' ,component: AchatComponent},
  {path:'achat2/:id' ,component: Achat2Component},
  {path:'achat3/:id/:num' ,component: Achat3Component},
  {path:'recherche' ,component: RechercheComponent},
  {path:'recherche2' ,component: Recherche2Component},
  {path:'rechmobile' ,component: RecherchemobileComponent},
  {path:'profileV/:email' ,component: PvendeurComponent},
  {path:'orders' ,component: OrderComponent},
  {path:'profil' ,component: DabonnerComponent},
  {path:'taille' ,component: AbonnertailleComponent},
  {path:'address' ,component: AbonnerAdressComponent},
  {path:'infoooo' ,component: AbonnerAdressComponent},
  {path:'inscri' ,component: IscriptionComponent },
  {path:'commandes' ,component: CommandesComponent},
  {path:'notif' ,component: NotifComponent },
  {path:'verfier' ,component: VefnumComponent },
  {path:'image' ,component: PseudoimageComponent },
  {path:'tab/tab' ,component: TableaubordComponent },
  {path:'tab/tab/confirmer' ,component: CommandeconfirmerComponent},
  {path:'tab/tab/envoyer' ,component: CommandeenvoyerComponent },
  {path:'tab/tab/livrer' ,component: CommandelivrerComponent },
  {path:'tab/tab/finaliser' ,component: CommandefinaliserComponent },
  {path:'tab/tab/annuler' ,component: CommandeannulerComponent },
  {path:'privacy' ,component:PrivacyComponent },
  {path:'assistance' ,component:AssistanceComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
