import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ProduitService}from '../../providers/produit.service';
import {SusersService}from'../../providers/susers.service';
import {SabonnerService}from '../../providers/sabonner.service';
@Component({
  selector: 'app-infouser-p',
  templateUrl: './infouser-p.component.html',
  styleUrls: ['./infouser-p.component.css']
})
export class InfouserPComponent implements OnInit {
user:any;
id:any;
product:any;
abonner:any={};
abonner5:any;
article:any;
suivie:any;

  constructor( private activatedRoute:ActivatedRoute,
    private Produitsservice:ProduitService,
    private suserservice:SusersService,
    private sabonner:SabonnerService ) { }

  ngOnInit(): void {
    //this.user = JSON.parse(localStorage.getItem('connectedUser'));

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log("id: "+this.id);
    this.Produitsservice.getProduitByIdService(this.id).subscribe(
      data => {
        if (data)
        {
        this.product = data.produit;
        console.log("email;"+this.product.id_user);
      this.suserservice.getuserbyemail(this.product.id_user).subscribe(


        data=>
        {

this.user=data.user

this.suserservice.getarticleabonner(this.user._id).subscribe(

  data => {
     this.article = data;
     console.log('articles:'+this.article)
   }
 )

 this.suserservice.getsuivieabonner(this.user._id).subscribe(

  data => {
     this.suivie = data;
     console.log('suivie:'+this.suivie)
   }
 )

 this.suserservice.getuserabonner(this.user._id).subscribe(

  data => {
     this.abonner = data;
     console.log('abonner:'+this.abonner)
   }
 )
        }
      )  
      
      }
      }
    )




    
  }
  addabooner()
  {
    this.abonner.suivie=this.product.email_user;
    this.abonner.abonner=this.user.email;
    this.sabonner.add(this.abonner).subscribe(
    
      () => {
        console.log('produitadded successfully');
      }
    )


  }

  

}
