import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reclamationconfirmer',
  templateUrl: './reclamationconfirmer.component.html',
  styleUrls: ['./reclamationconfirmer.component.css']
})
export class ReclamationconfirmerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
