<div class="titre"><span>Achat sécurisé</span></div>

<div class="row">


    
        <div class="card text-center">
            <div class="card-block">
                <img class="card-img-top" src="{{product.image_1}}" alt="">

            </div>

            <div class="card-block">
                <div class="row">
                   
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">marque</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.marque}}</span>

                    </div>
                </div>

                

                <div class="row">

                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">prix</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.prix}} DT</span>

                    </div>
                </div>
              

                <div class="row">

                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">Frais de livraison</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">7DT</span>

                    </div>
                </div>

               <hr>

   <div class="row">

                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">Total à payer:</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.prix+7}} DT</span>

                    </div>

                </div>
                

            </div>

       
        <hr>
     <!--  <div class="form-group"   >
               
            <div ><input type="radio"  checked name="choice" value="1" id="choice-1"  [(ngModel)]="payment"  required   checked>
              <label for="choice-1">Payment a la livraison</label></div>
            <div><input type="radio" name="choice" value="2" id="choice-2" name='payment'  [(ngModel)]="payment"  required>
              <label for="choice-2">Carte-edinar
              </label></div>
            <div><input type="radio" name="choice" value="3" id="choice-3"  name='payment'  [(ngModel)]="payment"   required>
              <label for="choice-3">Carte bancaire</label></div>
          </div>

          <hr> 

          <input type="radio" value="male" name="gender"  checked ngModel> Male
          <input type="radio" value="female" name="gender" ngModel> Female
          <input type="radio" id="huey" name="drone" value="huey"  
          checked>
           <button type="submit" class="btn btn-success">suivant</button>
      
    </div>--> 

    <form [formGroup]="form" (ngSubmit)="submit()">
           
        <div class="form-group">
            <label for="gender">Methode de payment:</label>
            <div>
              <input id="male" type="radio" value="1" name="gender" formControlName="gender"  [(ngModel)]="payment" checked>
              <label for="male">Payment a la livraison</label>
           </div>
  
       
        
        </div>
        
        <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Acheter</button>
    </form>
    </div>

  


