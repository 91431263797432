<div class="card text-center">



    <!--  <form [formGroup]="form" (ngSubmit)="submit()">

        <span class="entete">article trouvé</span>

        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <span class="entete">Catégorie</span>

        <div class="form-group">
            <label for="gender">Methode de payment:</label>
            <div>
                <input id="male" type="radio" value="1" name="gender" formControlName="gender" [(ngModel)]="payment"
                    checked>
                <label for="male">Payment a la livraison</label>
            </div>

            <div>
                <input id="female" type="radio" value="2" name="gender" formControlName="gender" [(ngModel)]="payment">
                <label for="female">Carte-edinar</label>
            </div>
            <div>
                <input id="female" type="radio" value="3" name="gender" formControlName="gender" [(ngModel)]="payment">
                <label for="female">Carte bancaire</label>
            </div>



        </div>


<button class="btn btn-outline-success my-2 my-sm-0" type="submit" >Search</button>


</form>-->
    <form #platForm='ngForm' (ngSubmit)='submit()'>



        <div class="col-12">
            <div class="form-group">


                <input class="form-control" type="search" [(ngModel)]='nom' [ngModelOptions]="{standalone: true}"
                    id="subject" type="text" placeholder='search'>

            </div>
        </div>

        <div class="row">
            <div class="col-12">

            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-6">
                    <div class="reg">
                        <label>
                            <input id="male" type="radio" value='Femme' name="gender"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]='gender'
                                (change)="changeGender($event)" checked>
                            <img class="avatar"
                                src="https://lebesty.com:3000/images/femme.png">
                        </label>

                        <div>
                            <span class="nomG">Femmes</span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="reg">
                        <label>
                            <input id="male" type="radio" value="Fille" name="gender"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]='gender'
                                (change)="changeGender($event)">
                            <img class="avatar"
                                src="https://lebesty.com:3000/images/fille.png">
                        </label>

                        <div>
                            <span class="nomG">Filles</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="reg">
                        <label>
                            <input id="male" type="radio" value="Homme" name="gender"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]='gender'
                                (change)="changeGender($event)">
                            <img class="avatar"
                                src="https://lebesty.com:3000/images/homme.png">
                        </label>
                        <div>
                            <span class="nomG">Hommes</span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="reg">
                        <label>
                            <input id="male" type="radio" value="Garcon" name="gender"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]='gender'
                                (change)="changeGender($event)">
                            <img class="avatar"
                                src="https://lebesty.com:3000/images/garcon.png">
                        </label>

                        <div>
                            <span class="nomG">Garcons</span>
                        </div>
                    </div>
                </div>
            </div>




        </div>





        <div class="col-12">
            <ng-select [items]="Scategory" [(ngModel)]="Scategorie" bindLabel="nom" bindValue="nom"  [maxSelectedItems]="2"
                [ngModelOptions]="{standalone: true}"   [multiple]="true"
                placeholder='Categorie'>
                <ng-template ng-option-tmp let-item="item">

                    {{item.nom}} <br />


                </ng-template>

            </ng-select>
        </div>


        <br>


        <div class="col-12">
            <ng-select [items]="Marques" [(ngModel)]="marque" bindLabel="nom" bindValue="nom"  [maxSelectedItems]="3"
                [ngModelOptions]="{standalone: true}"   [multiple]="true"
                placeholder='Marques'>
                <ng-template ng-option-tmp let-item="item">

                    {{item.nom}}
                </ng-template>

            </ng-select>
        </div>

        <br>
        <div class="col-12">
            <ng-select [items]="Tailles" [(ngModel)]="taille" bindLabel="nom" bindValue="nom"  [maxSelectedItems]="3"
                [ngModelOptions]="{standalone: true}"   [multiple]="true"
                placeholder='Tailles'>
                <ng-template ng-option-tmp let-item="item">

                    {{item.nom}} <br />

                </ng-template>

            </ng-select>
        </div>
        <br>

        <div class="col-12">
            <ng-select [items]="Etats" [(ngModel)]="etat" bindLabel="name" bindValue="name"  [maxSelectedItems]="3"
                [ngModelOptions]="{standalone: true}"   [multiple]="true" placeholder='Etats'>
                <ng-template ng-option-tmp let-item="item">

                    {{item.name}}

                </ng-template>

            </ng-select>
        </div>

        <br>
        <div class="col-12">
            <ng-select [items]="Couleurs" [(ngModel)]="couleur" bindLabel="nom" bindValue="nom" [maxSelectedItems]="3"
                [ngModelOptions]="{standalone: true}" [multiple]="true" placeholder='Couleur'>
                <ng-template ng-option-tmp let-item="item">

                    {{item.nom}}

                </ng-template>

            </ng-select>
        </div>
        <div class="form-group mt-3">
            <button type="submit" class="button button-contactForm btn_4">Recherche</button>
        </div>
    </form>
</div>