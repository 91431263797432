import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listproinfo',
  templateUrl: './listproinfo.component.html',
  styleUrls: ['./listproinfo.component.css']
})
export class ListproinfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
