import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactencour',
  templateUrl: './contactencour.component.html',
  styleUrls: ['./contactencour.component.css']
})
export class ContactencourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
