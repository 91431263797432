

<!-- Page Content -->
<div class="container1">

  <div class="row">

    <div class="col-lg-3">
      <div class="mar">

        <!-- la section droite de la page vous cree beaucoup des categorie -->



        <app-infouser></app-infouser>

        <app-recherche2></app-recherche2>

        <!-- <app-categorie></app-categorie>

        <app-coupcoueur></app-coupcoueur> -->
        <app-appstore></app-appstore>
        <app-reseausocial></app-reseausocial>

      </div>
    </div>
    <hr>

    <!-- /.col-lg-3 -->

    <div class="col-lg-9">


      <div class="card">
        <div class="card-content">
          <form [formGroup]="slideOneForm" (ngSubmit)=' ajoutagent()'>

            <div class="col-12">
              <div class="form-group">
                <input class="form-control" [(ngModel)]='agent.nom' formControlName="nom" type="text" placeholder='Nom'>

                <div
                  *ngIf="slideOneForm.controls['nom'].invalid && (slideOneForm.controls['nom'].dirty || slideOneForm.controls['nom'].touched)"
                  class="alert alert-danger">
                  <div *ngIf="slideOneForm.controls['nom'].errors.required">
                    Nom Obligatoire.
                  </div>
                </div>
              </div>

            </div>
       
              <div class="form-group">
              
                <div class="sexe">
                  <label class="sexelabel">
                    <input type="radio" formControlName="sexe" [(ngModel)]="agent.sexe" value="1" /> Homme
                  </label>
                  <label class="sexelabel">
                    <input type="radio" formControlName="sexe" [(ngModel)]="agent.sexe" value="2" checked /> Femme
                  </label>
                </div>


              </div>
          
            <div class="col-12">
              <div class="form-group">
                <input class="form-control" [(ngModel)]='agent.num' formControlName="num" type="text" maxlength="8"
                  name="sexe" placeholder='Numero telehone'>
              </div>

              <div
                *ngIf="slideOneForm.controls['num'].invalid && (slideOneForm.controls['num'].dirty || slideOneForm.controls['num'].touched)"
                class="alert alert-danger">
                <div *ngIf="slideOneForm.controls['num'].errors.required">
                  Numero Obligatoire est de 8 chiffre.
                </div>
              </div>
            </div>
      
              <p class="condition" onclick="conditions()">Lire les conditions d'utilisation</p>
       
            <div class="form-group">
              <label>
              
                <input type="checkbox" name="condition" [(ngModel)]="agent.condi" formControlName="condition" />
                J'accepte les conditions d'utilisation
              </label>
            </div>
            <div class="row">
              <div class="col-12">
              <button type="submit" [disabled]="!slideOneForm.valid" (click)="ajoutagent()">Valider</button>
              </div>
            </div>
          </form>
        </div>
      </div>


      <!--<app-baniere></app-baniere>-->

      <!-- /.row -->

    </div>
    <!-- /.col-lg-9 -->

  </div>
  <!-- /.row -->

</div>
<!-- /.container -->

<!-- Footer -->