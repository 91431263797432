import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-susers',
  templateUrl: './susers.component.html',
  styleUrls: ['./susers.component.css']
})
export class SusersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
