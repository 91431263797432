

<!-- Page Content -->
<div class="container1">

    <div class="row">

        <div class="col-lg-3">
            <div class="mar">

                <!-- la section droite de la page vous cree beaucoup des categorie -->



                <app-infouser></app-infouser>

                <app-recherche2></app-recherche2>

                <!-- <app-categorie></app-categorie> -->

                <!-- <app-coupcoueur></app-coupcoueur> -->
                <app-appstore></app-appstore>
                <app-reseausocial></app-reseausocial>

            </div>
        </div>
        <hr>

        <!-- /.col-lg-3 -->

        <div class="col-lg-9">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                        aria-controls="home" aria-selected="true">Ventes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                        aria-controls="profile" aria-selected="false">Achats</a>
                </li>

            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="product-list">

                            <div class="row ">
                            <div class="col-lg-4 col-md-6 mb-4" *ngFor="let product of Coeur index as i">
                            <div class="product">
                               

                                <div>
                                    <img class="card-img-top" [src]="product.imageproduit"
                                        (click)="getproduit(product.id_produit)">
                                </div>
                                <h4 class='annule' *ngIf="((product.valider==3)&&(product.statue==0)) ">COMMANDE ANNULEE</h4>
                                <h4 class='cour' *ngIf="((product.valider==0)&&(product.statue==1)) ">. en cour confirmation </h4>
                                <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==0)&&(product.recu==0)) ">COMMANDE CONFIRMER </h4>
                                <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==1)&&(product.recu==0)) ">COMMANDE ENVOYER </h4>
                                <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==1)&&(product.recu==1)&&(product.payment==0)) ">COMMANDE LIVRE </h4>
                             
                                <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==1)&&(product.recu==1)&&(product.payment==1)) ">VENTE CONFIRMER </h4>
                                <div class="product-bottom">
                                    <div class="col-9">
                                        <span class="product_price">{{product.nom_produit}} </span>
                                        <br> {{product.marque}}
                                        <br> {{product.taille}}
                                        <br>
                                     
                                        <!-- <ionic3-star-rating activeIcon="ios-star" defaultIcon="ios-star-outline" activeColor="gold"
                          defaultColor="gray" readonly="true" fontSize="15px" [rating]="product.etoile">
                        </ionic3-star-rating> -->
                                        <span class="badgrree">COMMANDÉ: {{product.heure | date:'M/d/yyyy
                                            HH:mm'}}</span>
                                    </div>
                                    <div class="col-3">
                                        <p class="card-text2">{{product.prix}}DT</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                    <div class="product-list">

                        <div class="row ">
                        <div class="col-lg-4 col-md-6 mb-4" *ngFor="let product of achat index as i">
                        <div class="product">
                           

                            <div>
                                <img class="card-img-top" [src]="product.imageproduit"
                                    (click)="getproduit(product.id_produit)">
                            </div>
                            <h4 class='annule' *ngIf="((product.valider==3)&&(product.statue==0)) ">. COMMANDE ANNULEE</h4>
                            <h4 class='cour' *ngIf="((product.valider==0)&&(product.statue==1)) ">. en cour confirmation </h4>
                            <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==0)&&(product.recu==0)) "> COMMANDE CONFIRMER </h4>
                            <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==1)&&(product.recu==0)) "> COMMANDE ENVOYER </h4>
                            <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==1)&&(product.recu==1)&&(product.payment==0)) "> COMMANDE LIVRE </h4>
                         
                            <h4 class='confirmer' *ngIf="((product.valider==1)&&(product.statue==1)&&(product.envoyer==1)&&(product.recu==1)&&(product.payment==1)) "> ACHAT CONFIRMER</h4>
                            <div class="product-bottom">
                                <div class="col-9">
                                    <span class="product_price">{{product.nom_produit}} </span>
                                    <br> {{product.marque}}
                                    <br> {{product.taille}}
                                    <br>
                                 
                                    <!-- <ionic3-star-rating activeIcon="ios-star" defaultIcon="ios-star-outline" activeColor="gold"
                      defaultColor="gray" readonly="true" fontSize="15px" [rating]="product.etoile">
                    </ionic3-star-rating> -->
                                    <span class="badgrree">COMMANDÉ: {{product.heure | date:'M/d/yyyy
                                        HH:mm'}}</span>
                                </div>
                                <div class="col-3">
                                    <p class="card-text2">{{product.prix}}DT</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
        </div>


        <!--<app-baniere></app-baniere>-->

        <!-- /.row -->


        <!-- /.col-lg-9 -->

    </div>
    <!-- /.row -->

</div>
<!-- /.container -->

<!-- Footer -->