import { Component, OnInit } from '@angular/core';
import {ProduitService} from '../../providers/produit.service';
import {StorageCrypter} from 'storage-crypter';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-articlevendu',
  templateUrl: './articlevendu.component.html',
  styleUrls: ['./articlevendu.component.css']
})
export class ArticlevenduComponent implements OnInit {
  productss:any;
  Coeur:any={};
  addlike:any={};
like:any;  
email:any;
id:any;
page=0;
isLoading=true;
Data:Array<any>=[];
  constructor( private Produitsservice:ProduitService ,private activatedRoute:ActivatedRoute) { }


  
  ngOnInit(): void {


    this.id = this.activatedRoute.snapshot.paramMap.get('email');
console.log("email:"+this.id)
    this.getProduct();
   /* this.Produitsservice.getAllProduitServiceemail(this.id).subscribe(
      (data) => {
        this.productss = data.produits;
        console.log('produit:'+this.productss);
      }
    );
*/
    this.Produitsservice.getAlllike(this.email).subscribe(


      data=>
      {

this.Coeur=data.coeur
console.log("likes:"+this.Coeur.length);
      }
    )  
  


  }

  isFav(id:any,item:any)
  {
  var reponse:boolean=false;
    /*foreach(this.Coeur as cou)
    {


    }*/
    for (var i = 0; i < this.Coeur.length; i++) {

   //  console.log("likeghhhh:"+this.Coeur[i]["idproduit"] );
    if(this.Coeur[i]["idproduit"]==id )
  {
reponse=true;
  }
    
    }
    return reponse;
  }
  coeur(id :any,like:any,item:any)
  {
    if (like!=null)
    {
    console.log("like"+like);
   
this.addlike.like=like+1;
    }
    else{

      this.addlike.like=1;
    }
this.addlike.id=id;
this.addlike.idproduit=id;
this.addlike.email=this.email;

console.log("lentgh1:"+this.Coeur.length);

//this.isFav(id);

//this.productss[item]["Countfavoirie"]=this.addlike.like;
//this.Coeur.push(this.addlike);
//this.Coeur[this.Coeur.lentgh-1]["idproduit"]=id
this.Coeur.push(this.addlike);
console.log("lentgh2:"+this.Coeur.length);
this.Produitsservice.addcoeur(this.addlike).subscribe(
  (data) => {

    console.log('produitadded successfully');
    console.log(data);
  }
)

  }


  getProduct():void
  {
    this.isLoading=true;
  

  this.Produitsservice.getoroductprofilevendu({page:this.page,email:this.id}).subscribe((res:any)=>{
  res.produits.forEach((e:any) => {
    this.Data.push(e);
  });
  
  /*this.Data=res.produits;
  console.log("tt:"+res.produits);
  */
  this.isLoading=false;
  } );
  
  }
  onScroll()
  {
    console.log("scrolle down");
    this.page+=1;
  
    this.getProduct();
  }

}
