import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {ProduitService}from '../../providers/produit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentaireService} from '../../providers/commentaire.service'
import {StorageCrypter} from 'storage-crypter';
import { FormControl} from '@angular/forms';
import { FormsModule , Validators} from '@angular/forms';
import { io } from "socket.io-client";
import {SusersService} from'../../providers/susers.service';
//NOTIF DDANS PROFIDER MARQUE
import {SmarqueService} from'../../providers/smarque.service'; 
//import * as io from 'socket.io-client' 
@Component({
  selector: 'app-commentaire',
  templateUrl: './commentaire.component.html',
  styleUrls: ['./commentaire.component.css']
})
export class CommentaireComponent implements OnInit {
connectedUser:any;
  
commentaire:any={};
comentaireForm:FormGroup;
id :any;
submitAttempt: boolean = false;
email:any;
user: any;
product :any;
name:any;
photo:any;
productss: any;
isLoading = true;
Coeur: any = {};
addlike: any = {};

url: any;
like: any;

data2: string;

page = 0;
Data: Array<any> = [];
vus: any = {};
notif: any = {};
commentaires: any;
prodForm : FormGroup;
commentairess: any;
tabBarElement:any;
tabsHideOnSubPages = "false";


socket = io("https://lebesty.com:3000/",{
    
  rejectUnauthorized : false 
});

  constructor( private activatedRoute:ActivatedRoute,  private suser:SusersService,
    private fb:FormBuilder,private Produitsservice:ProduitService ,
    private notifservice:SmarqueService,
    private commentaireservice:CommentaireService,private router:Router ) 
    
    {


     }

  ngOnInit(): void {
    this.comentaireForm = this.fb.group({
      commentaire: new FormControl('', [Validators.required ]),
  
    })
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.Produitsservice.getProduitByIdService(this.id).subscribe(
      data => {
        this.product = data.produit;
      }
    )

    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
   
 this.name=storageCrypter.getItem('name', 'local')
    //console.log(storageCrypter.getItem('provider', 'local'));
    this.email=storageCrypter.getItem('num', 'local');
    this.photo=storageCrypter.getItem('photoUrl', 'local');
 
    this.suser.getuserbyemail(this.email).subscribe(


      data => {

        this.user = data.user
        console.log('abonner:' + this.user.url_userimage)

      }
    )
  }

/**
 * 
  description:String,
    heure:Date,
    emailachteur:String,
    imageuser:String,
    statue:Number,
    emailVendeur:String,
    idProduit:String,
    nomacheteur:String,
 */
  addCommentaire()
  {
    this.submitAttempt = true;
    if(this.comentaireForm.valid){
      this.name = this.user.nom ;
      this.photo = this.user.url_userimage;
      this.commentaire.idProduit = this.id;
      this.commentaire.nomacheteur = this.name;
      this.commentaire.imageuser = this.photo;
      this.commentaire.emailVendeur = this.email;
      this.commentaire.emailacheteur = this.product.email_user;
      this.commentaire.id_vendeur = this.product.id_user;
      this.commentaire.id_achteur = this.user._id;
this.commentaire.emailvendeur=this.product.nom_user;

this.notif.titre = "";



/////qui sest abonner /////////////////
this.notif.id_user = this.user._id;
this.notif.email_user = this.email;
this.notif.image_user = this.user.url_userimage;
////////////////////////////



////recherche par id ou email /////////////
this.notif.id_vendeur = this.product.id_user;
this.notif.email_vendeur = this.product.email_user;
///////////////

///////article////////////
this.notif.nomarticle = this.product.titre;
this.notif.image_article = this.product.image_1;
this.notif.id_article = this.product._id;
this.notif.prix_article = this.product.prix;
////////////////////////////

////type d action commentaire ou suivie ou ajout article////////////
this.notif.typeaction = 3;
/////////////////////////////////////////////////


/////////////commentaire ////////////////////
this.notif.commentaire_article = this.commentaire.commentaire;
this.notif.nom_user = this.user.nom;

this.commentaireservice.add(this.commentaire).subscribe(
  (data) => {



    this.socket.emit("refrech", { data: this.id });
  


    ////////////////



  }
)
console.log('ooooooohgfghfghdddddddddooo'+this.commentaires.length);


for (var i = 0; i < this.commentairess.length; i++) {
  console.log('ooooooohgfghfghooo'+this.commentairess[i]);
 
  this.notif.id_vendeur = this.commentairess[i];
  //this.notif.email_vendeur = this.commentairess[i]["emailacheteur"];



 if ((this.commentairess[i])!= (this.user._id)&&(this.commentairess[i])!= (this.product.id_user))
 {
  console.log('uuuuuuuuuuuuuuuu:'+this.commentairess[i]);
 this.socket.emit("refrech", { data: this.commentairess[i]})

  this.notifservice.add_notif(this.notif).subscribe(
    data => {


    }
  )
 }
}

this.commentaire.commentaire = null;
if ((this.product.id_user)!= (this.user._id))
{
this.notif.id_vendeur = this.product.id_user;
this.notifservice.add_notif(this.notif).subscribe(
  () => {
    console.log('dfl,;ls,f;lsd,abonner');


  }

)
this.socket.emit("refrech", { data: this.product.id_user})

}}
else
{
//this.navCtrl.setRoot(InscriptionPage);
}
}
  


  
  
pro(teste)
{
  this.commentaire.commentaire=teste;
}
  
}
