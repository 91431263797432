<div class="card text-center">
    <div class="card-block"><span class="prixpr">{{product.prix}} DT</span><hr>
        <div class="card-block"><span class="titrearticle">{{product.titre}}</span>
        </div>
        <hr>
        <div class="card-block"><span class="descarticle">{{product.description}}</span> </div>
        <div class="card-block">


            <hr>
            <h4 class="card-title"></h4>
            <!--   <p class="card-text">{{ user.email }}</p>
       <p class="card-text">Logged in with {{ user.provider }}</p>-->
        </div>
        <div class="row">

            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom1">Marque</span>

            </div>
            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom">{{product.marque}}</span>

            </div>
        </div>
        <div class="row">

            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom1">Taille</span>

            </div>
            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom">{{product.taille}}</span>

            </div>
        </div>
        <div class="row">

            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom1">etat</span>

            </div>
            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom">{{product.etat}}</span>

            </div>
        </div>
        <div class="row">

            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom1">Couleur</span>

            </div>
            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom">{{product.couleur}}</span>

            </div>
        </div>
     
     
        <hr>

        <div class="row">

            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom1">livraison</span>

            </div>
            <div class="col-lg-6 col-md-6 mb-4">
                <span class="nom">6-7jours</span>

            </div>
            
        </div>
        <button type="button" *ngIf="user"  (click)='achat(product._id)' class="btn btn-success">Acheter</button>
        <button type="button" *ngIf="!user"routerLink="/inscri"  class="btn btn-success">Acheter</button>

    </div>