
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  produitURL = 'https://lebesty.com:3000' ;
  imageData: any;
  img2 :File;
  constructor(private httpClient:HttpClient) 
  
  {


   }

   addcoeur(coeur:any) {
  
    return this.httpClient.post(`${this.produitURL}/addcoeur`, coeur);
  }

deletecoeur(coeur:any) {
  
    return this.httpClient.post(`${this.produitURL}/deletecoeur`, coeur);
   
  }
  getAlllike(email:any) {
    return this.httpClient.get<{coeur:any}>(`${this. produitURL}/coeurs/${email}`);
  }



  getAllProduitService() {
    return this.httpClient.get<{produits:any}>(`${this. produitURL}/produits`);
  }

 
  deletecoeur2(coeur:any) {
  
    return this.httpClient.put(`${this.produitURL}/suppproduitfav`, coeur);
   
  }

  getAllProduitServiceId(id :any) {
    return this.httpClient.get<{produits:any}>(`${this. produitURL}/produitsid/${id}`);
  }


getAllProduitServiceemail(email:any) {
    return this.httpClient.get<{produits:any}>(`${this. produitURL}/produitsemail/${email}`);
  }

  

  getAllProduitrecherche3(recherche:any) {
    return this.httpClient.get<{recherche:any}>(`${this. produitURL}/recherche/${recherche}`);
  }
  getAllProduitrecherche(nom:any,cat:any,gender:any,etat:any,taille:any,marque:any,couleur:any) {
    return this.httpClient.get<{recherche:any}>(`${this. produitURL}/recherche?search=`+nom+`&cat=`+cat+`&gender=`+gender+`&etat=`+etat+`&taille=`+taille+`&marque=`+marque+`&couleur=`+couleur);
  }
 
  getnbrecoeur(id:any)
  {
   // await   this.httpClient.get<{user:any}>(`${this.produitURL}/coueur/${id}`).toPromise();
   return this.httpClient.get<{user:any}>(`${this.produitURL}/coueur/${id}`);

  
  }

  addprodService(produit:any, multipleImages:any) {    
    console.log('pro2')
    let formData = new FormData();
    formData.append('titre',produit.titre);
    formData.append('description',produit.description);
    formData.append('prix',produit.prix);
    formData.append('nom_user',produit.nomuser);
    formData.append('categorie',produit.categorie)
    formData.append('Scategorie',produit.scategorie)
    formData.append('marque',produit.marque);
    formData.append('taille',produit.taille);
    formData.append('url_userimage',produit.urlimageuser);
    formData.append('couleur',produit.couleur);
    formData.append('etat',produit.etat);
    formData.append('genre',produit.genre);
    formData.append('email_user',produit.email_user);
    formData.append('id_user',produit.id_user);

    console.log('ddd'+multipleImages);
    for(let img of multipleImages){
      formData.append('files', img);
      console.log("444")
    }
    return this.httpClient.post(`${this.produitURL}/addproduitt`,formData);
  }

  getProduitByIdService(id:any) {
    return this.httpClient.get<{produit:any}>(`${this.produitURL}/produit/${id}`);
  }




getoroduct(params:any):Observable<any>{

return  this.httpClient.get<{produits:any}>(this.produitURL+"/produitscroll",{params});

}



getoroductsimilaire(params:any):Observable<any>{

  return  this.httpClient.get<{produits:any}>(this.produitURL+"/produitscrollsimilaire",{params});
  
  }
  
  
getoroductprofile(params:any):Observable<any>{

  return  this.httpClient.get<{produits:any}>(this.produitURL+"/produitsprofile",{params});
  
  }

  updatepreduitvenduService(produit:any) {
    return this.httpClient.put(`${this.produitURL}/produitvendu`,produit);
  }
  updatepreduitannuler(produit:any) {
    return this.httpClient.put(`${this.produitURL}/produitannuler`,produit);
  }
 

  
  getoroductprofilevendu(params:any):Observable<any>{

    return  this.httpClient.get<{produits:any}>(this.produitURL+"/produitsprofile2",{params});
    
    }

    compterProduitsVendus(email:any){
  
      // return  this.httpClient.get<{count:number}>(this.produitURL+"/compterProduitsVendus/${email}`
 
       return this.httpClient.get<{count:number}>(`${this.produitURL}/compterProduitsVendus/${email}`);
       
       }
   
 
       compterProduitsnonVendus(email:any){
   
         // return  this.httpClient.get<{count:number}>(this.produitURL+"/compterProduitsVendus/${email}`
    
          return this.httpClient.get<{count:number}>(`${this.produitURL}/compterProduitsnonVendus/${email}`);
          
          }
}


