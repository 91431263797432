import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ScategorieService} from '../../providers/scategorie.service';
import { SconditionService} from '../../providers/scondition.service';
import {ScouleurService} from '../../providers/scouleur.service';
import {SgenreService} from '../../providers/sgenre.service';
import {SmarqueService} from '../../providers/smarque.service';
import {SscategorieService} from '../../providers/sscategorie.service';
import {StailleService} from '../../providers/staille.service';
import { HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-recherche2',
  templateUrl: './recherche2.component.html',
  styleUrls: ['./recherche2.component.css']
})
export class Recherche2Component implements OnInit {
  plat: any = {};
  platForm: FormGroup;
  nom:any;
  imagePreview: any;
gender:any;
  title = 'ng-select-filter-demo';
  titre:any;
  prix:any;
  Genressr:any;
  description:any;
  categories: any;
  categorie: any;
  items:any;
  Scategorie: any;
  Couleurs: any;
  Marques:any;
  marque:any;
  selectedUser2 :any;
category:any;
Scategory:any;
recherche: any = {};
search: string=null;
  brands: any;
  couleur:any;
  genre:any;
  taille;any;
  Genres:any;
  Genress :any=[] ;
 Tailles = [];
  userList = [];
  Etats:any;
  etat:any;
  selectedUser: number;
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,private GenreService: SgenreService,private Etatservice:SconditionService ,private Scouleurs :ScouleurService,private SmarqueService:SmarqueService,
    private ScategorieService:ScategorieService,
    private SouscategorieService:SscategorieService,
    private TailleService:StailleService, private http: HttpClient, private router:Router
   ) { }

  ngOnInit() {
    this.getUsersList();
    this.getUsersList2();
    this.platForm = this.fb.group({
      name: [''],
      description: [''],
      price: [''],
      payment:[''],
      gender: ['']
    })


    this.route.queryParams
    .subscribe(params => {
      //console.log(params); // { orderby: "price" }
      if((params.search!=null)&&(params.search!=""))
      this.recherche.search = params.search;
      else
      this.recherche.search ="opanull"
      if((params.gender!=null)&&(params.gender!=""))
      this.gender=params.gender;
      else
      this.recherche.gender="opanull"

      if((params.cat!=null)&&(params.cat!=null))
      this.Scategorie=params.cat;
      else
      this.recherche.cat="opanull"
      if((params.marque!=null)&&(params.marque!=""))
      this.marque=params.marque;
      else
      this.recherche.marque="opanull"

      if((params.taille!=null)&&(params.taille!=""))
     { this.taille=params.taille;
    
     
     }
      else
      this.recherche.taille="opanull"


      if((params.etat!=null)&&(params.etat!=""))
      this.etat=params.etat;
      else
      this.recherche.etat="opanull"
      
      
      if((params.couleur!=null)&&(params.couleur!=""))
      this.couleur=params.couleur;
      else
      this.recherche.couleur="opanull"
console.log("cat:"+this.recherche.cat);
console.log("marque:"+this.recherche.marque);


console.log("couleur:"+this.recherche.couleur);

    this.Etatservice.getAlletatService().subscribe(
      (data) => {
        this.Etats = data.etats;
      //  console.log(data.genres);
        console.log(this.Etats);
      }
    );

    this.Scouleurs.getAllcouleurService().subscribe(
      (data) => {
        this.Couleurs = data.couleurs;
      //  console.log(data.genres);
        console.log(this.Couleurs);
      }
    );

    this.SmarqueService.getAllMarqueService().subscribe(
      (data) => {
        this.Marques = data.marques;
      //  console.log(data.genres);
        console.log(this.Marques);
      }
    );

    this.ScategorieService.getBygenreService('Femme').subscribe(
      (data) => {
        this.Scategory = data.category;
  
        console.log(this.Scategory);
      }
    );


    this.TailleService.getBytaillegenre('Femme').subscribe(
      (data) => {
        this.Tailles = data.taille;
  
        console.log(this.Tailles);
      }
    );
  })
  }
  

  submit() 
  {
    this.router.navigate(['recherche'], { queryParams: { search:this.nom , gender:this.gender,cat:this.Scategorie , marque: this.marque ,taille:this.taille ,etat: this.etat, couleur: this.couleur} });
  }
  changeGender2(e) {

    console.log(e.target.value);

  }

  changeGender(e)
  {
    this.selectedUser2 = null;
    this.Tailles=null;
  this.taille=null;
    console.log(e.target.value);
    this.Scategory=null;
    this.Scategorie=null;
  
    this.ScategorieService.getBygenreService(e.target.value).subscribe(
      (data) => {
        this.Scategory = data.category;
  
        console.log(this.Scategory);
      }
    );

    this.TailleService.getBytaillegenre(e.target.value).subscribe(
      (data) => {
        this.Tailles = data.taille;
  
        console.log(this.Tailles);
      }
    );



  }

  getUsersList2() {
  this.GenreService.getAllgenreService().subscribe(
    (data) => {
      this.Genres = data.genres;
      this.Genress=data.genres;
    //  console.log(data.genres);
      console.log(this.Genres);

    
     

    }
  );
}

  getUsersList() {
    this.http
      .get<any>('https://jsonplaceholder.typicode.com/users')
      .subscribe(response => {
        this.userList = response.map(o => {
          o.search_label =
            ` ${o.id} ${o.name} ${o.username} ${o.email} ${o.phone} ${o.website} ${o.phone} ${o.address.street} ${o.address.suite} ${o.address.city} ${o.address.zipcode} 
          `
          return o
        });
        console.log(this.userList);

      }, error => {
        console.log(error);
      });
  }

  customSearchFn(term: string) {
    term = term.toLowerCase();

  


    if (term) {
       this.Marques =this.Marques.filter(x => x.nom.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return (this.Marques);




  /*  // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      let search = item['search_label'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) != -1);
    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);*/
  }
}

