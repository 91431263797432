import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ProduitService}from '../../providers/produit.service';
import { KeyValue } from '@angular/common';
@Component({
  selector: 'app-bannier-prouduit',
  templateUrl: './bannier-prouduit.component.html',
  styleUrls: ['./bannier-prouduit.component.css']
})
export class BannierProuduitComponent implements OnInit {
  id:any;
  product:any={};
  constructor(    private activatedRoute:ActivatedRoute,private Produitsservice:ProduitService  ) { }

  ngOnInit(): void {
   this.product.image_1=null;
   this.product.image_2=null;
   this.product.image_3=null;
   this.product.image_4=null;
   this.product.image_5=null;
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.Produitsservice.getProduitByIdService(this.id).subscribe(
      data => {
        this.product = data.produit;
        console.log("data1",this.product.image_1);
        console.log("data2",this.product.image_2);
        console.log("data3",this.product.image_3);
        console.log("data4",this.product.image_4)
        console.log("data5",this.product.image_5)
      }
    )
  }

}
