<div class="container1">

    <h2>{{totalPages}} Commande en cours</h2>

    <div class="row">
        <div class="col-3">
            <div class="mar">




                    <ul class="navbar-nav ml-auto"> <!-- Utilisation de ml-auto pour aligner vers la droite -->
                        <li class="nav-item active">
                            <a class="nav-link" routerLink="#">Commandes en cours <span
                                    class="sr-only">(current)</span></a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/annuler">Commandes Anuuler</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/confirmer">Commandes Confirmer</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/envoyer">Commandes Envoyée</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/livrer">Commandes Livrée</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/finaliser">Commandes Finaliser</a>
                        </li>
                    
                    </ul>

             

            </div>
        </div>


        <div class="col-9">
            <table class="table" style="width: auto;">
                <tbody>
                    <ng-container *ngFor="let commande of pagedCommandes">
                        <tr>
                            <th scope="row">Date</th>
                            <td>{{momenttimenow(commande.heure)}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Nom Vendeur</th>
                            <td> <input type="text" [(ngModel)]="commande.nom_Vendeur" /> </td>
                        </tr>
                        <tr>
                            <th scope="row">Adresse Vendeur</th>
                            <td>
                                <textarea [(ngModel)]="commande.adressvendeur">{{commande.adressvendeur}}</textarea>

                            </td>
                        </tr>

                        <tr>
                            <th scope="row">ville vendeur</th>
                            <td> <input type="text" [(ngModel)]="commande.villevendeur" /></td>
                        </tr>
                        <tr>
                            <th scope="row">Numéro Vendeur</th>

                            <td> <input type="text" [(ngModel)]="commande.numvendeur" /> </td>
                        </tr>
                        <tr>
                            <th scope="row">Nom Acheteur</th>
                            <td> <input type="text" [(ngModel)]="commande.nom_Achteur" /></td>
                        </tr>
                        <tr>
                            <th scope="row">Adresse Acheteur</th>
                            <td>
                                <textarea [(ngModel)]="commande.adresslivraison">{{commande.adresslivraison}}</textarea>

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">ville achteur</th>

                            <td> <input type="text" [(ngModel)]="commande.villeacheteur" /></td>



                        </tr>
                        <tr>
                            <th scope="row">Numéro Acheteur</th>
                            <td> <input type="text" [(ngModel)]="commande.numacheteur" /></td>
                        </tr>
                        <tr>
                            <th scope="row">Nom Produit</th>
                            <td>{{commande.nom_produit}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Description</th>
                            <td>{{commande.detail}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Marque</th>
                            <td>{{commande.marque}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Taille</th>
                            <td>{{commande.taille}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Prix</th>
                            <td>{{commande.prix}}</td>
                        </tr>
                        <tr>

                            <th scope="row"></th>
                            <td><img src="{{commande.imageproduit}}"></td>
                        </tr>
                        <tr>
                            <th scope="row"></th>
                            <td><button class="buttonn" (click)='confirmer(commande)'>Confirmer</button></td>
                        </tr>
                        <tr>
                            <th scope="row"></th>
                            <td><button class="buttonn" (click)='annuler(commande)'>Annuler</button></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="pagination">
                <button class="page-button" (click)="previousPage()">Previous</button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button class="page-button" (click)="nextPage()">Next</button>
    
            </div>
        </div>

       
    </div>
</div>
<!-- /.row -->