import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScategorieService {
  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) 
  
  { }

  add(category:any) {
    return this.httpClient.post(`${this.URL}/addcategory`, category);
  }


  getAllcategoryService() {
    return this.httpClient.get<{categorys:any}>(`${this.URL}/categorys`);
  }


  getBygenreService(genre:any) {
    return this.httpClient.get<{category:any}>(`${this.URL}/category/${genre}`);
  }




}
