<div class="jumbotron bg-transparent text-center">
    <div *ngIf="!user && !user22" class="card text-center">
      <h6 class="card-header">
        Connexion sur Labesni
      </h6>
      <div class="card-block">
       <h4 class="card-title">Pour acheter, vendre ou interagir avec notre communauté, connecte-toi en 3 secondes .</h4>
   
        <button  class="btn-foursquare" class="btn btn-danger" style=" width: -webkit-fill-available;margin-right: 10px;
    margin-left: 10px;" (click)="signInWithGoogle()"><span >GOOGLE</span></button><br>
        
        
        <button class="btn-foursquare" class="btn btn-info" style="
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4; margin-bottom: 10px;margin-top: 10px; width: -webkit-fill-available;
    margin-right: 10px;
    margin-left: 10px;"(click)="signInWithFB()"><span > Facebook</span></button><br>
     
      </div>
      <div class="card-block">
       
      
      </div>
    </div>
    <div *ngIf="user" class="card text-center">
      <h6 class="card-header">
        Connexion sur Labesni
      </h6>
      <div class="card-block"></div>
      <img *ngIf="user.photoUrl" class="card-img-top img-responsive photo" src="{{ user.photoUrl }}">
      <div class="card-block">
        <h4 class="card-title"> BienVenu {{ user.name }}</h4>
        <p class="card-text">{{ user.email }}</p>
        <p class="card-text">Logged in with {{ user.provider }}</p>
      </div>
      <div class="card-block">
        <button class="btn btn-danger" (click)="signOut()">Sign out</button>
      </div>
      <!-- <div class="card-block" *ngIf="user.provider === GoogleLoginProvider.PROVIDER_ID">
       <button class="btn" (click)="refreshGoogleToken()">Refresh google token</button>
      </div>-->
    </div>
    <div *ngIf="user22" class="card text-center">
      <h6 class="card-header">
        Connexion sur Labesni
      </h6>
      <div class="card-block"></div>
      <img *ngIf="photo" class="card-img-top img-responsive photo" src="{{ photo }}">
      <div class="card-block">
        <h4 class="card-title"> BienVenu {{ name }}</h4>
        <p class="card-text">{{ email }}</p>
      
      </div>
      <div class="card-block">
        <button class="btn btn-danger" (click)="signOut()">Sign out</button>
      </div>
      <!-- <div class="card-block" *ngIf="user.provider === GoogleLoginProvider.PROVIDER_ID">
       <button class="btn" (click)="refreshGoogleToken()">Refresh google token</button>
      </div>-->
    </div>
  </div>