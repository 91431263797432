<div class="card text-center">

    <div class="card-block"></div>
    <h5 class="topvendeur">Pour ne rien rater  !</h5>
    <div class="card-block">

        <div class="social" >
          
            
                
             
         
   
            
               <img class="avatar" src="https://lebesty.com:3000/images/logo-facebook.svg"> 
            
          
            
               
               <img class="avatar" src="https://lebesty.com:3000/images/logo-instagram.svg"> 
              
           
                 
                  <img class="avatar" src="https://lebesty.com:3000/images/logo-twitter.svg">      
                 
                  
             
                   </div>
              </div>

           
        
    </div>