import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SmarqueService {

  marqueURL = 'https://lebesty.com:3000' ;
  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) { }

  add(marque:any) {
    return this.httpClient.post(`${this.marqueURL}/addmarque`, marque);
  }

  getAllMarqueService() {
    return this.httpClient.get<{marques:any}>(`${this.marqueURL}/marques`);
  }
  add_notif(notif:any) {
    return this.httpClient.post<{message:any}>(`${this.URL}/addNotif`, notif);
}



get_Notif_id(id:any) {
  return this.httpClient.get<{notifs:any}>(`${this.URL}/notif/${id}`);
}
get_Notif_idvu(id:any) {
  return this.httpClient.get<{notifs:any}>(`${this.URL}/notifvu/${id}`);
}
get_Notif_id2(id:any) {
  return this.httpClient.get<{notifs:any}>(`${this.URL}/notiff/${id}`);
}
get_Notif_email(email:any) {
  return this.httpClient.get<{notifs:any}>(`${this.URL}/abonnerr/${email}`);
}

updatenotiff(id:any) {
  console.log("idnotiff"+id)
  return this.httpClient.put(`${this.URL}/notiff`,id);
}
}
