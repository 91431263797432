


<div class="row">
   
    <div class="col-lg-3">
<app-infouser></app-infouser>
<app-abonnerinfo></app-abonnerinfo>

</div>
    <div class="col-lg-8">
        <div class="card text-center">
            <div class="card-block">
            <form #userForm='ngForm' (ngSubmit)='addChef()'>
                <div class=entete>
                <h2>Mes Tailles</h2>
                  <span class="description"> Tu peux indiquer ta taille et ta pointure pour une expérience plus personnalisée sur Labesni</span>
              
              
                </div>    <br>
           
                <div class="row">
          
                   <div class="col-3">
                    <span class="descri">Ma taille (vêtements)</span>
                 </div>
                 <div class="col-6">
                     <div class="form-group">
                         <input class="form-control" [(ngModel)]= 'user.taille' [ngModelOptions]="{standalone: true}"
                             id="subject" type="text" placeholder=''>
                     </div>
                 </div>
                     <div class="col-3">
                         <span class="deescri2">Champ Obligatoire !</span>
                      </div>
                      <div class="col-3">
                        <span class="descri">Ma pointure (chaussures)</span>
                     </div>
                     <div class="col-6">
                         <div class="form-group">
                             <input class="form-control" [(ngModel)]= 'user.pointure' [ngModelOptions]="{standalone: true}"
                                 id="subject" type="text" placeholder=''>
                         </div>
                     </div>
                         <div class="col-3">
                             <span class="deescri2">Champ Obligatoire !</span>
                          </div>


                        
                    <div class="col-12">
                      
                        <div class="form-group mt-3">
                            <button type="submit" class="button button-contactForm btn_4">suivant</button>
                        </div>
                    </div>

                   
                </div>

            </form>
          
            </div>
        </div>
    </div>
   


</div>