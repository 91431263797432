


<div class="row">
   
    <div class="col-lg-3">
<app-infouser></app-infouser>
<app-abonnerinfo></app-abonnerinfo>

</div>
    <div class="col-lg-8">
        <div class="card text-center">
            <div class="card-block">
            <form [formGroup]="userForm" (ngSubmit)='addChef()'>
                <div class=entete>
                <h2>Mon Profil</h2>
                  <span class="description"> Tu peux ajouter ou modifier tes informations personnelles</span>
              
              
                </div>    <br>
           
                <div class="row">
                 
                  
         
                   <div class="col-3">
                    <span class="descri">Prénom</span>
                 </div>
                 <div class="col-6">
                     <div class="form-group">
                         <input class="form-control" [(ngModel)]= 'user.prenom' formControlName="prenom"
                             type="text" placeholder='Prénom'>
                     </div>
                 </div>
                     <div class="col-3">
                      
                      </div>
                      <div class="col-3">
                        <span class="descri">Nom</span>
                     </div>
                     <div class="col-6">
                         <div class="form-group">
                             <input class="form-control" [(ngModel)]= 'user.nom' formControlName="nom" type="text" placeholder=''>
                         </div>
                     </div>
                         <div class="col-3">
                             *
                          </div>


                          <div calss="info" style="
                          width: 99%;
                          margin-bottom: 10px;
                          margin-top: 10px;
                      ">
                          
                            
                   
                          <div class="col-12">
                          
                           <span><strong> N° de téléphone </strong> est nécessaires pour recevoir des notifications lors d'achats ou ventes réalisés sur Lebesty.</span>
                           </div>
                       </div>
                       <div class="col-3">
                        <span class="descri">Téléphone</span>
                     </div>
                     <div class="col-6">
                         <div class="form-group">
                             <input class="form-control" [(ngModel)]= 'user.num' formControlName="num" type="number" placeholder='Mobile'>
                         </div>
                     </div>
                         <div class="col-3">
                             *
                          </div>

                              <div class="col-3">
                                <span class="descri">Date de naissance</span>
                             </div>
                             <div class="col-6">
                                 <div class="form-group">
                                     <input class="form-control" [(ngModel)]= 'user.dateNaiss' formControlName="datenai"  type="text" placeholder=''>
                                 </div>
                             </div>
                                 <div class="col-3">
                                  
                                  </div>
                                  <div class="col-3">
                                    <span class="descri">Bio</span>
                                 </div>
                                 <div class="col-6">
                                     <div class="form-group">
                                    
                                        <textArea  class="form-control"  placeholder='Présente-toi aux autres' [(ngModel)]= 'user.bio'  formControlName="datenai"  rows="5" cols="50" type="text">{{user.bio}}</textArea>
                                     
                                     </div>
                                 </div>
                                     <div class="col-3">
                                        <!-- <span class="deescri2">Présente-toi aux autres</span> -->
                                      </div>
                    <div class="col-12">
                      
                        <div class="form-group mt-3">
                            <button type="submit"  [disabled]="!userForm.valid" class="button button-contactForm btn_4">Valider</button>
                        </div>
                    </div>

                   
                </div>

            </form>
          
            </div>
        </div>
    </div>
   


</div>