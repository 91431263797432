import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ProduitService}from'../../providers/produit.service';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.css']
})
export class RechercheComponent implements OnInit {
  recherche: any = {};
  search: string=null;
  gender:any=null;
  cat:any=null;
  productss:any;
  marque:any=null;
  taille:any=null;
  etat:any=null;
  constructor(private route: ActivatedRoute,
     private Produitsservice: ProduitService) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      //console.log(params); // { orderby: "price" }
      if((params.search!=null)&&(params.search!=""))
      this.recherche.search = params.search;
      else
      this.recherche.search ="opanull"
      if((params.gender!=null)&&(params.gender!=""))
      this.recherche.gender=params.gender;
      else
      this.recherche.gender="opanull"

      if((params.cat!=null)&&(params.cat!=null))
      this.recherche.cat=params.cat;
      else
      this.recherche.cat="opanull"
      if((params.marque!=null)&&(params.marque!=""))
      this.recherche.marque=params.marque;
      else
      this.recherche.marque="opanull"

      if((params.taille!=null)&&(params.taille!=""))
     { this.recherche.taille=params.taille;
    
     
     }
      else
      this.recherche.taille="opanull"


      if((params.etat!=null)&&(params.etat!=""))
      this.recherche.etat=params.etat;
      else
      this.recherche.etat="opanull"
      
      
      if((params.couleur!=null)&&(params.couleur!=""))
      this.recherche.couleur=params.couleur;
      else
      this.recherche.couleur="opanull"
console.log("cat:"+this.recherche.cat);
console.log("marque:"+this.recherche.marque);


console.log("couleur:"+this.recherche.couleur);


  this.Produitsservice.getAllProduitrecherche( this.recherche.search, this.recherche.cat,this.recherche.gender,this.recherche.etat,this.recherche.taille,this.recherche.marque,this.recherche.couleur).subscribe(
    data=>
 {

this.productss=data.recherche;
//console.log("produit"+ this.productss);
 }
) ;

    }
    );


/*
 if (this.cat!=null)
{
      this.Produitsservice.getAllProduitrecherche(this.search,this.cat).subscribe(
           data=>
        {
  
  this.productss=data.recherche;
  console.log("produit"+ this.productss);
        }
      ) ;
      }
      console.log(this.search); // 
    }
    );
    


}*/
  }
}
