import { Component } from '@angular/core';
import {StorageCrypter} from 'storage-crypter';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'lebesty';

  user: null;
  loggedIn: boolean;
  users: any={};

  ngOnInit(): void {

   

    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
      
    this.users.email=storageCrypter.getItem('num', 'local');
    if (this.users.email)
      {
        this.user=this.users.email
      }
      else 
      {

        this.user=null;
      }
      
  }
}

