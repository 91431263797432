<h1 class="entenmodel" mat-dialog-title>Notifications</h1>
<mat-dialog-content class="entenmodel-body">
    <div class="container2">




        <ul>
          <li *ngFor="let notiff of  notif index as i ">
            <div *ngIf="notiff.typeaction==2" class="card text-center">
            <img class="avatar" src="{{notiff.image_user}}"  (click)="getprofil(notiff.id_user)" >
              
            <span class="badgrree">{{momenttimenow(notiff.heure)}}</span>
            <br>
            <span class="badgrre"><strong> {{notiff.nom_user}}</strong> {{notiff.commentaire_article}} <strong (click)="getproduit(notiff.id_article)">{{notiff.nom_article}} à {{notiff.prix_article}}</strong></span>
         
            </div>
    
            <div *ngIf="notiff.typeaction==1" class="card text-center">
              <img class="avatar" src="{{notiff.image_user}}" (click)="getprofil(notiff.id_user)"  >
                
              <span class="badgrree">{{momenttimenow(notiff.heure)}}</span>
              <br>
              <span class="badgrre" ><strong > {{notiff.nom_user}}</strong> {{notiff.commentaire_article}} </span>
           
              </div>
    
              <div *ngIf="notiff.typeaction==3" class="card text-center">
                <img class="avatar" src="{{notiff.image_user}}"  (click)="getprofil(notiff.id_user)" >
                  
                <span class="badgrree">{{momenttimenow(notiff.heure)}}</span>
                <br>
                <span class="badgrre"><strong> {{notiff.nom_user}}</strong >  a également commenté l'article  <strong (click)="getproduit(notiff.id_article)">{{notiff.nom_article}} à {{notiff.prix_article}}</strong></span>
               <br>
                <span class="badgrree">{{notiff.commentaire_article}}</span>
                </div>
                
    
                <div *ngIf="notiff.typeaction==4" class="card text-center">
                  <img class="avatar" src="{{notiff.image_user}}" (click)="getprofil(notiff.id_user)"  >
                    
                  <span class="badgrree">{{momenttimenow(notiff.heure)}}</span>
                  <br>
                  <span class="badgrre"><strong> {{notiff.nom_user}}</strong>  a acheter votre article  <strong (click)="getproduit(notiff.id_article)">{{notiff.nom_article}} à {{notiff.prix_article}}</strong></span>
              
                  </div>
    
    
          </li>
          <hr>
        </ul>
    
    
      </div>
</mat-dialog-content>

