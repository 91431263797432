import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StailleService {

  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) { }



  add(taille:any) {
    return this.httpClient.post(`${this.URL}/addtaille`, taille);
  }


  getAllTailleService() {
    return this.httpClient.get<{taille:any}>(`${this.URL}/tailles`);
  }


  getBytaillegenre(taille:any) {
    return this.httpClient.get<{taille:any}>(`${this.URL}/tailles/${taille}`);
  }






}
