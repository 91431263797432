import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { io } from "socket.io-client";
import {StorageCrypter} from 'storage-crypter';
import *  as moment from 'moment';
import { Router } from '@angular/router';
import {SusersService} from'../../providers/susers.service';
import {SmarqueService} from'../../providers/smarque.service'; 
@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.css']
})
export class MyModalComponent implements OnInit {
  email:any;
  abonner:any;
  notif:any;
  users: any={};
  socket = io("https://lebesty.com:3000/",{
    
    rejectUnauthorized : false 
  });
  constructor(public dialogRef: MatDialogRef<MyModalComponent>, private notifservice:SmarqueService,
    private suser:SusersService, private router:Router,) { }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    
const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  
this.email=storageCrypter.getItem('num', 'local');

if (this.email)
{
  this.suser.getuserbyemail(this.email).subscribe(
    data => {
       this.abonner = data.user;
       console.log('abonner:'+this.abonner._id)
       this.socket.on('refrechpage'+this.abonner._id,()=>{
        this.ALLnotif();
       })   
       this.notifservice.get_Notif_id(this.abonner._id).subscribe(


        data=>
        {
  
  this.notif=data.notifs
  console.log("notifs7:"+this.notif.length);
  if(this.notif.length==0)
  {
    this.notifservice.get_Notif_idvu(this.abonner._id).subscribe(


      data=>
      {
  
  this.notif=data.notifs
      })
  }

  else{
  this.notifservice.updatenotiff(this.abonner).subscribe(
    () => {
      console.log('Updated produit valider successfully');
     
    }
      )}
        }
      )  
     }
   )


}
  }
  ALLnotif()
  {
    this.notifservice.get_Notif_id(this.abonner._id).subscribe(


      data=>
      {

this.notif=data.notifs
console.log("notifs:"+this.notif.length);
if (this.notif.length==0)
{
  this.notifservice.get_Notif_idvu(this.abonner._id).subscribe(


    data=>
    {

this.notif=data.notifs
    })
}

      }
    )  
   

  }

  getproduit(id: any) {
    console.log('id produit' + id);
    this.router.navigate([`produit/`+id])
   this. onClose()
    //  this.navCtrl.setRoot(DetproduitPage,{
    //  id : id,
    //    test:1
    //  }) 
  }
  getprofil(email: any) {
    console.log('id produit' + email);
    this.router.navigate([`profileV/`+email])
    this. onClose()

  }
  momenttimenow(time)
  {
    moment.locale('FR')
return moment(time).fromNow();
  }
}
