import { Component, OnInit } from '@angular/core';
import { SocialUser } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";
import { Router } from '@angular/router';
import {StorageCrypter} from 'storage-crypter';
import { ActivatedRoute} from '@angular/router';
import {SusersService} from '../../providers/susers.service';
import {SventeService} from '../../providers/svente.service';
@Component({
  selector: 'app-infovendeur',
  templateUrl: './infovendeur.component.html',
  styleUrls: ['./infovendeur.component.css']
})
export class InfovendeurComponent implements OnInit {
  user: any;
  avis:any;
  loggedIn: boolean;
users:any={};
abonner:any;
moyenne:any;
tailleavis:any;
email:any;
article:any;
suivie:any;
constructor( private authService: SocialAuthService, private svente: SventeService,  private route: ActivatedRoute, private router:Router
  , private suser:SusersService
  
  ) {


 }

ngOnInit() {
 
   
  this.email = this.route.snapshot.paramMap.get('email');
 
  if (this.email)
  {

    this.suser.getuserbyemail( this.email).subscribe(


      data => {

        this.user = data.user
       console.log("User:" + this.users.url_userimage)
     

      }
    )



    this.svente.getusercommandeavis(this.email).subscribe(


      data => {

        this.avis = data.vente
      this.tailleavis=this.avis.length 
        if (this.avis && this.avis.length > 0) {
          let sommeNotes = 0;
          this.tailleavis=this.avis.length 
          this.avis.forEach(avisItem => {
            // Utilisez la propriété 'etoile' de chaque avisItem
            sommeNotes += avisItem.etoile;
          });
        
          // Calculer la moyenne en divisant par le nombre total d'avis
          this.moyenne = sommeNotes / this.avis.length;
        
          console.log('Moyenne des avis :', this.moyenne);
        } else {
          this.moyenne = 0;
          this.tailleavis=this.avis.length 
          console.log('Aucun avis disponible.');
         
        }
      }
    )

  this.suser.getuserabonner(this.email).subscribe(

    data => {
       this.abonner = data;
       console.log('abonner:'+this.abonner)
     }
   )

this.suser.getarticleabonner(this.email).subscribe(

  data => {
     this.article = data;
     console.log('articles:'+this.article)
   }
 )

 this.suser.getsuivieabonner(this.email).subscribe(

  data => {
     this.suivie = data;
     console.log('suivie:'+this.suivie)
   }
 )
}
}
goToachat() {
  this.router.navigate([`addpro`])
}

}