
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProduitService } from '../../providers/produit.service';
import {StorageCrypter} from 'storage-crypter';
import {SusersService}from'../../providers/susers.service';
import {SventeService}from'../../providers/svente.service';
import { io } from "socket.io-client";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
//NOTIF DDANS PROFIDER MARQUE
import {SmarqueService} from'../../providers/smarque.service'; 

@Component({
  selector: 'app-achat3',
  templateUrl: './achat3.component.html',
  styleUrls: ['./achat3.component.css']
})
export class Achat3Component implements OnInit {
  id: any;
  product: any = {};
  users:any={};
  usersvendeur:any={};
  produit: any = {};
  vente:any={};
  prixlivraison:any;
  userForm: FormGroup;
  commision:any=20;
  prix:any=8;
  notif: any = {};
  chef: any = {};
  payment:any=0;
  //produit:any;
  user: any = {};
  socket = io("https://lebesty.com:3000/",{
    
  rejectUnauthorized : false 
});
  constructor(private activatedRoute: ActivatedRoute, 
    
    private suserservice:SusersService,    private notifservice:SmarqueService,
    private router: Router, private Produitsservice: ProduitService, private fb: FormBuilder,private Svente: SventeService) { 


      this.Svente.getprixlivraison().subscribe(


        data=>
        {
  
  this.prixlivraison=data.prix
 
  this.prix = parseFloat(this.prixlivraison[0].prix);
  this.commision = parseFloat(this.prixlivraison[0].commision);
        }
      ) 
    }

  ngOnInit(): void {

    this.userForm = this.fb.group({
      nom: [''],
      prenom: [''],
      telephone: [''],
      adresse: [''],
      adresse2: [''],
      codepostale: [''],
      ville: [''],
      email: [''],
      checkArray: this.fb.array([])
    })


    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.payment = this.activatedRoute.snapshot.paramMap.get('num');
    console.log("payement:"+this.payment);
    console.log("idproduit:"+this.id);
    this.Produitsservice.getProduitByIdService(this.id).subscribe(
      data => {
        this.product = data.produit;

        this.suserservice.getuserbyemail(this.product.id_user).subscribe(


          data=>
          {
    
    this.usersvendeur=data.user
  
          }
        )  





      }
    )




    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  
    this.users.email=storageCrypter.getItem('num', 'local');


    console.log("email;"+this.users.email);
    this.suserservice.getuserbyemail(this.users.email).subscribe(


      data=>
      {

this.user=data.user
console.log("user"+ this.user.nom)
      }
    )  
    
  }
  onCheckboxChange(e) {
    const checkArray: FormArray = this.userForm.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.removeAt(1);
      checkArray.push(new FormControl(e.target.value));
    }
   /* else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }*/
  }

  addChef6() {
    console.log("vendeur",this.user.nom);
this.vente.emailvendeur=this.product.email_user,
this.vente.emailacheteur=this.users.email,
this.vente.adressvendeur=this.usersvendeur.adresse
this.vente.adressevendeur2=this.usersvendeur.adress2,
this.vente.adresslivraison= this.user.adresse,
this.vente.adresselivraison2= this.user.adress2
this.vente.numacheteur= this.user.num,
this.vente.numvendeur=this.usersvendeur.num,
this.vente.nom_Vendeur=this.product.nom_user,
this.vente.nom__Achteur=this.user.nom,
this.vente.codepostaleacheteur=this.user.codepostale,
this.vente.codepostalevendeur=this.usersvendeur.codepostale,

this.vente.villeacheteur=this.user.ville,
this.vente.villevendeur=this.usersvendeur.ville



this.vente.prix=this.product.prix+this.prixlivraison;


this.vente.nom_produit=this.product.titre,
this.vente.id_produit=this.product._id,
this.vente.couleur=this.product.couleur,
this.vente.taille=this.product.taille,
this.vente.etat=this.product.etat,


this.vente.statue=1,
this.vente.valider=0


    this.Svente.add(this.vente).subscribe(
      () => {
        console.log('Updated successfully');
    this.router.navigate(['achat2/'+ this.product._id]);
      }
    ) 


this.Produitsservice.updatepreduitvenduService(this.product).subscribe(
  () => {
    console.log('Updated produit valider successfully');
  
  }
) 

this.router.navigate([`/home`]);
}
    
addChef() {
 // console.log("vendeur",this.usersvendeur.adresse);
  console.log("user",this.user.adresse);
  this.vente.idvendeur=this.product.id_user,
this.vente.idachteur=this.user._id,
  this.vente.emailvendeur=this.product.email_user,
  this.vente.emailacheteur=this.user.email,
  this.vente.adressvendeur=this.usersvendeur.adresse
  this.vente.adressevendeur2=this.usersvendeur.adress2,
  this.vente.adresslivraison= this.user.adresse,
  this.vente.adresselivraison2= this.user.adress2
  this.vente.numacheteur= this.user.num,
  this.vente.numvendeur=this.usersvendeur.num,
  this.vente.nom_Vendeur=this.product.nom_user,
  this.vente.nom__Achteur=this.user.nom,
  this.vente.codepostaleacheteur=this.user.codepostale,
  this.vente.codepostalevendeur=this.usersvendeur.codepostale,
  
  this.vente.villeacheteur=this.user.ville,
  this.vente.villevendeur=this.usersvendeur.ville,
  this.vente.imageacheteur=this.user.url_userimage,
  this.vente.imagevendeur=this.usersvendeur.url_userimage,
  
  
  this.vente.prix=this.product.prix;
  this.vente.prixvente=this.product.prix+this.prix;
  this.vente.prixachat = this.product.prix - (this.product.prix * (this.commision / 100));
  this.vente.image=this.product.image_1;
  this.vente.nom_produit=this.product.titre,
  this.vente.id_produit=this.product._id,
  this.vente.couleur=this.product.couleur,
  this.vente.taille=this.product.taille,
  this.vente.etat=this.product.etat,
  this.vente.marque=this.product.marque,
  
  this.vente.statue=1,
  this.vente.valider=0
  
  console.log("achteur"+this.vente.emailacheteur)
    this.Svente.add(this.vente).subscribe(
        () => {
          console.log('Updated successfully');
    
        }
      ) 
  
  
  this.Produitsservice.updatepreduitvenduService(this.product).subscribe(
    () => {
      console.log('Updated produit valider successfully');
    
    }
  ) 
  
 /////qui sest abonner /////////////////
this.notif.id_user=this.user._id;
this.notif.email_user=this.users.email;
this.notif.image_user=this.user.url_userimage;
////////////////////////////



////recherche par id ou email /////////////
this.notif.id_vendeur=this.product.id_user;
this.notif. email_vendeur=this.product.email_user;
///////////////

///////article////////////
this.notif.nomarticle=this.product.titre;
this.notif.image_article=this.product.image_1;
this.notif.id_article=this.product._id;
this.notif.prix_article=  this.vente.prixachat;
////////////////////////////

////type d action commentaire ou suivie ou ajout article////////////
this.notif.typeaction=4;
/////////////////////////////////////////////////


/////////////commentaire ////////////////////
this.notif.commentaire_article="article vendu";
this.notif.nom_user=this.user.nom;
this.notifservice.add_notif(this.notif).subscribe(
() => {
  console.log('dfl,;ls,f;lsd,abonner');
 

}

  ) 

  this.router.navigate([`/home`]);
}




/*emailvendeur:String,
 
    emailacheteur:String,
    adressvendeur:String,
    adressevendeur2:String,
    adresslivraison:String,
    adresselivraison2:String,
    numacheteur:String,
    numvendeur:String,
    nom_Vendeur:String,
    nom_Achteur:String,
    codepostaleacheteur:Number,
    codepostalevendeur:Number,
    villeacheteur:String,
    villevendeur:String,
   

     prix:String,
     
    nom_produit:String,
    id_produit:String,
    couleur:String,
    taille:String,*/ 

}


