import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScouleurService {

  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) 
  
  { }

  add(couleur:any) {
    return this.httpClient.post(`${this.URL}/addcouleur`, couleur);
  }


  getAllcouleurService() {
    return this.httpClient.get<{couleurs:any}>(`${this.URL}/couleurs`);
  }

}
