

  <!-- Page Content -->
  <div class="container1">

    <div class="row">

      <div class="col-lg-3">
<div class="mar">

  <!-- la section droite de la page vous cree beaucoup des categorie -->



<app-infouser></app-infouser>

<app-recherche2></app-recherche2>

<!-- <app-categorie></app-categorie>  -->

<!-- <app-coupcoueur></app-coupcoueur> -->
<app-appstore></app-appstore>
<app-reseausocial></app-reseausocial>

</div>
      </div>
<hr>

      <!-- /.col-lg-3 -->

      <div class="col-lg-9">

        
<!--<app-baniere></app-baniere>-->
      <app-listeprods></app-listeprods>
        <!-- /.row -->

      </div>
      <!-- /.col-lg-9 -->

    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->

  <!-- Footer -->
 
