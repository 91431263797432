


<div class="row">
   
    <div class="col-lg-3">
<app-infouser></app-infouser>
<br>
<app-abonnerinfo></app-abonnerinfo>

</div>
    <div class="col-lg-8">
        <div class="card text-center">
            <div class="card-block">
                <form [formGroup]="userForm" (ngSubmit)='addChef()'>
                <div class=entete>
                <h2>Mon Adresse</h2>
                  <span class="description"> Tu peux ajouter ou modifier tes informations personnelles</span>
                  <br>
                  <span class="description"> En tant que vendeur, ce sera ton adresse de retour en cas de problème.</span>
              
                </div>    <br>
           
                <div class="row">
                    <div class="col-3">
                       <span class="descri">Adresse</span>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <textarea class="form-control" [(ngModel)]='user.adresse' formControlName="adresse"  rows="5"  placeholder=''></textarea>

                        </div>
                    </div>
                        <div class="col-3">
                            <span class="deescri2"></span>
                         </div>
      
             
         
                   <div class="col-3">
                    <span class="descri">Complément d'adresse</span>

                 </div>
                 <div class="col-6">
                     <div class="form-group">
                         <input class="form-control" [(ngModel)]= 'user.adress2' formControlName="adresse2" type="text" placeholder=''>
                     </div>
                 </div>
                     <div class="col-3">
                         <span class="deescri2"></span>
                      </div>
                      <div class="col-3">
                        <span class="descri">Code Postal</span>
                     </div>
                     <div class="col-6">
                         <div class="form-group">
                             <input class="form-control" [(ngModel)]= 'user.codepostale' formControlName="codepostale"  type="number" placeholder=''>
                         </div>
                     </div>
                         <div class="col-3">
                             <span class="deescri2"></span>
                          </div>


                        
                       <div class="col-3">
                        <span class="descri">Ville</span>
                     </div>
                     <div class="col-6">
                         <div class="form-group">
                             <input class="form-control" [(ngModel)]= 'user.ville'  formControlName="ville" type="text" placeholder=''>
                         </div>
                     </div>
                         <div class="col-3">
                             <span class="deescri2"></span>
                          </div>

                         
                    <div class="col-12">
                      
                        <div class="form-group mt-3">
                            <button type="submit" [disabled]="!userForm.valid"  class="button button-contactForm btn_4">Valider</button>
                        </div>
                    </div>

                   
                </div>

            </form>
          
            </div>
        </div>
    </div>
   


</div>
