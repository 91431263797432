import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SgenreService {
  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) { }

  add(genre:any) {
    return this.httpClient.post(`${this.URL}/addgenre`, genre);
  }


  getAllgenreService() {
    return this.httpClient.get<{genres:any}>(`${this.URL}/genres`);
  }
}