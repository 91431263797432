<div class="container"  *ngIf="taille>0" >




    <ul>
        <li *ngFor="let commentairee of commentaires">
            <a class="nav-link" routerLink='/addpro'><img   class="avatar" src="{{commentairee.imageuser}}"> <span class="nomA">{{commentairee.nomacheteur}}</span> </a>
            <span class="badgrree">{{momenttimenow(commentairee.heure)}}</span>
            <span class="badgrre"> {{commentairee.description}}</span>
        </li>
    </ul>

    
</div>