

<h1 class="entete">Résumé de la commande</h1>




<div class="card text-center">

    <div class="card-block">

        <div class="image">

            <img class="card-img-top" src="{{product.image_1}}" alt="">
        </div>

        <div class="detaille">
          


              

                <div class="row">

                  
                    <div class="col-12 M5">
                        A la réception de votre colis, vous devez payer la somme de
                        <span class="prix"> {{product.prix +prix}} DT</span>
                        en espèces
                    </div>
                </div>
                <br><br>
                <div class="row">

                    <div class="col-6">
                        <span class="nom1">Prix livraison</span>

                    </div>
                    <div class="col-6">
                        <span class="nom">{{prix}} DT</span>

                    </div>
                </div>

                <div class="row">

                    <div class="col-6">
                        <span class="nom1">taille</span>

                    </div>
                    <div class="col-6">
                        <span class="nom">{{product.taille}}</span>

                    </div>
                </div>

                <div class="row">

                    <div class="col-6">
                        <span class="nom1">marque</span>

                    </div>
                    <div class="col-6">
                        <span class="nom">{{product.marque}}</span>

                    </div>
                </div>



          






            <div class="row">




                <div class="col-6">


                    <span class="nom1">Prenom</span>
                </div>
                <div class="col-6">
                    <span class="nom">{{user.prenom}}</span>

                </div>
            </div>

            <div class="row">
                <div class="col-6">


                    <span class="nom1">Nom</span>
                </div>
                <div class="col-6">
                    <span class="nom">{{user.nom}}</span>

                </div>
            </div>

            <div class="row">
                <div class="col-6">


                    <span class="nom1">Numero Téléphone</span>
                </div>
                <div class="col-6">
                    <span class="nom">{{user.num}}</span>

                </div>
            </div>

            <!-- <div class="row">
                <div class="col-6">


                    <span class="nom1">email</span>
                </div>
                <div class="col-6">
                    <span class="nom">{{user.email}}</span>

                </div>
            </div> -->

       
     
            <div class="row">
                <div class="col-6">


                    <span class="nom1">Code postale</span>
                </div>
                <div class="col-6">
                    <span class="nom">{{user.codepostale}}</span>

                </div>
            </div>

            <div class="row">
                <div class="col-6">


                    <span class="nom1">Ville</span>
                </div>
                <div class="col-6">
                    <span class="nom">{{user.ville}}</span>

                </div>
            </div>

            <div class="row">
                <span class="adress"> L'adresse enregistré pour la livraison est la suivante :</span>
              </div>
              <div class="row">
                 
                  <div class="col-12">
                      <span class="nom">{{user.adresse}} {{user.adress2}}</span>
  
                  </div>
              </div>
  
            <div class="col-12">

                <div class="form-group mt-3">
                    <button class="btn btn-info" (click)='addChef()'>confirmer</button>
                </div>
            </div>





        </div>
        </div>
    </div>