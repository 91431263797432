
<!-- Page Content -->
<div class="container1">

  <div class="row">

    <div class="col-lg-3">
      <div class="mar">

        <!-- la section droite de la page vous cree beaucoup des categorie -->



        <app-infouser></app-infouser>

        <app-recherche2></app-recherche2>

      
        <app-appstore></app-appstore>
        <app-reseausocial></app-reseausocial>

      </div>
    </div>
    <hr>

    <!-- /.col-lg-3 -->

    <div class="col-lg-9">


      <!--<app-baniere></app-baniere>-->
      <div class="row">

        <div class="col-lg-3 col-md-6 mb-4" *ngFor="let product of productss index as i">
          <div class="card h-100">



            <img class="avatar" src="{{product.url_userimage}}">


            <span class="user-name"> {{product.nom_user}}</span>

            <a routerLink='/produit/{{product._id}}'><img class="card-img-top" src="{{product.image_1}}" alt=""></a>
            <div class="card-body">
              <!--<h4 class="card-title">
          <a href="#">{{product.titre}}</a>
        </h4>-->
              <p class="card-text2">{{product.Countfavoirie}}</p>
              <!-- <button type="button" *ngIf="!isFav(product._id,i)"  class="notif"  (click)='coeur(product._id,product.Countfavoirie,i)'>  <img class="notif"  src="../../../assets/images/coeur2.png"></button>
        <button type="button" *ngIf="isFav(product._id,i)"  class="notif"  (click)='coeur(product._id,product.Countfavoirie,i)'>  <img class="notif"  src="../../../assets/images/coeur3.png"></button>
       -->
              <h5 class="card-title">{{product.prix}}DT</h5>



              <p class="card-text">{{product.taille}}</p>
              <h5 class="card-marque">{{product.marque}}</h5>
            </div>

          </div>
        </div>



        <!-- /.row -->

      </div>
      <!-- /.col-lg-9 -->

    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->

  <!-- Footer -->