import { Component, OnInit } from '@angular/core';
import { SocialUser } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";
import { Router } from '@angular/router';
import {StorageCrypter} from 'storage-crypter';
import {SusersService} from '../providers/susers.service';
import {SventeService} from '../providers/svente.service';

@Component({
  selector: 'app-infouser',
  templateUrl: './infouser.component.html',
  styleUrls: ['./infouser.component.css']
})
export class InfouserComponent implements OnInit {
  user: any;
  loggedIn: boolean;
users:any={};
abonner:any;
moyenne:any;
tailleavis:any;
avis:any;
article:any;
suivie:any;
  constructor( private authService: SocialAuthService,  private svente: SventeService, private router:Router
    , private suser:SusersService
    
    ) {

  
   }

  ngOnInit() {
   
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  
    this.users.email=storageCrypter.getItem('num', 'local');
   
    if (this.users.email)
    {
    this.suser.getuserabonner(this.users.email).subscribe(

      data => {
         this.abonner = data;
         console.log('abonner:'+this.abonner)
       }
     )
     this.svente.getusercommandeavis(this.users.email).subscribe(


      data => {

        this.avis = data.vente
      this.tailleavis=this.avis.length 
        if (this.avis && this.avis.length > 0) {
          let sommeNotes = 0;
          this.tailleavis=this.avis.length 
          this.avis.forEach(avisItem => {
            // Utilisez la propriété 'etoile' de chaque avisItem
            sommeNotes += avisItem.etoile;
          });
        
          // Calculer la moyenne en divisant par le nombre total d'avis
          this.moyenne = sommeNotes / this.avis.length;
        
         
        } else {
          this.moyenne = 0;
          this.tailleavis=this.avis.length 
          console.log('Aucun avis disponible.');
         
        }
      }
    )
     this.suser.getuserbyemail( this.users.email).subscribe(


      data => {

        this.user = data.user
       console.log("User:" + this.user.url_userimage)
     

      }
    )
  this.suser.getarticleabonner(this.users.email).subscribe(

    data => {
       this.article = data;
       console.log('articles:'+this.article)
     }
   )

   this.suser.getsuivieabonner(this.users.email).subscribe(

    data => {
       this.suivie = data;
       console.log('suivie:'+this.suivie)
     }
   )
    }
  }

  goToachat() {
    this.router.navigate([`addpro`])
  }

}