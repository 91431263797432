import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SusersService} from'../../providers/susers.service';
import {StorageCrypter} from 'storage-crypter';
import { FormsModule , Validators} from '@angular/forms';

import { FormGroup, FormBuilder ,FormControl} from '@angular/forms';
@Component({
  selector: 'app-iscription',
  templateUrl: './iscription.component.html',
  styleUrls: ['./iscription.component.css']
})
export class IscriptionComponent implements OnInit {

  public slideOneForm: FormGroup;
  agent: any = {};
  id:any;
 id2:number=0;
 isSubmitting: boolean = false;
 isAddingUser: boolean = false;
model:any;
poido:any;
resultat: any;
submitAttempt: boolean = false;
load:any;
poidoo:any;
dat:any=new Date().toISOString().substring(0, 4);
datee:string=new Date().toISOString().substring(0, 10);
age:any;

  constructor(private router:Router, 
    private suser:SusersService,
    private fb: FormBuilder) { }

  ngOnInit(): void {

   this. agent.condi=true;
   this. agent.sexe='1';
    this.slideOneForm = this.fb.group({
      nom: new FormControl('', [Validators.required ]),
      
      num: ['', Validators.compose([Validators.maxLength(8),Validators.minLength(8) ,Validators.pattern('[0-9 ]{8}'), Validators.required])],
      // // prenom: ['', Validators.compose([ ,Validators.pattern('[a-zA-ZÀ-ž_0-9  -]*'),Validators.required])],
       condition:  ['false', Validators.compose([ Validators.requiredTrue])],
     
     
   
       sexe:  ['', Validators.compose([ Validators.pattern('[0-9 ]*'),Validators.required])],
    })
  }
  ajoutagent()
  {
    console.log("ddd");
    this.submitAttempt = true;
    if (this.slideOneForm.valid && !this.isSubmitting) {

      this.isSubmitting = true

   
    this.suser.getnbre_user_numero(this.agent.num).subscribe(
      data => {
        this.resultat = data;
        console.log('resultat'+ this.resultat);
        if (this.resultat != 0) {

              
    this.suser.addnumerouser(this.agent).subscribe(
      (data) => {
        console.log('Chef added numero');
        this.isSubmitting = false;
        console.log(data);
      }
    )
          //  si lutlisateur existe le valider a
          console.log('abonnerexiste :' + data)

          this.router.navigate(['verfier'], { queryParams: { name: this.agent.nom,sexe:this.agent.sexe,numero:this.agent.num } });
      



        }
        else {
          this.agent.valider=0;
          this.agent.firstName = this.agent.nom;
          this.agent.numero=this.agent.num;
          console.log('nouveau abonner  :' + data)
          this.isAddingUser = false; // Assurez-vous de réinitialiser le drapeau même en cas d'erreur
          this.suser.add(this.agent).subscribe(
data => {

console.log("id"+data.message);
const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');

storageCrypter.setItem('num', data.message, 'local');


              console.log('user added successfully');
              this.isSubmitting = false;
              this.reloadPage();
           
             
            }
          )
        
        }
      }
    )










  
//     this.navCtrl.push(VefnumPage,{
      
//       name: this.agent.nom,
//       prenom:this.agent.prenom,
//     sexe:this.agent.sexe,
//     numero:this.agent.num,
// naissance:this.agent.datenai
//     })
       
     //  this.navCtrl.setRoot(DetproduitPage,{
      //  id : id,
    //    test:1
    //  }) 
         
      }



}
reloadPage() {
  setTimeout(()=>{
    window.location.reload();

  }, 100);
  this.router.navigate(['home'], { queryParams: { } });
}
}
