import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProduitService } from '../../providers/produit.service';
import {StorageCrypter} from 'storage-crypter';
import {SusersService}from'../../providers/susers.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-abonnertaille',
  templateUrl: './abonnertaille.component.html',
  styleUrls: ['./abonnertaille.component.css']
})
export class AbonnertailleComponent implements OnInit {
  id: any;
  product: any = {};
  users:any={};
  produit: any = {};
  userForm: FormGroup;
  chef: any = {};
  //produit:any;
  user: any = {};


  constructor(private activatedRoute: ActivatedRoute, private suserservice:SusersService,
    private router: Router, private Produitsservice: ProduitService, private fb: FormBuilder)
     { }

 
  ngOnInit(): void {

    this.userForm = this.fb.group({
      nom: [''],
      prenom: [''],
      telephone: [''],
      adresse: [''],
      adresse2: [''],
      codepostale: [''],
      ville: [''],
      email: [''],
      checkArray: this.fb.array([])
    })

    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  
    this.users.email=storageCrypter.getItem('num', 'local');

    console.log("email;"+this.users.email);
    this.suserservice.getuserbyemail(this.users.email).subscribe(


      data=>
      {

this.user=data.user
console.log("user"+ this.user.nom)
      }
    )  
  }
  addChef() {
    this.suserservice.updateUserService(this.user).subscribe(
      () => {
        console.log('Updated successfully');
       this.router.navigate(['/address']);
      }
    ) 

  }
}
