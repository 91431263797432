<div class="container">
<div class="row">
    <button (click)="pro('Le prix, est-il négociable ?')" class="tonexp">Le prix, est-il négociable ?</button>
    <button  (click)="pro('As-tu une photo portée ?')" class="tonexp">As-tu une photo portée ?</button>
    <button  (click)="pro('Peux-tu mettre plus de photos ?')" class="tonexp">Peux-tu mettre plus de photos ?</button>
    <button  (click)="pro('Est-il toujours disponible  ?')" class="tonexp">Est-il toujours disponible ?</button>
    <button  (click)="pro('Des défauts à signaler ?')" class="tonexp">Des défauts à signaler ?</button>
 
</div>
    <div class="row">

        <div class="col-lg-12">
            <form [formGroup]="comentaireForm"  (ngSubmit)='addCommentaire()'>
                <div class="row">
                    <div class="col-9">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='commentaire.commentaire'
                    type="text"  formControlName="commentaire"
                                placeholder='Ajouter un commentaire'>
                        </div>

                        <div
                        *ngIf="comentaireForm.controls['commentaire'].invalid && (comentaireForm.controls['commentaire'].dirty || comentaireForm.controls['commentaire'].touched)"
                        class="alert alert-danger">
                        <div *ngIf="comentaireForm.controls['commentaire'].errors.required">
                          il nya pas de message.
                        </div>
                      </div>
                    </div>
                
                <div class="col-3">
                    <button *ngIf="email" type="submit" class="button button-contactForm btn_4">Commenter</button>
                    <button *ngIf="!email" type="button"  routerLink="/inscri" class="button button-contactForm btn_4">Commenter</button>
                </div>
                </div>
            </form>
        </div>
    </div>
</div>