<div class="card text-center">

    <div class="card-block"></div>
    <h5 class="topvendeur">Télécharger votre appli Lebesty!</h5>
    <div class="card-blockapp">

    

    

        <a href="https://play.google.com/store/apps/details?id=com.labesni.app&hl=fr&gl=US">
            <img class="avatar" src="https://lebesty.com:3000/images/play-store-badge_fr.png">
          </a>
          
          <a href="https://apps.apple.com/tn/app/lebesty/id6476626371?platform=iphone">
            <img class="avatar" src="https://lebesty.com:3000/images/app-store-badge_fr.png">
          </a>
          
          <a href="https://appgallery.huawei.com/#/app/C107362159">
            <img class="avatar4" src="https://lebesty.com:3000/images/appgallry.png">
          </a>

    </div>