import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import {ProduitService} from '../../providers/produit.service';
@Component({
  selector: 'app-infousrarticle',
  templateUrl: './infousrarticle.component.html',
  styleUrls: ['./infousrarticle.component.css']
})
export class InfousrarticleComponent implements OnInit {
  productss:any;
  constructor( private Produitsservice:ProduitService,private authService: SocialAuthService) { }

  ngOnInit(): void {
   
    this.Produitsservice.getAllProduitService().subscribe(
      (data) => {
        this.productss = data.produits;
        console.log('produit:'+this.productss);
      }
     
    );

  

  }

}
