import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProduitService } from '../../providers/produit.service';
import { StorageCrypter } from 'storage-crypter';
import { SusersService } from '../../providers/susers.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-achat',
  templateUrl: './achat.component.html',
  styleUrls: ['./achat.component.css']
})
export class AchatComponent implements OnInit {
  id: any;
  product: any = {};
  users: any = {};
  produit: any = {};
  userForm: FormGroup;
  chef: any = {};
  //produit:any;
  user: any = {};

  constructor(private activatedRoute: ActivatedRoute,

    private suserservice: SusersService,
    private router: Router, private Produitsservice: ProduitService, private fb: FormBuilder,) { }

  ngOnInit(): void {

    this.userForm = this.fb.group({
      nom:  new FormControl('', [Validators.required, ]),
      prenom: new FormControl('', [Validators.required, ]),
      num: ['', Validators.compose([Validators.maxLength(8),Validators.minLength(8) ,Validators.pattern('[0-9 ]{8}'), Validators.required])],
      adresse:new FormControl('', [Validators.required, ]),
      adresse2: [''],
      codepostale: ['', Validators.compose([ Validators.pattern('[0-9 ]{4}'), Validators.required])],
      ville: new FormControl('', [Validators.required, ]),
      email: [''],
     
    })


    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.Produitsservice.getProduitByIdService(this.id).subscribe(
      data => {
        this.product = data.produit;

      }
    )

    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');

    this.users.email = storageCrypter.getItem('num', 'local');

    console.log("email;" + this.users.email);
    this.suserservice.getuserbyemail(this.users.email).subscribe(


      data => {

        this.user = data.user
        console.log("user" + this.user.nom)
      }
    )

  }
  onCheckboxChange(e) {
    const checkArray: FormArray = this.userForm.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.removeAt(1);
      checkArray.push(new FormControl(e.target.value));
    }
    /* else {
       let i: number = 0;
       checkArray.controls.forEach((item: FormControl) => {
         if (item.value == e.target.value) {
           checkArray.removeAt(i);
           return;
         }
         i++;
       });
     }*/
  }

  addChef() {


    this.suserservice.updateUserService(this.user).subscribe(
      () => {
        console.log('Updated successfully');
        this.router.navigate(['achat3/'+ this.product._id+'/'+"1"]);
      }
    )
  }
}


