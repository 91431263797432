

  <!-- Page Content -->
  <div class="container1">

    <div class="row">

      <div class="col-lg-3">
<div class="mar">

  <!-- la section droite de la page vous cree beaucoup des categorie -->



<app-infovendeur></app-infovendeur>


<app-recherche2></app-recherche2>



<app-appstore></app-appstore>
<app-reseausocial></app-reseausocial>

</div>
      </div>
<hr>

      <!-- /.col-lg-3 -->

      <div class="col-lg-9">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Disponibles (<strong>{{tailleproduitnonvendu}}</strong>)</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Vendus  (<strong>{{tailleproduitvendu}}</strong>)</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#avis" role="tab" aria-controls="profile" aria-selected="false">AVIS  (<strong>{{tailleavis}}</strong>)</a>
          </li>
         
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <app-listeproduitprofil></app-listeproduitprofil>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

          <app-listeproduitprofilvendu>
          </app-listeproduitprofilvendu>
          </div>
          <div class="tab-pane fade" id="avis" role="tabpanel" aria-labelledby="profile-tab">
            <div class="product-list">
              <div class="product-item" *ngFor="let product of avis; let i = index">
                <div class="product">
                  <div class="product-bottom">
                    <div class="profil">
             
                        <img class="avatar" [src]="product.url_achteurimage" />
                      
                      <span class="user">{{product.nom_Vendeur}}</span>
                    </div>
                    <div>
                      <span class="badgrree">{{momenttimenow(product.heure)}}</span>
                    </div>
                 
                    <span class="description">{{product.avis}}</span>
                    <br>
                 
<ngb-rating [max]=5 [readonly]="true"  [(rate)]=product.etoile></ngb-rating> 
                  </div>
                </div>
              </div>
            </div>
            </div>
      </div>
      </div>
       
<!--<app-baniere></app-baniere>-->
    
        <!-- /.row -->
  
      <!-- /.col-lg-9 -->

    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->

  <!-- Footer -->
 
