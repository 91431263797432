
<div class="card text-center">



    <div class="card-block">
 
     
      <div id="carouselExampleIndicators1" class="carousel slide my-4" data-ride="carousel1">
         
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators1" *ngIf="product.image_1" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators1" *ngIf="product.image_2" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators1" *ngIf="product.image_3" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators1" *ngIf="product.image_4" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators1" *ngIf="product.image_5" data-slide-to="4"></li>
    </ol>
        <div class="carousel-inner" role="listbox">
            <div class="carousel-item active" *ngIf="product.image_1">
         
                <img class="d-block img-fluid" src="{{ product.image_1 }}" alt="First slide">
            </div>
            <div class="carousel-item" *ngIf="product.image_2">
                <img class="d-block img-fluid" src="{{ product.image_2 }}" alt="Second slide">
            </div>
            <div class="carousel-item" *ngIf="product.image_3">
                <img class="d-block img-fluid" src="{{ product.image_3 }}" alt="Third slide">
            </div>
            <div class="carousel-item" *ngIf="product.image_4">
                <img class="d-block img-fluid" src="{{ product.image_4 }}" alt="Fourth slide">
            </div>
            <div class="carousel-item" *ngIf="product.image_5">
                <img class="d-block img-fluid" src="{{ product.image_5 }}" alt="Fifth slide">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators1" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>

    
    
    
        
    </div>