import { Component, OnInit } from '@angular/core';
import {SventeService} from'../../providers/svente.service';
import { PageEvent } from '@angular/material/paginator';
import *  as moment from 'moment';
import { ProduitService } from '../../providers/produit.service';

import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-commandeenvoyer',
  templateUrl: './commandeenvoyer.component.html',
  styleUrls: ['./commandeenvoyer.component.css']
})
export class CommandeenvoyerComponent implements OnInit {



  constructor(    private svente:SventeService,
    private router:Router, private Produitsservice: ProduitService) { }
 // Commandes:any={};

 Commandes: any[] = []; // Vos données de commande
 pagedCommandes: any[] = []; // Tableau de commandes pour la page actuelle
 pageSize: number = 5; // Taille de la page
 currentPage: number = 1; // Page actuelle
 totalPages: number = 1; // Nombre total de pages
  ngOnInit(): void {

    
      this.svente.commandeenvoyer().subscribe(data => {
        this.Commandes = data.commande;
        this.totalPages = Math.ceil(this.Commandes.length / this.pageSize);
        this.updatePageData();
      });
    }
  

  updatePageData(): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.Commandes.length);
    this.pagedCommandes = this.Commandes.slice(startIndex, endIndex);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePageData();
    }
  }

  momenttimenow(time)
  {
    moment.locale('FR')
return moment(time).fromNow();
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePageData();
    }
  }
 formatDateString(dateString: string) {
    const dateObj = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    return dateObj.toLocaleString('fr-FR', options);
}
  confirmer(commande){

  

    this.svente.modifiercommandelivrer(commande).subscribe(data => {
      console.log('Updated commande valider successfully');
   
      this.reloadPage();
    });
  
  }
  annuler(commande){
  
    
    this.svente.commandeanuler(commande).subscribe(data => {
      console.log('Updated commande valider successfully');
      this.reloadPage();

    });

    this.Produitsservice.updatepreduitannuler(commande).subscribe(
      () => {
        console.log('Updated produit valider successfully');
      
      }
    )
  }
  reloadPage() {
    setTimeout(()=>{
      window.location.reload();

    }, 10);
 
}
}
