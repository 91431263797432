

<!-- Page Content -->
<div class="container1">

  <div class="row">

    <div class="col-lg-3">
<div class="mar">

<!-- la section droite de la page vous cree beaucoup des categorie -->



<app-infovendeur></app-infovendeur>




<app-coupcoueur></app-coupcoueur>
<app-appstore></app-appstore>
<app-reseausocial></app-reseausocial>

</div>
    </div>
<hr>

    <!-- /.col-lg-3 -->

    <div class="col-lg-9">
      
      <mat-tab-group>
          <mat-tab label="ACHATS">
            
            <table class="table caption-top">
                <caption>List of users</caption>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Date</th>
                    <th scope="col">Vendeur</th>
                    <th scope="col">Article</th>
                    <th scope="col">Prix</th>
                    <th scope="col">Progression</th>
                    <th scope="col">Détails</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                     <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
          </mat-tab>
          <mat-tab label="VENTES"> 
              
        
        
        
            <table class="table caption-top">
                <caption>List of users</caption>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Date</th>
                    <th scope="col">Article</th>
                    <th scope="col">Vercement</th>
                    <th scope="col">N°Livraison</th>
                    <th scope="col">Progression</th>
                    <th scope="col">Détails</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                     <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
        
        
        
        
        
        
        
        
        
        
        
        </mat-tab>
        
        </mat-tab-group>
<!--<app-baniere></app-baniere>-->
  
      <!-- /.row -->
     
    </div>
    <!-- /.col-lg-9 -->

  </div>
  <!-- /.row -->

</div>
<!-- /.container -->

<!-- Footer -->

