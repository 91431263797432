<h1>Conditions d'utilisation et informations legales</h1>
 <p>Le Site met en relation des Vendeurs et des Acheteurs souhaitant vendre des articles,
    neufs et d’occasion (ci-après les Articles) pour homme,femmes et enfants. Les Vendeurs et les 
    Acheteurs sont définis ci-après par le terme « Utilisateurs ».
  </p>
<p>Lorsqu’un utilisateur achète un produit, son règlement est conservé par lebesty.
   Cette somme est versée au Vendeur lorsque l’Acheteur confirme la bonne réception
    et la conformité du produit reçu.
</p>
<h3 class="header">Article 1. Inscription sur le Site</h3>
<p>Lors de son inscription sur Lebesty, l’utilisateur devra remplir un formulaire contenant son nom,
   son prénom, son pseudonyme, son numéro de téléphone . 
   Son numéro de téléphone sera confirmé par la réception d’un SMS à des fins de sécurité.
    L’utilisateur pourra également choisir de s’inscrire via son compte Facebook,Google ou son compte Apple ,
     il sera par la suite invité à compléter ses informations personnelles 
  afin de pouvoir profiter pleinement des services de lebesty.</p>

  <p>L'Utilisateur s’engage à ne pas porter atteinte au respect de la vie privée des autres 
    Utilisateurs et à ne pas leur envoyer de message, d’objet, de documentation notamment publicitaire, 
    par quelque moyen que ce soit (notamment par voie postale, téléphonique ou électronique). En cas de non respect,
     le site se réserve le droit déclencher toutes les procédures qu’il jugera utiles.
    </p>
<p>
  L’Utilisateur s’engage à ne pas indiquer ses coordonnées (téléphone, adresse, email…) dans ses Annonces, 
  ni dans les commentaires des articles, ni dans le nom, le pseudo ou la description de son Dressing.
  Lebesty pourra, dans le cas échant, supprimer ces informations sans en avoir fait la 
   demande préalable auprès de l’Utilisateur. En cas de non respect, le site se réserve le droit déclencher toutes 
  les procédures qu’il jugera utiles, à savoir bannir provisoirement ou définitivement l’utilisateur.
</p>
<p>
  L’Utilisateur s’engage à ne pas employer sur le Site , des propos contraires aux bonnes mœurs, injurieux, 
  diffamatoires ou pouvant porter atteinte à la réputation des autres Utilisateurs. En cas de non-respect de
   cette obligation, Lebesty se réserve le droit de suspendre le compte de l’Utilisateur sans notification préalable.
    En cas de récidive, Lebesty se réserve le droit
   de clôturer définitivement le compte de l’Utilisateur et de déclencher toutes les procédures qu’il jugera utiles.
</p>
<h3 class="header">Article 2. Confidentialité</h3>
<h3 class="header2">2.1 Dispositions générales</h3>
<p>
  Seuls les résidents en Tunisie ont le droit d’utiliser le site et l'application. Lebesty s’engage à recueillir 
  et utiliser les Données Personnelles des Visiteurs ou des Utilisateurs dans le strict respect de la loi tunisienne 
  sur la protection des données

</p>
<h3 class="header2">2.2 Recueil et stockage des données personnelles</h3>

<p>
 Lebesty recueille, stocke et utilise les Données Personnelles suivantes des Utilisateurs :</p>
<p> -Au moment de l’inscription ou de la modification du compte: nom, prénom, numéro de téléphone.</p>
<p>- Au moment de l’utilisation des Services : Article référencé , Données de localisation des Utilisateurs.</p>


<h3 class="header2">2.5 Protection des données personnelles</h3>
<p>
  lebesty garantit que les Données Personnelles fournies par un Utilisateur et/ou un Visiteur sont protégées contre 
  toute activité illégale, telle que l’utilisation abusive des Données Personnelles, la modification ou la destruction
   des Données Personnelles, l’usurpation d’identité, la fraude. La divulgation de l'identité d'un Utilisateur
   à un autre n'est dévoilé qu'en cas de procédure entre ces derniers.
</p>
<p>Les Utilisateurs et les Visiteurs s’engagent à ne pas divulguer leurs Données Personnelles,
   ni les Données Personnelles d’un tiers si celles-ci ont été mises à leur disposition, 
  et doivent informer Lebesty immédiatement en cas de violations.</p>

  <h3 class="header">Article 3. Vendre sur Lebesty</h3>
  <h3 class="header2">3.1 Articles mis en vente</h3>
  <p>
    Le Vendeur s’engage à ce que tout Article mis en vente corresponde à un bien matériel disponible immédiatement dont 
    il dispose de la pleine et entière propriété et capacité de vente.
  </p>
<p>
  Le Vendeur s’engage à ce que les Articles ne comportent pas de défauts : tache visible, article troué ou déchiré, etc.
   L’Acheteur ayant reçu un Article dans cet état, dispose d’un droit de retour de ce dernier.
</p>
<p>
  Le Vendeur s’engage à emballer correctement son article, spécialement les articles qui risquent d’être 
  abîmés lors du transport. Lebesty ainsi que ses partenaires de livraison ne sont pas tenus responsables 
  de tout sinistre survenu lors de l’acheminement d’un article vers son destinataire. 
  Il incombe au Vendeur de veiller à bien emballer son article
   (en utilisant du papier-bulle ou autre) afin de limiter le risque de casse.
</p>
<p>Le Vendeur s’engage à ce que tout article de beauté (maquillage et soins) mis en vente corresponde à 
  un produit non ouvert dans son emballage d’origine.</p>
  <p>
    Les articles de lingerie, maillots de bain, chaussettes et collants ainsi que les vêtements de nuit mis en vente sur 
    Lebesty 
    doivent être neufs(avec ou sans étiquette).
  </p>
  <p>
    Le Vendeur s'engage à fournir une photo réelle de son Article mis en vente.
  </p>
  <p>
    Le Vendeur s’engage à décrire l’Article sur le Site. La description de 
    l’Article doit correspondre à ses caractéristiques effectives. Le Vendeur est le seul responsable
     de la description des articles.

  </p>
  <p>

   Lebesty se réserve le droit de solliciter au Vendeur qu’il modifie et/ou supprime son Annonce 
    et/ou de le faire directement, notamment en cas d’erreur concernant la catégorie de l’article, 
    ou en cas de renseignement des
     coordonnées dans l’annonce, ou en cas d’article interdit de vente sur le site.
  </p>
  <p>
    Le Vendeur détermine le Prix de vente de l’Article. Le prix d’achat en boutique n’est pas obligatoire.
     Si le Vendeur ne connaît pas le prix d’achat, il peut se contenter de mettre une estimation.
  </p>
<p>
 Lebesty s’engage à diffuser l’Annonce du Vendeur sur le Site sans limitation de durée, sous réserve du respect par
   le Vendeur des présentes Conditions Générales.
  Lebesty rend indisponible l’Annonce du Vendeur immédiatement après l’émission par un Acheteur d’une Offre d’Achat.
</p>
<p>
  Le Vendeur accepte que son Article soit diffusé sur les sites et les supports partenaires de Lebesty.

</p>

<h3 class="header2">3.2 Déroulement d’une transaction ( Vente / Achat )</h3>
<p>Lorsque l’Acheteur paye un article en ligne sur le site ou passe une commande pour un paiement à la livraison, Lebesty notifie le Vendeur par email
  , par sms et sur le Site.</p>
  <p>
    Le Vendeur s’engage à confier le colis au livreur qui passera chez lui.
  </p>
  <p>Les Offres d’Achat émises par l’Acheteur sont indépendantes les unes des autres.</p>

  <h3 class="header2">3.3 Paiement de la commande</h3>

  <p>Les paiements en ligne sont assurés par la plateforme de paiement GPG.
    GPG est un service sécurisé de gestion des transactions.</p>
    <p>
      L’Acheteur peut payer :
    </p>
    <p>- En espèces à la livraison</p>
    <p>- Par carte bancaire</p>
    <p>- Par carte e-dinar</p>
    <p>En espèces dans les bornes RUNPAY disponibles dans toute la Tunisie</p>
  
    <p>La collecte des informations de l’Acheteur de carte bancaire est assurée par GPG .
       La sauvegarde de ces informations se fait en tout état de cause avec le consentement 
       de l’Acheteur, sur le serveur de GPG.</p>
       <p>
        En aucun cas, Lebesty ne stocke ou n’a accès aux coordonnées bancaires des Acheteurs.
       </p>

       <h3 class="header2">3.4 Livraison de l’Article</h3>
       <p>Le Vendeur s'engage à confier l'Article acheté au livreur qui se présentera à son domicile.</p>
       <p>Le colis doit être bien emballé. Le livreur remet une décharge et un numéro de suivi au Vendeur.
         Le Vendeur doit conserver ces documents pendant le mois suivant la date d’expédition. Sans décharge,
          le Vendeur ne peut faire
         valoir ses droits auprès du partenaire de livraison ou de Lebesty</p>
         <p>Le Vendeur s’engage à n’insérer aucune publicité pour lui-même ou pour quiconque, sous quelque forme que ce soit.</p>

         <h3 class="header2">3.5. Réception du colis</h3>
         <p>
          En cas de passage à domicile, l’Acheteur s’engage à répondre au téléphone pour confirmer le rendez-vous du passage
           du livreur, et à respecter ce rendez-vous. Faute de quoi, l’article sera réexpédié au Vendeur. 
          Dans ce cas, Lebesty se réserve le droit de bannir l’Acheteur du site.
         </p>

         <h3 class="header2">3.6 Paiement du Vendeur</h3>
         <p>Le Vendeur autorise Lebesty à percevoir, en son nom et pour son compte, le Prix de la Transaction par le biais du système sécurisé de paiement électronique mis en place sur le Site ou par le biais du paiement à la livraison.
          Le Vendeur autorise Lebesty, à retenir sur le Prix total de la Transaction une Commission déterminée ci-après.
          La Commission facturée au Vendeur par Lebesty est de 3 DT pour les articles de moins de 15 DT . Au delà de 15DT, Lebesty perçoit une commission de 20%  du prix de l’article .</p>
        
        <p>Les frais de port ne sont pas inclus dans la base de calcul de la Commission.</p>
        <p>Une fois, la transaction finalisée, Lebesty versera au Vendeur le prix de l'Article diminué de la Commission, 
          et ce, dès confirmation de la réception de l’Article par l’Acheteur sur le Site, sauf en cas de 
          retour de l’Article par l’Acheteur dans les conditions et délais prévus. </p>
          <p>Pour recevoir son argent , le Vendeur doit fournir des justificatifs de son identité et de ses coordonnées 
            bancaires accompagnés de leur photos 
            pour faire les vérifications nécessaires:</p>

            <p>- la CIN ( carte d’identité nationale )</p>
            <p>- le RIB ( relevé d’identité bancaire à son nom ) / la carte e-dinar</p>

         <p> Lebesty peut suspendre le virement dans l’attente de la réception de ces documents et de leur étude.</p>

         <h3 class="header2">3.7 Retour suite à un échec de livraison</h3>
         <p>En cas d’échec de livraison suite à l’injoignabilité de l’Acheteur, le Vendeur doit se montrer disponible 
          pour réceptionner son article retourné. Le livreur effectue les tentatives nécessaires pour retourner l’article.
           A défaut, l’article sera envoyé aux locaux de Lebesty. Le Vendeur dispose dès lors d’un délai de 21 jours pour réclamer son article. Dans le cas échéant,
           le Vendeur payera les frais de retour à son adresse. Passé ce délai, l’article devient la propriété de lebesty.</p>

           <h3 class="header2">3.8  Signaler un article ou un dressing</h3>
<p>Si vous remarquez un contenu désagréable ou ne respectant pas les règles imposées par Lebesty 
  sur le site, vous avez la possibilité de le signaler en cliquant sur le bouton ‘ signaler ‘.
 </p>
 
 <h3 class="header">Article 4. Garantie </h3>
<p>Lebesty offre cette protection à chaque fois qu'une transaction est réalisée sur le Site, 
  au cas où l'Acheteur constate un défaut ou une non-conformité de l'Article acheté avec la description sur le Site.</p>

  <h3 class="header2">4.1 Le retour et le remboursement
  </h3>
  <p>
    Quand un achat est effectué sur Lebesty, l'argent n'est versé à la Vendeur qu'après 24h 
    de la réception de l'Article par l'Acheteur. l'Acheteur dispose de 24h après la livraison, 
    pour signaler à Lebesty tout problème avec sa commande. Elle peut effectuer la réclamation par 
    mail et en envoyant des photos expliquant la nature du problème. L'équipe de lebesty vérifie la
     réclamation, et en cas de validation du retour, enverra un livreur pour récupérer l’article et 
     assurer le retour vers le Vendeur.Une fois la réclamation validée, l'Acheteur doit être disponible
      immédiatement quand le livreur le contactera pour venir récupérer l’article en question. Faute de quoi,
       l'article ne sera pas réexpédié au Vendeur et l’Acheteur devient propriétaire de l’article et accepte
        que Lebesty ne lui rembourse pas le prix de l’Article. Si l’Acheteur est disponible et expédie l'article dans
     les temps, Lebesty s'engage à rembourser l’Acheteur dès que le Vendeur reçoit son article.

  </p>
  <p>

    Les frais de retour des articles non conformes, sont à la charge du Vendeur, il s’agit du montant des frais
     de livraison habituel soit 7 dt. Si la Vendeur refuse le retour, l’article sera inséré 
     sur le dressing “Déstockage Lebesty” à moitié prix, le prix de la vente (50% du prix initial) 
     sera versé au Vendeur si vendu,
     les 7 dt correspondant aux frais de retour de l’article seront également prélevés.
  </p>

  <h3 class="header2">4.2 Ce qui est couvert par la protection
  </h3>
  <p>Si l'un des éléments suivants se rapporte à la commande, la demande du retour sera acceptée par Lebesty :</p>

  <p>- Dommages non divulgués</p>
  <p>- Article incorrect ou manquant</p>

<p>Si aucune réclamation n'est faite dans les 24h suivants la livraison, le paiement du Vendeur 
  sera automatiquement effectué. Une fois le virement effectué,
   toutes les ventes sont finalisées et aucun remboursement ne sera fait.</p>
   <p>Le remboursement se fera par virement bancaire.</p>
   <p>L‘Acheteur doit fournir des justificatifs de son identité :</p>
   <p>- la CIN ( carte d’identité nationale )</p>
   <p>- le RIB ( relevé d’identité bancaire ) ou le RIP (relevé d’identité postale)</p>
   <p>- la carte e-dinar</p>

   <p>Le Vendeur doit se montrer disponible pour réceptionner son article retourné. 
    Le livreur effectue les tentatives nécessaires pour retourner l’article. A défaut, 
    l’article sera envoyé aux locaux de Lebesty. Le Vendeur dispose dès lors d’un délai de 21 jours pour réclamer son article. 
    Passé ce délai, l’article devient la propriété de Lebesty.</p>
   <h3 class="header2">4.3 Ce qui n'est pas couvert :
    par la protection
  </h3>
  <p>

    Les parfums, les articles de beauté, la lingerie, les maillots de bain, chaussettes et collants
  </p>
  <p>- La lingerie, les maillots de bain, chaussettes et collants sauf s’ils sont neufs avec tickets</p>
  <p>- les articles interdits: téléphones, tablettes, bijoux et objets de valeur</p>


  
<p>Si l'article n'est tout simplement pas au gout de l'Acheteur ou ne lui convient pas, Lebesty ne peut malheureusement pas accepter un retour.</p>
<h3 class="header">Les opérations et les transactions hors ligne </h3>
<p>Lebesty protection ne couvre que les opérations effectuées sur la plate-forme . 
  Lorsque le paiement de la valeur totale des articles n'est pas échangé via la plate-forme,
  lebesty ne peut pas garantir que les articles soient envoyés ou conformes.
   l'Acheteur assumera tous les risques associés à ces transactions.</p>

   <h3 class="header">Article 5. Responsabilités</h3>
<p>Lebesty ne peut être tenu pour responsable ni du contenu des Annonces, ni des actions
   (ou absence d’action) des Utilisateurs, ni des Articles mis en vente.</p>

   
   <p>lebesty ne peut être tenu responsable du caractère diffamatoire, injurieux ou contraire aux bonnes
     mœurs des commentaires mis en ligne par un Utilisateur.</p>
     <p>
      Lebesty n’intervient pas dans la transaction entre Acheteurs et Vendeurs. En conséquence,
      lebesty n’exerce aucun contrôle sur la qualité des Articles répertoriés, 
      l’exactitude du contenu des annonces des utilisateurs.</p>

      <p>lebesty ne transfère pas la propriété légale des objets du Vendeur à l’Acheteur. Les accords de
         vente/achat sont conclus directement entre l’Acheteur et le Vendeur.</p>
         <p>
         Lebesty se réserve le droit de suspendre, sans préavis ni indemnité et sans engager sa responsabilité, 
          l’accès au Site, temporairement ou définitivement. Il ne garantit pas que le Site sera accessible sans interruption. Il pourra interrompre 
          l’accès au Site pour des raisons notamment de maintenance et en cas d’urgence.
         </p>
         <p>lebesty n'est pas responsable de l’utilisation frauduleuse par un tiers et des conséquences éventuelles
           de l’identifiant et/ou du mot de passe de l’Utilisateur.</p>
           <p>Des liens hypertextes sur le Site peuvent renvoyer à d’autres sites.
             lebesty n’est pas responsable du contenu ou des agissements de ces sites.</p>

             <p>Le contrat créé lors de la validation de l’Offre d’Achat de l’Acheteur par le Vendeur lie 
              uniquement le Vendeur et l’Acheteur. lebesty jouant seulement un rôle d’intermédiaire et ne 
              saurait être tenue responsable des litiges déclarés en dehors du délai de 24 
              heures suivant la réception de l’Article par l’Acheteur</p>
              <p>Lorsqu’un litige survient entre les parties, lebesty pourra, pendant un délai de 20 jours, 
                faire son possible pour aider les parties à trouver un accord.</p>

                <h3 class="header">Article 6. Dispositions diverses</h3> 
                <p>Dans l’hypothèse où l’une ou plusieurs des stipulations des présentes Conditions Générales serai(en)t écartée(s)
                   par une disposition législative ou réglementaire ou par une décision de justice,
                   toutes les autres dispositions demeureraient applicables</p> 
                   <p>
                    En s’inscrivant sur le Site, l’Utilisateur reconnaît avoir pris connaissance, compris et 
                    accepté sans réserve l’intégralité des présentes Conditions Générales qui 
                    régissent avec force obligatoire l’ensemble des relations entre Lebesty, les Vendeurs et les Acheteurs.
                     Cette acceptation prend effet à compter de la date d’inscription sur le Site de 
                     l’Utilisateur et vaut pour chaque opération, Annonce, Offre d’Achat et Vente.
                      Les présentes Conditions Générales prévalent sur tout document 
                    contractuel ou non contractuel propre au Vendeur ou propre à l’Acheteur.
                   </p>
                   <p>
                    lebesty a la possibilité de modifier les présentes Conditions Générales en fonction de l’évolution 
                    de son offre et du marché. L’Utilisateur s’engage donc à consulter régulièrement les Conditions Générales 
                    du Site pour prendre connaissance des modifications y ayant été apportées.
                     Tout refus d’acceptation des Conditions modifiées devra être notifié en s’adressant au Service Client en 
                     envoyant un courriel 

                   </p>
                   <p>
                    
Le présent contrat est conclu entre Lebesty et l’Utilisateur pour une durée indéterminée. 
Il est résiliable par simple notification à tout moment et sans motif particulier, 
par l’une ou l’autre des parties en présence
                   </p>
                   <p>
                    Le Site, son contenu, son catalogue, ses textes illustrations, ses photographies 
                    et images sont la propriété de Lebesty . Il est interdit de reproduire, représenter 
                    et/ou exploiter tout ou partie du Site, de son contenu, de son catalogue, de ses textes illustrations,
                     de ses photographies et images sans autorisation préalable de lebesty
                   </p>
                   


