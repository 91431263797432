<div class="titre"><span>Achat sécurisé</span></div>

<div class="row">
   
    <div class="col-lg-8">
        <div class="card text-center">
            <div class="card-block">
                <form [formGroup]="userForm" (ngSubmit)='addChef()'>
                <div class="row">

                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]= 'user.prenom'  type="text"  formControlName="prenom"  placeholder='Prenom'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.nom'  type="text"  formControlName="nom" placeholder='Entrer votre nom'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.num'  formControlName="num" type="number" placeholder='Entrer votre numero telephone'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.email' formControlName="email"
                                type="text"
                                placeholder='Enter email'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.adresse' formControlName="adresse"
                              type="text"
                                placeholder='Enter adresse'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.adress2' formControlName="adresse2"
                            type="text"
                                placeholder='en face/prés du /a coté du ...'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.codepostale' formControlName="codepostale"
                                type="text"
                                placeholder='Code Postale'>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" [(ngModel)]='user.ville' formControlName="ville"
                             type="text"
                                placeholder='ville'>
                        </div>
                        <div class="form-group mt-3">
                            <button type="submit"   [disabled]="!userForm.valid" class="button button-contactForm btn_4">suivant</button>
                        </div>
                    </div>

                   
                </div>

            </form>
          
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card text-center">
            <div class="card-block">
                <img class="card-img-top" src="{{product.image_1}}" alt="">

            </div>

            <div class="card-block">

                <div class="row">

                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">Taille</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.taille}}</span>

                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">prix</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.prix}} DT</span>

                    </div>
                </div>
                <div class="row">
                   
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">taille</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.taille}}</span>

                    </div>
                </div>

                <div class="row">
                   
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">marque</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.marque}}</span>

                    </div>
                </div>


               

           <!---   <div class="row">

                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom1">Total à payer:</span>

                    </div>
                    <div class="col-lg-6 col-md-6 mb-4">
                        <span class="nom">{{product.prix+7}}</span>

                    </div>

                </div>-->
                <!--   <button type="button"  class="btn btn-success">suivant</button>-->

            </div>

       
        
      <!-- <div>
               
            <div><input type="radio" name="choice" value="choice-1" id="choice-1" checked>
              <label for="choice-1">Payment a la livraison</label></div>
            <div><input type="radio" name="choice" value="choice-2" id="choice-2">
              <label for="choice-2">Carte-edinar
              </label></div>
            <div><input type="radio" name="choice" value="choice-3" id="choice-3">
              <label for="choice-3">Carte bancaire</label></div>
          </div>
      --> 
    </div>
    </div>



</div>