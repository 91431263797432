<div class="momo">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <div class="tl-toolbar">


    <div>
      <a routerLink="/">
        <img class="notif" src="https://lebesty.com:3000/images/home.png">

      </a>
    </div>

    <div title="Mes favoris">
      <a routerLink="/rechmobile">
        <img class="notif" src="https://lebesty.com:3000/images/recherche.png">

      </a>
    </div>

  

    <div>
      <a routerLink="/addpro">
        <img class="notif" src="https://lebesty.com:3000/images/photo.png">
      </a>
    </div>
    <!-- <div title="Menu">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <img class="notif" src="https://lebesty.com:3000/images/menu.png">

      </button>
    </div> -->
  </div>
  <footer class="py-5 bg-dark">
    <div class="container">
      <p class="m-0 text-center text-white">Copyright &copy; Your Website 2020</p>
    </div>

  </footer>
</div>