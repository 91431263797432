import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SventeService {

ventes:any;
  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) { }



  add(ventes:any) {
    return this.httpClient.post(`${this.URL}/addventes`, ventes);
  }
  getusercommande(email:any) {
    return this.httpClient.get<{vente:any}>(`${this.URL}/vente/${email}`);
  }
  getusercommandeavis(email:any) {
    return this.httpClient.get<{vente:any}>(`${this.URL}/venteavis/${email}`);
  }

  
  commandeanuler(commande:any) {
    return this.httpClient.put(`${this.URL}/commandeannul`,commande);
  }

    
  commandeaccept(commande:any) {
    return this.httpClient.put(`${this.URL}/commandeaccepter`,commande);
  }

  modifiercommandeenvoyer(commande:any) {
    return this.httpClient.put(`${this.URL}/commandeenvoyer`,commande);
  }

  modifiercommandelivrer(commande:any) {
    return this.httpClient.put(`${this.URL}/commandedelivrer`,commande);
  }

  modifiercommandepayer(commande:any) {
    return this.httpClient.put(`${this.URL}/commandepayer`,commande);
  }
  commandeenvoyer() {
    return this.httpClient.get<{commande:any}>(`${this.URL}/ckkkk/cmdlll/jj/commandeenvoyer`);
  }

  commandelivrer() {
    return this.httpClient.get<{commande:any}>(`${this.URL}/ckkkk/cmdlll/jj/commandelivrer`);
  }
  
  commandepayment() {
    return this.httpClient.get<{commande:any}>(`${this.URL}/ckkkk/cmdlll/jj/commandepayment`);
  }
  getusercommandeachat(email:any) {
    return this.httpClient.get<{vente:any}>(`${this.URL}/achat/${email}`);
  }
  getcommandebyid(id:any)
{
  return this.httpClient.get<{commande:any}>(`${this.URL}/commande/${id}`);

}
getcommandes()
{
  return this.httpClient.get<{commande:any}>(`${this.URL}/ckkkk/cmdlll/jj/`);

}
getcommandesannuler()
{
  return this.httpClient.get<{commande:any}>(`${this.URL}/ckkkk/cmdlll/jj/annuler`);

}

getcommandesaccepter()
{
  return this.httpClient.get<{commande:any}>(`${this.URL}/ckkkk/cmdlll/jj/accepter`);

}
updateavis(avis:any) {
  return this.httpClient.put(`${this.URL}/commandeavis`,avis);
}
getprixlivraison()
{
  return this.httpClient.get<{prix:any}>(`${this.URL}/prixlivraison`);

}
}


