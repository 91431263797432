import { Component, OnInit } from '@angular/core';
import {SusersService} from'../../providers/susers.service';
import {ProduitService} from '../../providers/produit.service';
import {SventeService} from '../../providers/svente.service';
import {StorageCrypter} from 'storage-crypter';
import *  as moment from 'moment';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css']
})
export class CommandesComponent implements OnInit {
  product: any;
  isLoading = true;
  recherche: string = "achats";
  Coeur: any;
  achat: any;
  addlike: any = {};
  email: any;
  user: any;
  abonner: any;
  starRating = 0;
  nbreproduct: any;
  produit: any;
  like: any;
  page = 0;
  Data1: Array<any> = [];
  constructor(  private svente: SventeService, private suser:SusersService,private Produitsservice:ProduitService) { }

  ngOnInit(): void {
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
    console.log("email:"+storageCrypter.getItem('num', 'local'))
    this.email=storageCrypter.getItem('num', 'local');
    this.svente.getusercommande(this.email).subscribe(


      data => {

        this.Coeur = data.vente


      }
    )

    this.suser.getuserabonner(this.email).subscribe(

      data => {
        this.abonner = data;
        console.log('abonner:' + this.abonner)
      }
    )

    this.suser.getnbreproduct(this.email).subscribe(

      data => {
        this.nbreproduct = data;
        console.log('nbreproducts:' + this.nbreproduct)
      }
    )

    this.svente.getusercommandeachat(this.email).subscribe(


      data => {

        this.achat = data.vente


      }
    )
    console.log("emailcoeur:" + this.Coeur)

    this.suser.getuserbyemail(this.email).subscribe(


      data => {

        this.user = data.user
        console.log('abonner:' + this.user.url_userimage)

      }
    )
  }

  momenttimenow(time)
  {
    moment.locale('FR')
    return moment(time).fromNow();
  }
  
  avis(id: any)
  {
  
    console.log('id produit' + id);
    // this.navCtrl.push(AvisPage, {
    //   id: id,
    //   //  this.navCtrl.setRoot(InscriPage);
    // })
  
  }
  
  
  showmodal(id)
  {
  
    // const modal = this.modalCtrl.create(AvisPage, { "id": id }, { cssClass: 'test-modal' });
    // modal.present();
  
  }
  
  
  
  getproduit(id : any) {
    console.log('id commande' + id);
    // this.navCtrl.setRoot(DetproduitPage, {
    //   id: id,
      //  this.navCtrl.setRoot(InscriPage);
    //})
    //  this.navCtrl.setRoot(DetproduitPage,{
    //  id : id,
    //    test:1
    //  }) 
  }
}
