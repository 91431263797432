import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SscategorieService {

  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) { }


   
  add(Scategory:any) {
    return this.httpClient.post(`${this.URL}/addScategory`, Scategory);
  }


  getAllScategoryService() {
    return this.httpClient.get<{Scategorys:any}>(`${this.URL}/Scategorys`);
  }


  getBycategoryService(category:any) {
    return this.httpClient.get<{Scategory:any}>(`${this.URL}/Scategorys/${category}`);
  }
  





}
