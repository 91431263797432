import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { Router } from '@angular/router';
import {StorageCrypter} from 'storage-crypter';
import { SusersService} from '../../providers/susers.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
  users: any={};
  users2: any={};
  user22:any;
  email:any=null;
  name:any=null
  photo:any=null
  i:any=0;
  constructor(private authService: SocialAuthService,
     private router:Router,private Suserservice :SusersService
    
    
    ) { }

  signInWithGoogle(): void {

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    
    
    this.authService.authState.subscribe((user) => {
      this.user = user;
      if (user != null)
      {
       
        this.loggedIn = (user != null);
console.log('connected:'+this.loggedIn);
this.user = user;
const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');

storageCrypter.setItem('photoUrl', this.user.photoUrl, 'local');
storageCrypter.setItem('email', this.user.email, 'local');
storageCrypter.setItem('name', this.user.name, 'local');
storageCrypter.setItem('provider', this.user.provider, 'local');
storageCrypter.setItem('first_name', this.user.firstName, 'local');
storageCrypter.setItem('LastName', this.user.lastName, 'local');

if (this.i===0)
{
this.existeuser(user)
this.router.navigate([`/home`]);
      }
      }
    });
  
  
   
  }

  signInWithFB(): void {

    const fbLoginOptions = {
      scope: 'email',
      return_scopes: true,
      enable_profile_selector: true
    };
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions);
    this.authService.authState.subscribe((user) => {
      this.user = user;
      if (user != null)
      {
        this.loggedIn = (user != null);
      
console.log('connected:'+this.loggedIn);
this.users = user;
const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');

storageCrypter.setItem('photoUrl', this.user.photoUrl, 'local');
storageCrypter.setItem('email', this.user.email, 'local');
storageCrypter.setItem('name', this.user.name, 'local');
storageCrypter.setItem('provider', this.user.provider, 'local');
storageCrypter.setItem('first_name', this.user.firstName, 'local');
storageCrypter.setItem('LastName', this.user.lastName, 'local');
this.existeuser(user)


  
this.router.navigate([`/home`]);

      }
//this.users2=storageCrypter.getItem('emaill', 'local');
//console.log("email:"+this.users2);
//console.log("names::"+storageCrypter.getItem('emaill', 'local'));

    });
  
    
  }

  signOut(): void {
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
    this.authService.signOut();
this.user22=null;
this.photo=null;
this.email=null;
  storageCrypter.removeItem('photoUrl', 'local');
  storageCrypter.removeItem('email', 'local');
  storageCrypter.removeItem('name', 'local');
  storageCrypter.removeItem('provider', 'local');
  storageCrypter.removeItem('first_name', 'local');
  storageCrypter.removeItem('LastName', 'local');
  storageCrypter.removeItem('iduser', 'local');
  }


ajoutuser(user:any)
{
  
  const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  console.log('usser add');
this.Suserservice.add(user) .subscribe(
  () => {
    this.i++;

    this.Suserservice.getuserbyemail(user.email).subscribe(
      (data) => {
      

          console.log("null5555555");
        this.user = data.user;
        storageCrypter.setItem('photoUrl', this.user.photoUrl, 'local');
storageCrypter.setItem('email', this.user.email, 'local');
storageCrypter.setItem('name', this.user.name, 'local');
storageCrypter.setItem('provider', this.user.provider, 'local');
storageCrypter.setItem('first_name', this.user.firstName, 'local');
storageCrypter.setItem('LastName', this.user.lastName, 'local');
        storageCrypter.setItem('iduser', data.user._id, 'local');
        console.log("iduser:"+data.user._id);
     

 
       // console.log(this.user);
      }
    );
  }
)
}



existeuser(user:any)
{

  const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');

  console.log("entrer");


    this.user = user;
  


    console.log("emailchercher:"+user.email);
    this.Suserservice.getuserbyemail(user.email).subscribe(
      (data) => {
       

        if (data.user!==null)
        {
          console.log("null5555555");
        this.user = data.user;
        storageCrypter.setItem('iduser', data.user._id, 'local');
   
        console.log("iduser:"+data.user._id);
        }

        else
        {
          console.log("add user11");

this.i=1;
this.ajoutuser(this.user);
this.user = user;

        }
       // console.log(this.user);
      }
    ); 

  
  

}
  
  ngOnInit() {

    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
console.log("email:"+storageCrypter.getItem('email', 'local'));
console.log(storageCrypter.getItem('name', 'local'));
console.log(storageCrypter.getItem('provider', 'local'));
this.email=storageCrypter.getItem('email', 'local');
console.log("email22"+this.email);
const name=storageCrypter.getItem('name', 'local')
const photoUrl=storageCrypter.getItem('photoUrl', 'local')
console.log("photo"+photoUrl);
if(this.email!==null)
{
this.user22=storageCrypter.getItem('email', 'local');
this.name=name;
this.photo=photoUrl;
} 
  }
  refreshToken(): void {
    //this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    this.authService.refreshAuthToken(FacebookLoginProvider.PROVIDER_ID );
  
  }


 
}
