<div *ngIf="users" class="card text-center">

  
    <div class="row">

 
 <div class="col" >
    <a class="nav-link" routerLink="/profil">     <span class="nom33">Mon profil</span>  </a>
  
   
  
 </div>

</div>
<!-- <hr>
<div class="row">

 
    <div class="col-lg-8 col-md-6 mb-4" >
       <a class="nav-link" routerLink="/taille">     <img class="notif"  src="../../../assets/images/notif.png"> <span class="nom33">Mes Tailles</span>  </a>
      
     
    </div>
    <div class="col-lg-2 col-md-6 mb-4" >
       <a class="nav-link" routerLink="#">     <img class="notif"  src="../../../assets/images/notif.png"> </a>
    
     
    </div>
   </div> -->
   
<hr>


<div class="row">

 
    <div class="col" >
       <a class="nav-link" routerLink="/address">    <span class="nom33">Mon Adresse</span> </a>
       
     
    </div>

   </div>
</div>