import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListeprodsComponent } from './components/listeprods/listeprods.component';
import { BaniereComponent } from './components/baniere/baniere.component';
import { HeaderComponent } from './components/header/header.component';
import { CategorieComponent } from './components/categorie/categorie.component';
import { AjoutprodComponent } from './components/ajoutprod/ajoutprod.component';
import { HomeComponent } from './components/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SocialLoginModule,  SocialAuthServiceConfig  } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import { MatTableModule } from '@angular/material/table';
import { InfouserComponent } from './infouser/infouser.component';
import { CoupcoueurComponent } from './components/coupcoueur/coupcoueur.component';
import { ReseausocialComponent } from './components/reseausocial/reseausocial.component';
import { AppstoreComponent } from './components/appstore/appstore.component';
import { MentionlegalComponent } from './components/mentionlegal/mentionlegal.component';
import { ProduitComponent } from './components/produit/produit.component';
import { BannierProuduitComponent } from './components/bannier-prouduit/bannier-prouduit.component';
import { DetailleProduitComponent } from './components/detaille-produit/detaille-produit.component';
import { AriclesimilComponent } from './components/ariclesimil/ariclesimil.component';
import { InfousrarticleComponent } from './components/infousrarticle/infousrarticle.component';
import { CommentaireComponent } from './components/commentaire/commentaire.component';
import { AffichagecommentaireComponent } from './components/affichagecommentaire/affichagecommentaire.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfouserProduitComponent } from './infouser-produit/infouser-produit.component';
import { InfouserPComponent } from './components/infouser-p/infouser-p.component';
import { ListproinfoComponent } from './components/listproinfo/listproinfo.component';
import { SusersComponent } from './providers/susers/susers.component';
import { AchatComponent } from './components/achat/achat.component';
import { Achat2Component } from './components/achat2/achat2.component';
import { Achat3Component } from './components/achat3/achat3.component';
import { RechercheComponent } from './components/recherche/recherche.component';
import { Recherche2Component } from './components/recherche2/recherche2.component';
import { PvendeurComponent } from './components/pvendeur/pvendeur.component';
import { InfovendeurComponent } from './components/infovendeur/infovendeur.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import{MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ArticlevenduComponent } from './components/articlevendu/articlevendu.component';
import { ArticlenonvenduComponent } from './components/articlenonvendu/articlenonvendu.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { OrderComponent } from './components/order/order.component';
import { MatDialogModule } from '@angular/material/dialog';
import { io } from "socket.io-client";
import { DabonnerComponent } from './components/dabonner/dabonner.component';
import { AbonnertailleComponent } from './components/abonnertaille/abonnertaille.component';
import { AbonnerAdressComponent } from './components/abonner-adress/abonner-adress.component';
import { AbonnerinfoComponent } from './components/abonnerinfo/abonnerinfo.component';
import { IscriptionComponent } from './components/iscription/iscription.component';
import { VefnumComponent } from './components/vefnum/vefnum.component';
import { PseudoimageComponent } from './components/pseudoimage/pseudoimage.component';
import { CommandesComponent } from './components/commandes/commandes.component';
import { NotifComponent } from './components/notif/notif.component';
import { MyModalComponent } from './components/my-modal/my-modal.component';
import { TableaubordComponent } from './components/tableaubord/tableaubord.component';
import { CommandeconfirmerComponent } from './components/commandeconfirmer/commandeconfirmer.component';
import { CommandeenvoyerComponent } from './components/commandeenvoyer/commandeenvoyer.component';
import { CommandelivrerComponent } from './components/commandelivrer/commandelivrer.component';
import { CommandefinaliserComponent } from './components/commandefinaliser/commandefinaliser.component';
import { CommandeannulerComponent } from './components/commandeannuler/commandeannuler.component';
import { ReclamationencourComponent } from './components/reclamationencour/reclamationencour.component';
import { ReclamationconfirmerComponent } from './components/reclamationconfirmer/reclamationconfirmer.component';
import { SignalencourComponent } from './components/signalencour/signalencour.component';
import { SignaletraiterComponent } from './components/signaletraiter/signaletraiter.component';
import { ContactencourComponent } from './components/contactencour/contactencour.component';
import { ContacteconfirmerComponent } from './components/contacteconfirmer/contacteconfirmer.component';
import { ListeproduitprofilComponent } from './components/listeproduitprofil/listeproduitprofil.component';
import { ListeproduitprofilvenduComponent } from './components/listeproduitprofilvendu/listeproduitprofilvendu.component';
import { ListeavisComponent } from './components/listeavis/listeavis.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AssistanceComponent } from './components/assistance/assistance.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecherchemobileComponent } from './components/recherchemobile/recherchemobile.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    ListeprodsComponent,
    BaniereComponent,
    HeaderComponent,

    CategorieComponent,

    AjoutprodComponent,

    HomeComponent,

    LoginComponent,

    DashboardComponent,

    InfouserComponent,

    CoupcoueurComponent,

    ReseausocialComponent,

    AppstoreComponent,

    MentionlegalComponent,

    ProduitComponent,

    BannierProuduitComponent,

    DetailleProduitComponent,

    AriclesimilComponent,

    InfousrarticleComponent,

    CommentaireComponent,

    AffichagecommentaireComponent,

    InfouserProduitComponent,

    InfouserPComponent,

    ListproinfoComponent,

    SusersComponent,

    AchatComponent,

    Achat2Component,

    Achat3Component,

    RechercheComponent,

    Recherche2Component,

    PvendeurComponent,

    InfovendeurComponent,

    ArticlevenduComponent,

    ArticlenonvenduComponent,

    OrderComponent,

    DabonnerComponent,

    AbonnertailleComponent,

    AbonnerAdressComponent,

    AbonnerinfoComponent,

    IscriptionComponent,

    VefnumComponent,

    PseudoimageComponent,

    CommandesComponent,

    NotifComponent,

    MyModalComponent,

    TableaubordComponent,

    CommandeconfirmerComponent,

    CommandeenvoyerComponent,

    CommandelivrerComponent,

    CommandefinaliserComponent,

    CommandeannulerComponent,

    ReclamationencourComponent,

    ReclamationconfirmerComponent,

    SignalencourComponent,

    SignaletraiterComponent,

    ContactencourComponent,

    ContacteconfirmerComponent,

    ListeproduitprofilComponent,

    ListeproduitprofilvenduComponent,

    ListeavisComponent,

    PrivacyComponent,

    AssistanceComponent,

    RecherchemobileComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MatTabsModule ,
    MatCardModule,
    NgbModule,
 //   NgbModule,
 MatDialogModule,
    MatTableModule ,
    MatProgressSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule ,
    HttpClientModule,
 SocialLoginModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    NgbModule
  
  ],
  providers: [  

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '73951058673-g6uub42gbs4t8lvfaec0oq6mj6v88rtf.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2926375540932624')
          }
        ]
      } as SocialAuthServiceConfig,
    }
    
  ],  
  bootstrap: [AppComponent]
})
export class AppModule { }
