

<div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="20"
  (scrolled)="onScroll()" [scrollWindow]="true">
  <div class="row ">
    <div class="col-lg-3 col-md-6 mb-3" *ngFor="let product of Data index as i">

      <div class="card h-100">

    
        <div class="vendu"> Vendu </div>

  

        <a routerLink='/produit/{{product._id}}'><img class="card-img-top" src="{{product.image_1}}" alt=""></a>
        <div class="card-body">

          <p class="card-text2">{{product.Countfavoirie}}</p>
          <div *ngIf="email">
          <button type="button" *ngIf="!isFav(product._id,i)" class="notif"
            (click)='coeur(product._id,product.Countfavoirie,i)'> <img class="notif"
              src="../../../assets/images/coeur2.png"></button>
          <button type="button" *ngIf="isFav(product._id,i)" class="notif"
            (click)='coeurnon(product._id,product.Countfavoirie,i)'> <img class="notif"
              src="../../../assets/images/coeur3.png"></button>
            </div>
            <div *ngIf="!email">
              <button type="button" class="notif"
              > <img class="notif"
                  src="../../../assets/images/coeur2.png"></button>
             
                </div>
          <h5 class="card-title">{{product.prix}}DT</h5>



          <p class="card-text">{{product.taille}}</p>
          <h5 class="card-marque">{{product.marque}}</h5>
         
        </div>

      

      </div>
      
    </div>
  
  </div>
  <!-- /.row -->

</div>
