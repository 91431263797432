
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SconditionService {

  conditiontURL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) 
  
  { }

  add(condition:any) {
    return this.httpClient.post(`${this. conditiontURL}/addcond`, condition);
  }


  getAlletatService() {
    return this.httpClient.get<{etats:any}>(`${this.conditiontURL}/etats`);
  }
}
