import { Component, OnInit } from '@angular/core';
import {CommentaireService} from '../../providers/commentaire.service';

import { ActivatedRoute, Router } from '@angular/router';

import { io } from "socket.io-client";

import *  as moment from 'moment';

@Component({
  selector: 'app-affichagecommentaire',
  templateUrl: './affichagecommentaire.component.html',
  styleUrls: ['./affichagecommentaire.component.css']
})
export class AffichagecommentaireComponent implements OnInit {
  id:any;
  commentaires:any;
  taille=0;

  socket = io("https://lebesty.com:3000/",{
    
    rejectUnauthorized : false 
  });
  constructor( private activatedRoute:ActivatedRoute,
    private Commentaireservice :CommentaireService) {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');

 
    }

   ngOnInit(): void {

    this.ALLposts();

    this.socket.on('refrechpage'+this.id,(data) => {

    this.ALLposts();
   })


  }

  ALLposts()
  {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('id:'+this.id);
    this.Commentaireservice.getcommentaireById(this.id).subscribe(

     data => {
        this.commentaires = data.commentaire;
        this.taille = this.commentaires.length;
        console.log('commentaire:'+this.commentaires)
      }
    )

  }
  
  momenttimenow(time)
  {
    moment.locale('FR')
return moment(time).fromNow();
  }
}


