<div  class="card text-center">

    <!-- <div class="card-block"><span class="niveauuser">Note :</span> <span class="niveau">Débutant</span></div> -->
 <br>
    <div class="card-block">
       
        <!-- <a *ngIf="user.url_userimage" routerLink='/profileV/{{user._id}}'> <img *ngIf="user.url_userimage" class="card-img-top1 img-responsive photo" src="{{ user.url_userimage }}"></a> -->
    
        <a class="nav-link" routerLink='/profileV/{{user._id}}' *ngIf="user.url_userimage" >     <img  *ngIf="user.url_userimage"class="card-img-top1 img-responsive photo"  src="{{user.url_userimage}}"> </a>
        <a class="nav-link"routerLink='/profileV/{{user._id}}' *ngIf="((user.genre==1)&&(!user.url_userimage))">     <img  *ngIf="!user.url_userimage" class="card-img-top1 img-responsive photo"  src="https://lebesty.com:3000/images/homme.png"> </a>
        <a class="nav-link" routerLink='/profileV/{{user._id}}' *ngIf="((user.genre==2)&&(!user.url_userimage))">     <img  *ngIf="!user.url_userimage" class="card-img-top1 img-responsive photo"  src="https://lebesty.com:3000/images/femme.png"> </a>
    
        <h4 class="card-title">{{ user.nom }}</h4>
   <p class="card-text">{{ user.bio }}</p>
      
       <button type="button"    (click)='addabooner()'   class="btn btn-success">s'abonner</button>

    </div>
    <hr>
    <div class="row">

 <div class="col-lg-4 col-md-6 mb-4" >
    <span class="nom">Articles</span> 
     <br> <span class="numero">{{article }}</span>
 </div>

 <div class="col-lg-4 col-md-6 mb-4" >
    <span class="nom">Abonnés</span> 
    <br><span class="numero">{{abonner }}</span>
</div>
<div class="col-lg-4 col-md-6 mb-4" >
    <span class="nom">Suivies</span> 
    <br><span class="numero">{{suivie }}</span>
</div>
    </div>
</div>