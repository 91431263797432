
<div class="container1">

    <div class="row">



      <!-- /.col-lg-3 -->

      <div class="col-lg-9">

        <app-bannier-prouduit></app-bannier-prouduit>

       <div class="contenairecomme">
        <app-commentaire></app-commentaire>
     <app-affichagecommentaire></app-affichagecommentaire>
      </div>
     
      </div>

      <div class="col-lg-3">
      
        
          <!-- la section droite de la page vous cree beaucoup des categorie -->
        
        
        
     <div class="detaille" >
        <app-detaille-produit></app-detaille-produit>
       

     </div>
     <app-infouser-p></app-infouser-p>
              </div>
        
      <!-- /.col-lg-9 -->

    </div>
    <!-- /.row -->
    <div class="row">

        <hr>
     
        <app-ariclesimil></app-ariclesimil>


    </div>

  </div>
  <!-- /.container -->

  <!-- Footer -->
 

