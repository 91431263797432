import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Router } from '@angular/router';
import { io } from "socket.io-client";
import {StorageCrypter} from 'storage-crypter';
import {SmarqueService} from'../../providers/smarque.service'; 
import { MatDialog } from '@angular/material/dialog';
import {SusersService} from'../../providers/susers.service';
import { MyModalComponent } from '../my-modal/my-modal.component';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  socket = io("https://lebesty.com:3000/",{
    
  rejectUnauthorized : false 
});
  user: any;
  loggedIn: boolean;
  users: any={};
nom: any;
showModal: boolean = false;
countconseil=0;
countredconseil=0
countdifferance=0;
countdifferance2=0
notif:any;
userForm: FormGroup;
  constructor( private authService: SocialAuthService,  private dialog: MatDialog,  
    private router:Router,    private notifservice:SmarqueService,
    private suser:SusersService,private fb: FormBuilder) {

  
   }
   openModal() {
    this.dialog.open(MyModalComponent);
  }
   ngOnInit(): void {

   

const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  
this.users.email=storageCrypter.getItem('num', 'local');
this.users.num=storageCrypter.getItem('email', 'local');
console.log("num"+this.users.num);
this.users.photoUrl=storageCrypter.getItem('photoUrl', 'local');
this.users.name=storageCrypter.getItem('name', 'local');
   console.log("user:"+this.users.email);
    this.userForm = this.fb.group({
    
      recherche: [''],
    })
    

//photoUrl
if (this.users.email)
{

 this.suser.getuserbyemail( this.users.email).subscribe(


  data => {

    this.user = data.user
   console.log("User:" + this.user.url_userimage)
 

  }
)
this.socket.on('refrechpage'+this.users.email,()=>{
  this.ALLnotif();
 })   
 this.notifservice.get_Notif_id2(this.users.email).subscribe(


  data=>
  {

this.notif=data
console.log("notifsddd:"+this.notif);
this.countdifferance=this.notif;
//this.countdifferance=10;

  }
)  



}



  }


  ALLnotif()
{
  this.notifservice.get_Notif_id2(this.users.emaild).subscribe(


    data=>
    {

this.notif=data
console.log("notifs:"+this.notif);
this.countdifferance=this.notif;
    }
  )  
 

}
  homme() 
  {
    this.router.navigate(['recherche'], { queryParams: { gender:"Homme"} });
  }
  femme() 
  {
    this.router.navigate(['recherche'], { queryParams: { gender:"Femme"} });
  }

  fille() 
  {
    this.router.navigate(['recherche'], { queryParams: { gender:"Fille"} });
  }

  garcon() 
  {
    this.router.navigate(['recherche'], { queryParams: { gender:"Garcon"} });
  }
  waitForOneSecond() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve("I promise to return after one second!");
      }, 1000);
    });
  }
 connect()
  {
 

   this.authService.authState.subscribe(user=> {
if (user!== null)
{
  //console.log("dddd2:"+JSON.stringify(user));
 this.users=JSON.stringify(user);
  console.log("dddd2:"+JSON.stringify(user));
  this.loggedIn = true;
  // this.router.navigate([`login`]);
  console.log('connected2:'+this.loggedIn);
}

else

{
  this.router.navigate([`inscri`]);

}
//console.log('connected3:'+this.loggedIn);});
    
  });}

  /* signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    
    console.log("user:"+this.user);
  
  
   
  }

  signInWithFB(): void {

    const fbLoginOptions = {
      scope: 'email',
      return_scopes: true,
      enable_profile_selector: true
    };
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions);
  }

  signOut(): void {
    this.authService.signOut();
  }*/


  goToachat() {
    this.router.navigate([`addpro`])
  }



  submit() 
  {

    this.router.navigate(['recherche'], { queryParams: { search: this.nom } });
  //this.router.navigate(['recherche#?nom='+ this.nom]);
  }
}
