import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CommentaireService {
  URL = 'https://lebesty.com:3000' ;

  constructor(private httpClient:HttpClient ) 
   { }


   add(commentaire:any) {
  
    return this.httpClient.post(`${this.URL}/addcommentaire`, commentaire);
  }

  getcommentaireById(id:any) {
    
    return this.httpClient.get<{commentaire:any}>(`${this.URL}/commentaire/${id}`);
  }

  




}
