import { Component, OnInit } from '@angular/core';

import { SocialUser } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";
import { Router } from '@angular/router';
import {StorageCrypter} from 'storage-crypter';
import {SusersService} from '../../providers/susers.service';

@Component({
  selector: 'app-abonnerinfo',
  templateUrl: './abonnerinfo.component.html',
  styleUrls: ['./abonnerinfo.component.css']
})
export class AbonnerinfoComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
users:any={};
abonner:any;
article:any;

suivie:any;
constructor( private authService: SocialAuthService,   private router:Router
  , private suser:SusersService
  
  ) {


 }


 ngOnInit() {
 
  const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');

  this.users.email=storageCrypter.getItem('num', 'local');
  this.users.photoUrl=storageCrypter.getItem('photoUrl', 'local');
  this.users.name=storageCrypter.getItem('name', 'local');
  this.suser.getuserabonner(this.users.email).subscribe(

    data => {
       this.abonner = data;
       console.log('abonner:'+this.abonner)
     }
   )

this.suser.getarticleabonner(this.users.email).subscribe(

  data => {
     this.article = data;
     console.log('articles:'+this.article)
   }
 )

 this.suser.getsuivieabonner(this.users.email).subscribe(

  data => {
     this.suivie = data;
     console.log('suivie:'+this.suivie)
   }
 )
}

}
