import { Component, OnInit } from '@angular/core';
import {SusersService} from'../../providers/susers.service';
import {ProduitService} from '../../providers/produit.service';
import {SventeService} from '../../providers/svente.service';
import {StorageCrypter} from 'storage-crypter';
import *  as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-pvendeur',
  templateUrl: './pvendeur.component.html',
  styleUrls: ['./pvendeur.component.css']
})
export class PvendeurComponent implements OnInit {
  product: any;
  isLoading = true;
  recherche: string = "achats";
  Coeur: any;
  achat: any;
  addlike: any = {};
  gfg = 5; 
  tailleavis:any;
  email: any;
  tailleproduitvendu:any;
  tailleproduitnonvendu:any;
  user: any;
  moyenne:any;
  abonner: any;
  Data:Array<any>=[];
  starRating = 0;
  nbreproduct: any;
  produit: any;
  like: any;
  page = 0;
  Data1: Array<any> = [];
  constructor(  private route: ActivatedRoute, private svente: SventeService, private suser:SusersService,private Produitsservice:ProduitService) { }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
    this.svente.getusercommandeavis(this.email).subscribe(


      data => {

        this.avis = data.vente
      this.tailleavis=this.avis.length 
        if (this.avis && this.avis.length > 0) {
          let sommeNotes = 0;
          this.tailleavis=this.avis.length 
          // this.avis.forEach(avisItem => {
          //   // Utilisez la propriété 'etoile' de chaque avisItem
          //   sommeNotes += avisItem.etoile;
          // });
        
          // Calculer la moyenne en divisant par le nombre total d'avis
          this.moyenne = sommeNotes / this.avis.length;
        
          console.log('Moyenne des avis :', this.moyenne);
        } else {
          this.moyenne = 0;
          this.tailleavis=this.avis.length 
          console.log('Aucun avis disponible.');
         
        }
      }
    )

    this.Produitsservice.compterProduitsVendus(this.email).subscribe(
      data=>
      {
       
this.tailleproduitvendu=data
console.log('taille produit vendu:'+this.tailleproduitvendu)

      }
    )  
    
    this.Produitsservice.compterProduitsnonVendus(this.email).subscribe(
      data=>
      {
       
this.tailleproduitnonvendu=data
console.log('taille produit non vendu:'+this.tailleproduitnonvendu)

      }
    )  
 





    console.log("emailcoeur:" + this.Coeur)

    this.suser.getuserbyemail(this.email).subscribe(


      data => {

        this.user = data.user
        console.log('abonner:' + this.user.url_userimage)

      }
    )
  }

  momenttimenow(time)
  {
    moment.locale('FR')
    return moment(time).fromNow();
  }
  
  avis(id: any)
  {
  
    console.log('id produit' + id);
    // this.navCtrl.push(AvisPage, {
    //   id: id,
    //   //  this.navCtrl.setRoot(InscriPage);
    // })
  
  }
  
  
  showmodal(id)
  {
  
    // const modal = this.modalCtrl.create(AvisPage, { "id": id }, { cssClass: 'test-modal' });
    // modal.present();
  
  }
  
  
  
  getproduit(id : any) {
    console.log('id commande' + id);
    // this.navCtrl.setRoot(DetproduitPage, {
    //   id: id,
      //  this.navCtrl.setRoot(InscriPage);
    //})
    //  this.navCtrl.setRoot(DetproduitPage,{
    //  id : id,
    //    test:1
    //  }) 
  }

  isFav(id: any, item: any)
  {
        var reponse: boolean=false;

        for(var i = 0; i< this.Coeur.length; i++) {

      // console.log("likeghhhh:"+this.Coeur[i]["idproduit"] );
      if (this.Coeur[i]["idproduit"] == id) {
        reponse = true;
      }

    }
    return reponse;
  }



  coeur(id: any, like: any, item: any) {
    // Initialize this.Coeur as an empty array if it's not already an array
    if (!Array.isArray(this.Coeur)) {
        this.Coeur = [];
    }

    if (like != null) {
        this.addlike.like = like + 1;
    } else {
        this.addlike.like = 1;
    }
    this.addlike.id = id;
    this.addlike.idproduit = id;
    this.addlike.email = this.email;

    this.Data[item]["Countfavoirie"] = this.addlike.like;

    this.Coeur.push(this.addlike);

    console.log("length2:" + this.Coeur.length);
    this.Produitsservice.addcoeur(this.addlike).subscribe(
        (data) => {
            // handle response if needed
        }
    );

    // Reset this.addlike to an empty object for the next iteration
    this.addlike = {};
}

  coeurnon(id: any, like: any, item: any) {


    this.addlike.like = like - 1;
    if (like != 0) {
      console.log("like" + like);

      this.addlike.like = like - 1;
    }
    else {

      this.addlike.like = 0;
    }
    this.addlike.id = id;
    this.addlike.idproduit = id;
    this.addlike.email = this.email;

    console.log("lentgh1:" + this.Coeur.length);
    for (var i = 0; i < this.Coeur.length; i++) {

      //console.log("likeghhhh:"+this.Coeur[i]["idproduit"] );
      if (this.Coeur[i]["idproduit"] == id) {
        this.Coeur.splice(i, 1);
      }
    }
    this.Data[item]["Countfavoirie"] = this.addlike.like;
    console.log("lentgh2:" + this.Coeur.length);
    this.Produitsservice.deletecoeur(this.addlike).subscribe(
      (data) => {
    

        this.Produitsservice.deletecoeur2(this.addlike).subscribe(
          (data) => {
          

          }
        )


      }
    )

  }
}
