import { Component, OnInit } from '@angular/core';
import {ProduitService} from '../../providers/produit.service';
import { SocialAuthService } from "angularx-social-login";

import {StorageCrypter} from 'storage-crypter';

@Component({
  selector: 'app-listeprods',
  templateUrl: './listeprods.component.html',
  styleUrls: ['./listeprods.component.css']
})
export class ListeprodsComponent implements OnInit {
  productss:any;
  Coeur:any={};
  addlike:any={};
like:any;  
email:any;
page=0;
isLoading=true;
Data:Array<any>=[];
  constructor( private Produitsservice:ProduitService,private authService: SocialAuthService) { }

  ngOnInit(): void {
   
    this.getProduct();
   /* this.Produitsservice.getAllProduitService().subscribe(
      (data) => {
        this.productss = data.produits;
        console.log('produit:'+this.productss);
      }
     
    );*/
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
    console.log("email:"+storageCrypter.getItem('num', 'local'));
    console.log(storageCrypter.getItem('name', 'local'));
    console.log(storageCrypter.getItem('provider', 'local'));
    this.email=storageCrypter.getItem('num', 'local');
    if (this.email)
    {
  console.log("emailcoeur:"+this.email)
    this.Produitsservice.getAlllike(this.email).subscribe(


      data=>
      {

this.Coeur=data.coeur
console.log("likes:"+this.Coeur.length);
console.log("likes:"+this.Coeur);
      }
    )  
  
    }

  }

  isFav(id: any, item: any)
  {
        var reponse: boolean=false;

        for(var i = 0; i< this.Coeur.length; i++) {

      // console.log("likeghhhh:"+this.Coeur[i]["idproduit"] );
      if (this.Coeur[i]["idproduit"] == id) {
        reponse = true;
      }

    }
    return reponse;
  }



  coeur(id: any, like: any, item: any) {
    // Initialize this.Coeur as an empty array if it's not already an array
    if (!Array.isArray(this.Coeur)) {
        this.Coeur = [];
    }

    if (like != null) {
        this.addlike.like = like + 1;
    } else {
        this.addlike.like = 1;
    }
    this.addlike.id = id;
    this.addlike.idproduit = id;
    this.addlike.email = this.email;

    this.Data[item]["Countfavoirie"] = this.addlike.like;

    this.Coeur.push(this.addlike);

    console.log("length2:" + this.Coeur.length);
    this.Produitsservice.addcoeur(this.addlike).subscribe(
        (data) => {
            // handle response if needed
        }
    );

    // Reset this.addlike to an empty object for the next iteration
    this.addlike = {};
}

  coeurnon(id: any, like: any, item: any) {


    this.addlike.like = like - 1;
    if (like != 0) {
      console.log("like" + like);

      this.addlike.like = like - 1;
    }
    else {

      this.addlike.like = 0;
    }
    this.addlike.id = id;
    this.addlike.idproduit = id;
    this.addlike.email = this.email;

    console.log("lentgh1:" + this.Coeur.length);
    for (var i = 0; i < this.Coeur.length; i++) {

      //console.log("likeghhhh:"+this.Coeur[i]["idproduit"] );
      if (this.Coeur[i]["idproduit"] == id) {
        this.Coeur.splice(i, 1);
      }
    }
    this.Data[item]["Countfavoirie"] = this.addlike.like;
    console.log("lentgh2:" + this.Coeur.length);
    this.Produitsservice.deletecoeur(this.addlike).subscribe(
      (data) => {
    

        this.Produitsservice.deletecoeur2(this.addlike).subscribe(
          (data) => {
          

          }
        )


      }
    )

  }
getProduct():void
{
  this.isLoading=true;

this.Produitsservice.getoroduct({page:this.page}).subscribe((res:any)=>{
res.produits.forEach((e:any) => {
  this.Data.push(e);
});

/*this.Data=res.produits;
console.log("tt:"+res.produits);
*/
this.isLoading=false;
} );

}
onScroll()
{
  console.log("scrolle down");
  this.page+=1;

  this.getProduct();
}

}
