

<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" routerLink='/home'>Lebesty</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <img class="notif" src="https://lebesty.com:3000/images/menu.png">
      
          
   
      </button>
      <!-- <a class="nav-link" routerLink='/profil' *ngIf="user.url_userimage" >     <img  *ngIf="user.url_userimage" class="avatar" src="{{user.url_userimage}}"> </a>
      <a class="nav-link" routerLink='/profil' *ngIf="((user.genre==1)&&(!user.url_userimage))">     <img  *ngIf="!user.url_userimage" class="avatar" src="https://lebesty.com:3000/images/homme.png"> </a>
      <a class="nav-link" routerLink='/profil' *ngIf="((user.genre==2)&&(!user.url_userimage))">     <img  *ngIf="!user.url_userimage" class="avatar" src="https://lebesty.com:3000/images/femme.png"> </a>
      -->
      <div class="collapse navbar-collapse" id="navbarResponsive">
    
        <form class="form-inline my-2 my-lg-0" #userForm='ngForm' (ngSubmit)="submit()">
          <input class="form-control mr-sm-2" type="search"  [(ngModel)]= 'nom'  [ngModelOptions]="{standalone: true}"   placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit" [disabled]="!userForm.valid" >Search</button>
        </form>
        <ul class="navbar-nav ml-auto">
     
          <li class="nav-item"  *ngIf="user">
            <a class="nav-link" mat-button  (click)="openModal()"> <span class="badge blink" *ngIf="countdifferance > 0">{{ countdifferance }}</span>
             <img class="avatarnotif" src="https://lebesty.com:3000/images/notif.png">  </a>
            
          </li>
        
          <li class="nav-item"  *ngIf="user">
            <a class="nav-link" routerLink="/commandes">       <img class="avatarpanier" src="https://lebesty.com:3000/images/panier2.png">  </a>
           
          </li>
       
          <li class="nav-item" *ngIf="user">
            <a class="nav-link" routerLink='/profil' *ngIf="user.url_userimage" >     <img  *ngIf="user.url_userimage" class="avatar" src="{{user.url_userimage}}"> </a>
            <a class="nav-link" routerLink='/profil' *ngIf="((user.genre==1)&&(!user.url_userimage))">     <img  *ngIf="!user.url_userimage" class="avatar" src="https://lebesty.com:3000/images/homme.png"> </a>
            <a class="nav-link" routerLink='/profil' *ngIf="((user.genre==2)&&(!user.url_userimage))">     <img  *ngIf="!user.url_userimage" class="avatar" src="https://lebesty.com:3000/images/femme.png"> </a>
          </li>
          <li class="nav-item" *ngIf="!user" >
            <button type="button"   routerLink="/inscri"  class="btn btn-success Inscription">Connexion</button>
          </li>
          <li class="nav-item" *ngIf="user">
            <button type="button"   (click)='goToachat()'  class="btn btn-success Inscription">Vendre</button>
          </li>
          <li class="nav-item" *ngIf="!user">
            <button type="button"  routerLink="/inscri"  class="btn btn-success Inscription">Vendre</button>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" routerLink="#">     <img class="notif"  src="../../../assets/images/point.png"> </a>
            
          </li> -->
        </ul>
      </div>
      
    </div>
    
  </nav>
  
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top nav2" data-target="#navbarResponsive" >
  <div class="container">
    
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link"  (click)='femme()'>Femmes <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   (click)='homme()'>Hommes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)='fille()' >Filles</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" (click)='garcon()' >Garcons</a>
        </li>
      </ul>
    
    </div>
    
  </div>
</nav>