<h2>Articles similaires</h2>    
<div class="row">
  
    <div class="col-lg-3 col-md-6 mb-4" *ngFor="let product of productss" >
      <div class="card h-100">
        
        
          
      <img class="avatar" src="{{product.url_userimage}}"> 
              
      
          <span class="user-name"> {{product.nom_user}}</span>
        
        <a routerLink='/produit/{{product._id}}'><img class="card-img-top" src="{{product.image_1}}" alt=""></a>
        <div class="card-body">
         <!--<h4 class="card-title">
            <a href="#">{{product.titre}}</a>
          </h4>--> 
          <h5 class="card-title">{{product.prix}}DT</h5>
          <p class="card-text">{{product.taille}}</p>
          <h5 class="card-marque">{{product.marque}}</h5>
        </div>
        
      </div>
    </div>

   