<div class="section">
    <h2>Comment ça marche ?</h2>
   <p>Tu as besoin d'une paire de baskets confortables pour tes séances de sport,<br> ou peut-être un sac à dos spacieux pour tes prochaines aventures en plein air ?<br> Tu peux explorer notre large sélection de produits en utilisant la fonction de recherche avancée sur le site pour trouver exactement ce que tu recherches.</p>
  </div>

<div class="section">
    <h2>Comment acheter ?</h2>
    <ol>
      <li>Créez votre liste coup de cœur et contactez les vendeurs pour plus d'informations</li>
      <li>En ajoutant un article à vos favoris, vous pouvez le retrouver facilement dans votre liste coup de cœur.</li>
      <li>En commentant un article, vous pouvez négocier le prix, demander une photo portée ou toute autre information.</li>
      <li>Connexion + Je vends un article</li>
    </ol>
</div>

<div class="section">
    <h2>Vendu ! Préparez votre envoi.</h2>
    <p>Génial ! Votre acheteur vient de passer commande en ligne !<br>
    Emballez soigneusement votre article et préparez votre colis.<br>
    Vous pouvez le remettre à notre livreur qui viendra le récupérer à votre domicile !<br>
    Votre article partira vers de nouvelles aventures, et vous recevrez votre paiement dès que l'acheteur aura confirmé la réception ;)</p>
</div>

 <div class="section">
    <h2>Achète</h2>
    <ol>
        <li>Clique sur Acheter et sélectionne le mode de paiement à la livraison.</li>
        <li>Indique ton adresse complète.</li>
        <li>Attends la livraison de ton colis à domicile.</li>
        <li>Une fois le colis reçu, confirme sa réception.<br>
            Si l'article ne correspond pas à l'annonce, tu as 24 heures pour demander un remboursement.</li>
    </ol>
</div>


  
<div class="section">
    <h2>Prêt à vendre ?</h2>
    <ol>
        <li>Publie ton article en vente + Passe en revue les articles que tu souhaites vendre : Prends de belles photos, rédige une description détaillée, et valide.</li>
    </ol>
</div>
<div class="section">
    <h2>Prêt à vendre ?</h2>
    <ol>
        <li>Publiez vos articles à vendre + Passez en revue vos articles à vendre : Prenez de belles photos, rédigez une description détaillée, et validez.</li>
    </ol>
</div>

<div class="section">
  <h2>Contactez-nous</h2>
  <p>Le moyen le plus simple pour poser toutes vos questions: 7/7 24h/24</p>
  <ul>
   
    <li><strong>Téléphone:</strong> (+216) 70 100 177<br>
    Vous pouvez nous contacter par téléphone du Lundi au vendredi de 9h à 18h</li>
    <li><strong>E-mail:</strong> <a href="mailto:support@lebesty.com">support@lebesty.com</a><br>
    Vous pouvez nous envoyer vos e-mails à tout moment.</li>
  </ul>
</div>

