<div class="card text-center">

  <div class="card-block"></div>
  <h5 class="topvendeur">Top Vendeurs: <span class="voir">voir Tous</span></h5>
  <div class="card-block">

    <div class="row">
      <div class="col-lg-6 col-md-6 mb-4" *ngFor="let user of users">
        <span class="nomdresing">{{ user.nom }}</span>
        <br>
        <img class="avatar" [src]="user.url_userimage" alt="User Image">
        <br>
        <span class="totalvente">3 Vente</span>
        <br>
        <button class="button button1">s'abonner</button>
      </div>
    </div>
    
  </div>