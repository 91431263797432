import { Component, OnInit } from '@angular/core';

import {ProduitService} from '../../providers/produit.service';
import { ScategorieService} from '../../providers/scategorie.service';
import { SconditionService} from '../../providers/scondition.service';
import {ScouleurService} from '../../providers/scouleur.service';
import {SgenreService} from '../../providers/sgenre.service';
import {SmarqueService} from '../../providers/smarque.service';
import {SscategorieService} from '../../providers/sscategorie.service';
import {StailleService} from '../../providers/staille.service';
import {StorageCrypter} from 'storage-crypter';
import {NgSelectModule, NgOption, NgSelectConfig} from '@ng-select/ng-select';
import { SocialAuthService } from "angularx-social-login";
import { FormsModule , Validators} from '@angular/forms';

import { FormGroup, FormBuilder ,FormControl} from '@angular/forms';

import { Router } from '@angular/router';
@Component({
  selector: 'app-ajoutprod',
  templateUrl: './ajoutprod.component.html',
  styleUrls: ['./ajoutprod.component.css']
})
export class AjoutprodComponent implements OnInit {
  produit: any = {};
  prodForm: FormGroup;
//produit:any;
user: any={};

  loggedIn: boolean;

  imagePreview: any;
  imagePreview2: any;
  imagePreview3: any;
  imagePreview4: any;
  imagePreview5: any;
  images = [];
  multipleImages = [];

  titre:any;
  prix:any;
  Genressr:any;
  description:any;
  categories: any;
  categorie: any;
  Scategorie: any;
  Couleurs: any;
  Marques:any;
category:any;
Scategory:any;
  brands: any;
  couleur:any;
  genre:any;
  Genres:any;
  Genress :any=[] ;
file:any;
file2:any;
file3:any;
file4:any;
file5:any;
  etat:any;
  Tailles:any;
  taille:any;
  marque:any;
  conditions: any;
  selectedCategory: any;
  selectedBrand: any;
  selectedCondition: any;
  selectedUser2:any;
  submitAttempt: boolean = false;
Etats:any;
  constructor(
    private fb: FormBuilder,
private produitService:ProduitService,private GenreService: SgenreService,

private Etatservice:SconditionService ,private Scouleurs :ScouleurService,
private SmarqueService:SmarqueService,
private ScategorieService:ScategorieService,
private SouscategorieService:SscategorieService,
private TailleService:StailleService,
private authService: SocialAuthService,
private router:Router,
private config: NgSelectConfig


  ) { 

    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same 
    // bindValue in most of the place. 
    // You can also override bindValue for the specified template 
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    this.config.bindValue = 'value';

  }

  ngOnInit(){
    
    this.prodForm = this.fb.group({
      titre: new FormControl('', [
        Validators.required,
        this.sqlInjectionValidator(),
      ]),
      file:  new FormControl('', [Validators.required, ]),
      description: new FormControl('', [Validators.required, this.sqlInjectionValidator() ]),
      prix:['', Validators.compose([ Validators.pattern('[0-9 ]*'),Validators.required],)],
      genre: new FormControl('', [Validators.required, ]),
      categorie: new FormControl('', [Validators.required, ]),
      Scategorie: new FormControl('', [Validators.required, ]),
      marque: new FormControl('', [Validators.required, ]),
      couleur: new FormControl('', [Validators.required, ]),
      etat: new FormControl('', [Validators.required, ]),
      taille: new FormControl('', [Validators.required, ]),
      
    })
    
    
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
  
   
    this.user.name=storageCrypter.getItem('num', 'local');
    console.log("name:::"+this.user.name)
    this.user.email=storageCrypter.getItem('num', 'local');
    if (!this.user.email)
      {
        this.router.navigate([`inscri`]); 
      }
    
    console.log("email::"+this.user.email)
    this.user.photoUrl=storageCrypter.getItem('photoUrl', 'local');
    this.user.id=storageCrypter.getItem('iduser', 'local');
    console.log("iduser::"+this.user.id)
    console.log("iduser::"+this.user.id)
    console.log("iduser::"+this.user.id)
    console.log("photo::"+this.user.photoUrl)
    if(1==1)
    {
    this.GenreService.getAllgenreService().subscribe(
      (data) => {
        this.Genres = data.genres;
        this.Genress=data.genres;
      //  console.log(data.genres);
        console.log("genresdddddddddddd"+this.Genres);

      
       

      }
    );
   
    this.Etatservice.getAlletatService().subscribe(
      (data) => {
        this.Etats = data.etats;
      //  console.log(data.genres);
        console.log(this.Etats);
      }
    );

    this.Scouleurs.getAllcouleurService().subscribe(
      (data) => {
        this.Couleurs = data.couleurs;
      //  console.log(data.genres);
        console.log(this.Couleurs);
      }
    );

    this.SmarqueService.getAllMarqueService().subscribe(
      (data) => {
        this.Marques = data.marques;
      //  console.log(data.genres);
        console.log(this.Marques);
      }
    );

    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);

    });
  }
  else
  {
  //  this.router.navigate([`/login`]); 
  }
  }
  sqlInjectionValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (/[;<>"]/g.test(value)) { // Vérifie les caractères spécifiques souvent utilisés dans les injections SQL
        return { sqlInjection: true };
      }
      return null;
    };
  }
  addPlat() {}

  onImageSelected(event: Event) {
    this.file = (event.target as HTMLInputElement).files[0];
    console.log('File', this.file);
    
   
    this.prodForm.patchValue({ img: this.file });
    this.prodForm.updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string
    };
    reader.readAsDataURL(this.file);
  }
  onImageSelected2(event: Event) {
     this.file2 = (event.target as HTMLInputElement).files[0];
    
     
    
    console.log('File', this.file2);
    
    this.prodForm.patchValue({ img: this.file2 });
    this.prodForm.updateValueAndValidity();
    const reader2 = new FileReader();
    reader2.onload = () => {
      this.imagePreview2 = reader2.result as string
    };
    reader2.readAsDataURL(this.file2);
    }



    onImageSelected3(event: Event) {
      this.file3 = (event.target as HTMLInputElement).files[0];
     
      
     
     console.log('File', this.file3);
     
     this.prodForm.patchValue({ img: this.file3 });
     this.prodForm.updateValueAndValidity();
     const reader3 = new FileReader();
     reader3.onload = () => {
       this.imagePreview3 = reader3.result as string
     };
     reader3.readAsDataURL(this.file3);
     }
 

     onImageSelected4(event: Event) {
      this.file4 = (event.target as HTMLInputElement).files[0];
     
      
     
     console.log('File', this.file3);
     
     this.prodForm.patchValue({ img: this.file4 });
     this.prodForm.updateValueAndValidity();
     const reader4 = new FileReader();
     reader4.onload = () => {
       this.imagePreview4 = reader4.result as string
     };
     reader4.readAsDataURL(this.file4);
     }


     onImageSelected5(event: Event) {
      this.file5 = (event.target as HTMLInputElement).files[0];
     
      
     
     console.log('File', this.file3);
     
     this.prodForm.patchValue({ img: this.file5 });
     this.prodForm.updateValueAndValidity();
     const reader5 = new FileReader();
     reader5.onload = () => {
       this.imagePreview5 = reader5.result as string
     };
     reader5.readAsDataURL(this.file5);
     }

  changeLeagueOwner($event)
  {
    console.log($event);

  }

  selectMultipleImage(event){
    if (event.target.files.length > 0) {
      this.multipleImages = event.target.files;
    }
  }




  

  savearticle()
{
  this.submitAttempt = true;
  if(this.prodForm.valid){
  this.multipleImages.push (this.file);
  this.multipleImages.push (this.file2);
  this.multipleImages.push (this.file3);
  this.multipleImages.push (this.file4);
  this.multipleImages.push (this.file5);
  
 
  this.produit.email_user=this.user.email;
  this.produit.nomuser=this.user.name;
this.produit.urlimageuser= this.user.photoUrl;
this.produit.id_user=this.user.id;

this.produit.scategorie=this.produit.scategorie;
this.produit.description=this.produit.description;
this.produit.taille=this.produit.taille;
this.produit.marque=this.produit.marque;
this.produit.titre=this.produit.titre;
this.produit.prix=this.produit.prix;
this.produit.categorie=this.produit.categorie;
this.produit.couleur=this.produit.couleur;
this.produit.etat=this.produit.etat;

/*this.produit.etat=this.etat;
this.produit.couleur=this.couleur;
console.log('category:'+this.categorie);
console.log('marque:'+this.marque);
console.log('condition:'+this.etat);
console.log('titre article:'+this.titre);
console.log('description article:'+this.description);
console.log('prix article:'+this.prix);
console.log('This is my produit', this.produit); */
console.log("produit:"+this.produit);
  this.produitService.addprodService(this.produit, this.multipleImages).subscribe(
    () => {
      console.log('produitadded successfully');
    }
  )
  }
  this.router.navigate([`/home`]);
}




onOptionsSelected(value:string){
  console.log("the selected value is " + value);

  this.category=null;
  this.categorie=null;
  this.Tailles=null;
  this.taille=null;
  
  this.ScategorieService.getBygenreService(value).subscribe(
      (data) => {
        this.category = data.category;
  
        console.log(this.category);
      }
    );
  
  
    this.TailleService.getBytaillegenre(value).subscribe(
      (data) => {
        this.Tailles = data.taille;
  
        console.log(this.Tailles);
      }
    );
   
}

onOptionsSelected2(value:string){
  console.log("the selected value is2 " + value);

  this.Scategory=null;
  this.Scategorie=null;

  this.SouscategorieService.getBycategoryService(value).subscribe(
    (data) => {
      this.Scategory = data.Scategory;

      console.log(this.Scategory);
    }
  );
}


toggleDisabled() {
  const genre: any = this.Genres[1];
genre.disabled = !genre.disabled;
}
onOptionsSelected3(value:string){
  console.log("the selected value is3 " + value);

}
  savearticle2()
{

 
  console.log('produ')
  this.produit.categorie="homme";
this.produit.id_marque="marque";
this.produit.description="description";
this.produit.taille="taille";
this.produit.marque="marque";
this.produit.titre="titre";
this.produit.prix=15;
/*this.produit.etat=this.etat;
this.produit.couleur=this.couleur;
console.log('category:'+this.categorie);
console.log('marque:'+this.marque);
console.log('condition:'+this.etat);
console.log('titre article:'+this.titre);
console.log('description article:'+this.description);
console.log('prix article:'+this.prix);
console.log('This is my produit', this.produit); */
  this.produitService.addprodService(this.produit, this.prodForm.value.img).subscribe(
    () => {
      console.log('produitadded successfully');
    }
  )
}

}
