import { Component, OnInit } from '@angular/core';
import { FormsModule , Validators} from '@angular/forms';
import {SusersService} from'../../providers/susers.service';
import { ActivatedRoute, Router } from '@angular/router';
import {StorageCrypter} from 'storage-crypter';
import { FormGroup, FormBuilder ,FormControl} from '@angular/forms';
@Component({
  selector: 'app-vefnum',
  templateUrl: './vefnum.component.html',
  styleUrls: ['./vefnum.component.css']
})
export class VefnumComponent implements OnInit {
  public slideOneForm: FormGroup;
  submitAttempt: boolean = false;
  agent: any = {};
  agent2: any = {};
  nom: String;
  id: any;
  id2: any;
  id3: any;
  id4: any;
  load: any;
  id5: any;
  resultat: any;
  constructor(  private activatedRoute:ActivatedRoute,
    
    private router:Router, 
    private suser:SusersService,
    private fb: FormBuilder,
 
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    // this.id = this.activatedRoute.snapshot.paramMap.get('id');
    // this.agent.firstName = this.activatedRoute.snapshot.paramMap.get('name');
    // this.agent.numero =this.activatedRoute.snapshot.paramMap.get('numero');
    // this.agent.sexe = this.activatedRoute.snapshot.paramMap.get('sexe');
    // this.agent.naissance = this.activatedRoute.snapshot.paramMap.get('naissance');
    // this.agent.lastName = this.activatedRoute.snapshot.paramMap.get('prenom');

    this.route.queryParams
    .subscribe(params => {
      //console.log(params); // { orderby: "price" }
      if((params.numero!=null)&&(params.numero!=""))
      this.agent.numero  = params.numero;
      console.log("id+++"+this.agent.numero );
      if((params.sexe!=null)&&(params.sexe!=""))
      this.agent.gender=params.sexe;
     
      if((params.name!=null)&&(params.name!=null))
      this.agent.firstName =params.name;
     
     


     



    }
    );


    console.log("id+++"+this.agent.numero );


console.log("agent:" , this.agent);
    this.slideOneForm = this.fb.group({



      id: ['', Validators.compose([Validators.maxLength(1), Validators.minLength(1), Validators.pattern('[0-9 ]*'), Validators.required])],
      id2: ['', Validators.compose([Validators.maxLength(1), Validators.minLength(1), Validators.pattern('[0-9 ]*'), Validators.required])],
      id3: ['', Validators.compose([Validators.maxLength(1), Validators.minLength(1), Validators.pattern('[0-9 ]*'), Validators.required])],
      id4: ['', Validators.compose([Validators.maxLength(1), Validators.minLength(1), Validators.pattern('[0-9 ]*'), Validators.required])],
      id5: ['', Validators.compose([Validators.maxLength(1), Validators.minLength(1), Validators.pattern('[0-9 ]*'), Validators.required])],

    });

  }
  tabChange(){
    console.log('teste')
    if (this.id !== null && !isNaN(this.id)) {
      document.getElementById('sec').focus();
    }
   
   }
  tabChange2(){
    console.log('teste')
   
    if (this.id2 !== null && !isNaN(this.id2)) {
      document.getElementById('trois').focus();
    }
   }
   tabChange3(){
    console.log('teste')
    if (this.id3 !== null && !isNaN(this.id3)) {
      document.getElementById('quatre').focus();
    }
   }
   tabChange4(){
    console.log('teste')
    if (this.id4 !== null && !isNaN(this.id4)) {
      document.getElementById('cinq').focus();
    }
   }
   tabChange5(){
    console.log('teste')
    if (this.id5 !== null && !isNaN(this.id5)) {
      document.getElementById('six').focus();
    }
   }

   ajoutagent() {
    this.submitAttempt = true;
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
    if (this.slideOneForm.valid) {
      this.agent.pass = this.id.toString() + this.id2.toString() + this.id3.toString() + this.id4.toString() + this.id5.toString();
      //  console.log('mot pass:'+this.agent.pass)
      console.log('vrif1:' + this.agent.numero)
      this.suser.getverifnumero(this.agent.numero, this.agent.pass).subscribe(

        data => {
          this.resultat = data;
          console.log('vrif2:' + data)
          if (this.resultat > 0) {
            console.log('le code est juste')


            //modifier verfier numero statue 0
            this.suser.updateabonnernumero(this.agent).subscribe(
              data => {
                this.resultat = data;
            
              }
            )

            //verification si l'abonner existe  

            this.suser.getnbre_user_numero(this.agent.numero).subscribe(
              data => {
                this.resultat = data;

                if (this.resultat > 0) {
                  //  si lutlisateur existe le valider a
                  console.log('abonnerexiste :' + data)
this.suser.updateUserServicenumero(this.agent).subscribe(
  data => {
    this.resultat = data.message;
console.log("id prdoduit :"+data.message)
  this.suser.getid(this.agent.numero).subscribe(

    data => {
      this.agent2 = data.user;
      storageCrypter.setItem('num', this.agent2._id, 'local');
   
      console.log('id99:'+this.agent2);
       console.log('id888:'+this.agent2._id);
  this.reloadPage();
     
      
     }
   )

}
)



                }
                else {
                  this.agent.valider=1;
                  console.log('nouveau abonner  :' + data)

                  this.suser.add(this.agent).subscribe(
data => {

  console.log("id"+data.message);
  storageCrypter.setItem('num', data.message, 'local');

                      console.log('user added successfully');

                      this.router.navigate(['image'], { queryParams: { num: data.message } });
                     
                    }
                  )
                
                }
              }
            )


          }
          else {
            this.id=null;
            this.id2=null;
            this.id3=null;
            this.id4=null;
            this.id5=null;
            this.submitAttempt = false;
            console.log('votre  code est erronne')

          }
        }
      )
    }
  }

  reloadPage() {
    setTimeout(()=>{
      window.location.reload();

    }, 100);
    this.router.navigate(['home'], { queryParams: { } });
}
}
