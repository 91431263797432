

<div class="container">

  <div class="row">
  
    <div class="col-lg-12">
      <form [formGroup]="prodForm" (ngSubmit)='savearticle()'>
        <div class="row">
          <!-- <div class="col-12">
            <div class="form-group">
              <label class="sbw_light">Selectionner les images</label>
              <input type="file" class="form-control" multiple (change)="selectMultipleImage($event)" />
            </div>

          </div>-->
          <div class="row">
          <div class="col-3 test">
            <div class="form-group">
              <button type="button" (click)='filePicker.click()' class="btn btn-success">Sélectionner image</button>
              <div class="form-group">
                <input style="visibility: hidden;" formControlName="file" type="file" class="form-control" #filePicker
                  (change)='onImageSelected($event)' />
              </div>
              <img [src]="imagePreview" *ngIf='imagePreview' width="180px" height="180px">

            </div>
            <span class="desc"><strong>Photo1:</strong> Ton article de face, en entier et sur un fond neutre.
            </span>
          </div>
          <div class="col-3">
            <div class="form-group">
              <button type="button" (click)='filePicker2.click()' class="btn btn-success">Sélectionner image</button>
              <div class="form-group">
                <input style="visibility: hidden;" type="file" class="form-control" #filePicker2
                  (change)='onImageSelected2($event)' />
              </div>
              <img [src]="imagePreview2" *ngIf='imagePreview2' width="180px" height="180px">

            </div>
            <span class="desc"><strong>Photo2:</strong> L'arrière de ton article.
            </span>

          </div>
          <div class="col-3">
            <div class="form-group">
              <button type="button" (click)='filePicker3.click()' class="btn btn-success">Sélectionner image</button>
              <div class="form-group">
                <input style="visibility: hidden;" type="file" class="form-control" #filePicker3
                  (change)='onImageSelected3($event)' />
              </div>
              <img [src]="imagePreview3" *ngIf='imagePreview3' width="180px" height="180px">
            </div>
            <span class="desc"><strong>Photo3:</strong>Ton article porté ou sur ceintre..
            </span>
          </div>
          <div class="col-3">
            <div class="form-group">
              <button type="button" (click)='filePicker4.click()' class="btn btn-success">Sélectionner image</button>
              <div class="form-group">
                <input style="visibility: hidden;" type="file" class="form-control" #filePicker4
                  (change)='onImageSelected4($event)' />
              </div>
              <img [src]="imagePreview4" *ngIf='imagePreview4' width="180px" height="180px">
            </div>
            <span class="desc"><strong>Photo4:</strong> L'étiquette.
            </span>
          </div>
          <!-- <div class="col-3">
            <div class="form-group">
              <button type="button" (click)='filePicker5.click()' class="btn btn-success">Sélectionner image</button>
              <div class="form-group">
                <input style="visibility: hidden;" type="file" class="form-control" #filePicker5
                  (change)='onImageSelected5($event)' />
              </div>
              <img [src]="imagePreview5" *ngIf='imagePreview5' width="180px" height="180px">
            </div>
            <span class="desc"><strong>Photo5:</strong> Les éventuels défauts de ton article.
            </span>
          </div> -->
          <div
          *ngIf="prodForm.controls['file'].invalid && (prodForm.controls['file'].dirty || prodForm.controls['file'].touched)"
          class="alert alert-danger">
          <div *ngIf="prodForm.controls['file'].errors.required">
            <p>Veuillez ajouter au moin une photo de votre article !</p>
          </div>
        </div>
      </div>
          <div class="col-12 titre">
            <div class="form-group">
              <input class="form-control" [(ngModel)]='produit.titre' formControlName="titre" type="text"
                placeholder='Enter name'>

                <div
                *ngIf="prodForm.controls['titre'].invalid && (prodForm.controls['titre'].dirty || prodForm.controls['titre'].touched)"
                class="alert alert-danger">
                <div *ngIf="prodForm.controls['titre'].errors.required">
                  Nom Obligatoire.
                </div>
              </div>
              <div  class="alert alert-danger" *ngIf="prodForm.get('titre').errors?.sqlInjection">
                Le nom ne doit pas contenir de caractères spéciaux.
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <input class="form-control" [(ngModel)]='produit.description' formControlName="description" type="text"
                placeholder='Enter description'>


                <div
                *ngIf="prodForm.controls['description'].invalid && (prodForm.controls['description'].dirty || prodForm.controls['description'].touched)"
                class="alert alert-danger">
                <div *ngIf="prodForm.controls['description'].errors.required">
                  description Obligatoire.
                </div>
               <div  class="alert alert-danger" *ngIf="prodForm.get('description').errors?.sqlInjection">
                La description ne doit pas contenir de caractères spéciaux.
              </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <input class="form-control" [(ngModel)]='produit.prix' formControlName="prix" type="text"
                placeholder='Enter price'>
                <div
                *ngIf="prodForm.controls['prix'].invalid && (prodForm.controls['prix'].dirty || prodForm.controls['prix'].touched)"
                class="alert alert-danger">
                <div *ngIf="prodForm.controls['prix'].errors.required">
                  Prix Obligatoire.
                </div>
              </div>

            </div>
          </div>



          <div class="col-12">
            <label class="sbw_light">Genre:</label>
            <select #mySelect (change)='onOptionsSelected(mySelect.value)' [(ngModel)]='produit.genre'
              class="custom-select" formControlName="genre">
              <option [value]="genre.nom" *ngFor="let genre of Genres"> {{genre.nom}}</option>
            </select>
            <div
            *ngIf="prodForm.controls['genre'].invalid && (prodForm.controls['genre'].dirty || prodForm.controls['genre'].touched)"
            class="alert alert-danger">
            <div *ngIf="prodForm.controls['genre'].errors.required">
              champ Obligatoire.
            </div>
          </div>
          </div>

          <!--   <div class="col-1">
            
           
        <label>Genre</label>
              
          
         
        </div>
      
          <div class="col-11">
            
           
        
              
           <ng-select  class="kk" [multiple]="false" [items]="Genress" bindLabel="nom" [(ngModel)]="Genress"   [ngModelOptions]="{standalone: true}" 
                (change)="changeLeagueOwner($event)">
            
              </ng-select>
           
          </div>-->



          <div class="col-12">
            <label class="sbw_light">Categorie:</label>
            <select #mySelect2 (change)='onOptionsSelected2(mySelect2.value)' [(ngModel)]='produit.categorie'
              class="custom-select" formControlName="categorie">

              <option [value]="gat.nom" *ngFor="let gat of category"> {{gat.nom}}</option>
            </select>
            <div
            *ngIf="prodForm.controls['categorie'].invalid && (prodForm.controls['categorie'].dirty || prodForm.controls['categorie'].touched)"
            class="alert alert-danger">
            <div *ngIf="prodForm.controls['categorie'].errors.required">
              champ Obligatoire.
            </div>
          </div>
          </div>
          <div class="col-12">
            <label class="sbw_light">SousCategorie:</label>
            <select #mySelect3 (change)='onOptionsSelected3(mySelect3.value)' [(ngModel)]='produit.scategorie'
              class="custom-select" formControlName="Scategorie">

              <option [value]="Sgat.nom" *ngFor="let Sgat of Scategory"> {{Sgat.nom}}</option>
            </select>
            <div
            *ngIf="prodForm.controls['Scategorie'].invalid && (prodForm.controls['Scategorie'].dirty || prodForm.controls['Scategorie'].touched)"
            class="alert alert-danger">
            <div *ngIf="prodForm.controls['Scategorie'].errors.required">
              champ Obligatoire.
            </div>
          </div>
          </div>

          <div class="col-12">
            <label class="sbw_light">Marques:</label>
            <select [(ngModel)]='produit.marque'formControlName="marque" class="custom-select">

              <option [value]="marque.nom" *ngFor="let marque of Marques"> {{marque.nom}}</option>
            </select>

          </div>


          <div class="col-12">
            <label class="sbw_light">Couleurs:</label>
            <select [(ngModel)]='produit.couleur' formControlName="couleur" class="custom-select">

              <option [value]="coul.nom" *ngFor="let coul of Couleurs"> {{coul.nom}}</option>
            </select>
            <div
            *ngIf="prodForm.controls['couleur'].invalid && (prodForm.controls['couleur'].dirty || prodForm.controls['couleur'].touched)"
            class="alert alert-danger">
            <div *ngIf="prodForm.controls['couleur'].errors.required">
              champ Obligatoire.
            </div>
          </div>
          </div>

          <div class="col-12">
            <label class="sbw_light">Etats:</label>
            <select [(ngModel)]='produit.etat' formControlName="etat" class="custom-select">

              <option [value]="ee.name" *ngFor="let ee of Etats"> {{ee.name}}</option>
            </select>
            <div
            *ngIf="prodForm.controls['etat'].invalid && (prodForm.controls['etat'].dirty || prodForm.controls['etat'].touched)"
            class="alert alert-danger">
            <div *ngIf="prodForm.controls['etat'].errors.required">
              champ Obligatoire.
            </div>
          </div>
          </div>
          <div class="col-12">
            <label class="sbw_light">Taille:</label>
            <select [(ngModel)]='produit.taille' formControlName="taille" class="custom-select">

              <option [value]="T.nom" *ngFor="let T of Tailles"> {{T.nom}}</option>
            </select>
            <div
            *ngIf="prodForm.controls['taille'].invalid && (prodForm.controls['taille'].dirty || prodForm.controls['taille'].touched)"
            class="alert alert-danger">
            <div *ngIf="prodForm.controls['taille'].errors.required">
              champ Obligatoire.
            </div>
          </div>
          </div>





        </div>
        <div class="ajoutproduit ">
          <button type="submit" [disabled]="!prodForm.valid" class="btn btn-success">Ajout Article</button>
        </div>
      </form>
    </div>
  </div>
</div>