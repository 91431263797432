import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
@Injectable({
  providedIn: 'root'
})
export class SusersService {
  user: SocialUser;
  loggedIn: boolean;
users:any;
  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient,private authService: SocialAuthService) { }








async login2()
{
 this.authService.authState.subscribe(user=> {

    //this.loggedIn = (user != null);
  //console.log('connected1:'+this.loggedIn);
  if (user!== null)
  {
  this.user=user;
    this.loggedIn = true;
    // this.router.navigate([`login`]);
    console.log('connected2:'+this.loggedIn);

}
});
return this.user;
}



  updateUserService(user:any) {
    return this.httpClient.put(`${this.URL}/users`,user);
  }

  add(users:any) {
    return this.httpClient.post<{message:any}>(`${this.URL}/addusersocial`, users);
  }
  addsocial(users:any) {
    return this.httpClient.post<{message:any}>(`${this.URL}/addusers`, users);
  }

  addnumerouser(numero:any) {
    return this.httpClient.post(`${this.URL}/addnumero`, numero);
  }

  addavis(avis:any) {
    return this.httpClient.post(`${this.URL}/addavis`, avis);
  }

  
  addvus(vus:any) {
    return this.httpClient.post(`${this.URL}/addvus`, vus);
  }
  addabonner(email:any) {
    return this.httpClient.post(`${this.URL}/addabonner`, email);
  }
  getuserid(email:any) {
    return this.httpClient.get<{taille:any}>(`${this.URL}/tailles`);
  }
  getuserssuivie() {
    return this.httpClient.get<{user:any}>(`${this.URL}/usersuivis`);
  }
  getuserssuivieweb() {
    return this.httpClient.get<{user:any}>(`${this.URL}/usersuivisweb`);
  }
  getuserexiste(id:any) {
    return this.httpClient.get<{user:any,token:any}>(`${this.URL}/userexiste/${id}`);
  
  }

  
  getuserexistemail(email:any) {
    return this.httpClient.get<{user:any}>(`${this.URL}/usermail/${email}`);
  
  }


 getuserbyemail(user:any) {
    return this.httpClient.get<{user:any}>(`${this.URL}/users/${user}`);
  }
  getuserabonner(email:any) {
    return this.httpClient.get<{user:any}>(`${this.URL}/abonner/${email}`);
  }
  getnbre_user_numero(numero:any) {
    return this.httpClient.get<{user:any}>(`${this.URL}/abonnernumero/${numero}`);
  }

  getverifnumero(numero:any,pass:any) {
    return this.httpClient.get<{user:any}>(`${this.URL}/verifnumro/${numero}/${pass}`);
  }
  
  getarticleabonner(email:any)
  {
    return this.httpClient.get<{user:any}>(`${this.URL}/produit/article/${email}`);
  }
  getid(num:any)
  {
    return this.httpClient.get<{user:any}>(`${this.URL}/abonner/id/${num}`);
  }
    getsuivieabonner(email:any)
  {
    return this.httpClient.get<{user:any}>(`${this.URL}/abonner/suivie/${email}`);

  
  }

  getnbreproduct(email:any)
  {
    return this.httpClient.get<{user:any}>(`${this.URL}/product/${email}`);

  
  }
  getsuivieabonner2(email:any,suivie:any)
  {
    return this.httpClient.get<{user:any}>(`${this.URL}/abonner/${email}/${suivie}`);

  
  }
  updatesuivie(user:any) {
    return this.httpClient.put(`${this.URL}/abonner`,user);
  }


  updateabonnernumero(numero:any) {
    console.log("numero"+numero)
    return this.httpClient.put(`${this.URL}/abonnernumero`,numero);
  }

  getAllvus(email:any) {
    return this.httpClient.get<{vus:any}>(`${this.URL}/vus/${email}`);
  }


  updateUserService2(user:any) {
    return this.httpClient.put(`${this.URL}/users`,user);
  }
  updateUserService3(user:any) {
    return this.httpClient.put(`${this.URL}/users2`,user);
  }


  updateUserServicenumero(user:any) {
    return this.httpClient.put<{message:any}>(`${this.URL}/usersnumero`,user);
  }
  updateUserServicenumero2(user:any) {
    return this.httpClient.put<{message:any}>(`${this.URL}/usersnumero2`,user);
  }
  updateUsertoken(user:any) {
    return this.httpClient.put<{message:any}>(`${this.URL}/userstoken`,user);
  }
  updateuserphoto(produit:any, multipleImages:any) {    
    console.log('pro2')
    let formData = new FormData();
    formData.append('pseudo',produit.pseudo);
    formData.append('numero',produit.numero);

    console.log('ddd'+multipleImages);
    for(let img of multipleImages){
      formData.append('files', img);
      console.log("444")
    }
    return this.httpClient.put<{message:any}>(`${this.URL}/updateuser`,formData);
  }
}