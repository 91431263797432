

  <!-- Page Content -->
  <div class="container1">

    <div class="row">

  
<hr>

      <!-- /.col-lg-3 -->

      <div class="col-lg-12">

        <form [formGroup]="slideOneForm" >
            <h1>ENTER OTP</h1>
            <div class="row teste">
      
            <div class="col">
              <div class="group">
      
                <input type="text" class="verf" (keyup)="tabChange()" maxlength="1" [(ngModel)]="id" formControlName="id"
                  [class.invalid]="!slideOneForm.controls.id.valid && (slideOneForm.controls.id.dirty || submitAttempt)"
                  required="required" />
      
              </div>
              
            </div>
            <div class="col">
              <div class="group">
      
                <input type="text" (keyup)="tabChange2()"   id="sec" class="verf" maxlength="1" [(ngModel)]="id2" formControlName="id2"
                  [class.invalid]="!slideOneForm.controls.id2.valid && (slideOneForm.controls.id2.dirty || submitAttempt)"
                  required="required" />
      
              </div>
              
            </div>
      
      
            <div class="col">
              <div class="group">
      
                <input type="text" class="verf" maxlength="1" (keyup)="tabChange3()" id="trois"[(ngModel)]="id3" formControlName="id3"
             [class.invalid]="!slideOneForm.controls.id3.valid && (slideOneForm.controls.id3.dirty || submitAttempt)"
                  required="required" />
      
              </div>
              
            </div>
      
            <div class="col">
              <div class="group">
      
                <input type="text" class="verf" maxlength="1" [(ngModel)]="id4" (keyup)="tabChange4()" id="quatre" formControlName="id4"
                  [class.invalid]="!slideOneForm.controls.id4.valid && (slideOneForm.controls.id4.dirty || submitAttempt)"
                  required="required" />
      
              </div>
              
            </div>
            <div class="col">
              <div class="group">
      
                <input type="text" class="verf" maxlength="1" [(ngModel)]="id5" formControlName="id5" id="cinq" (keyup)="tabChange5()" 
                  [class.invalid]="!slideOneForm.controls.id5.valid && (slideOneForm.controls.id5.dirty || submitAttempt)"
                  required="required" />
      
              </div>
              
            </div>
      
            </div>
      
           
      
          <div class="enter">
        <button type="submit" float-right ion-button color="primary" id="six" (click)="ajoutagent()"> valider</button>
      </div>
      </form>
  

        
<!--<app-baniere></app-baniere>-->
     
        <!-- /.row -->

      </div>
      <!-- /.col-lg-9 -->

    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->

  <!-- Footer -->
 

