import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {StorageCrypter} from 'storage-crypter';

import {SusersService} from'../../providers/susers.service';
import {ProduitService}from '../../providers/produit.service';
@Component({
  selector: 'app-detaille-produit',
  templateUrl: './detaille-produit.component.html',
  styleUrls: ['./detaille-produit.component.css']
})
export class DetailleProduitComponent implements OnInit {
  id:any;
  product:any={};
  user: any;
  users: any={};
  constructor(    private suser:SusersService,  private activatedRoute:ActivatedRoute,private router:Router,private Produitsservice:ProduitService  ) { }

  ngOnInit(): void {
    const storageCrypter = new StorageCrypter('Mohamedtlili1985*Syrinetlili2016*');
    this.users.email=storageCrypter.getItem('num', 'local');
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.Produitsservice.getProduitByIdService(this.id).subscribe(
      data => {
        this.product = data.produit;
      }
    )

    if (this.users.email)
{

 this.suser.getuserbyemail( this.users.email).subscribe(


  data => {

    this.user = data.user
   console.log("User:" + this.user.url_userimage)
 

  }
)



}
  }
  achat(id :any)
  {

    this.router.navigate([`achat/`+this.id])
  
  }

}

3