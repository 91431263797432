import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
;
import {SusersService} from '../../providers/susers.service';
@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
  users: any;
  constructor( private authService: SocialAuthService ,private suser:SusersService) {


    this.suser.getuserssuivieweb().subscribe(

      (data) => {
        this.users = data.user;
  
      }
    )
      

  }

  ngOnInit(): void {
    // this.authService.authState.subscribe((user) => {
    //   this.user = user;
    //   this.loggedIn = (user != null);

    // });
  }
 

}
