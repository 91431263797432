import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SabonnerService {
  URL = 'https://lebesty.com:3000' ;
  constructor(private httpClient:HttpClient) 
  
  { }


  add(abonner:any) {
    return this.httpClient.post(`${this.URL}/addabonner`, abonner);
  }


  getAllcouleurService() {
    return this.httpClient.get<{couleurs:any}>(`${this.URL}/abonner`);
  }

}
