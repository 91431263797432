


<div class="container1">

    <h2>{{Commandes.length}} Commande Confirmer</h2>

    <div class="row">
        <div class="col-3">
            <div class="mar">




                    <ul class="navbar-nav ml-auto"> <!-- Utilisation de ml-auto pour aligner vers la droite -->
                        <li class="nav-item active">
                            <a class="nav-link"  routerLink="/tab/tab/">Commandes en cours <span
                                    class="sr-only">(current)</span></a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/annuler">Commandes Anuuler</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/confirmer">Commandes Confirmer</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/envoyer">Commandes Envoyée</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/livrer">Commandes Livrée</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/tab/tab/finaliser">Commandes Finaliser</a>
                        </li>
                    
                    </ul>

             

            </div>
        </div>


        <div class="col-9">
            <div class="mat-elevation-z8" style="overflow-x: auto;">
            <table class="table" style="width: auto; ">
                <thead>
                  <tr>
                    <th scope="col"  >Date  Commande</th>
                    <th scope="col"  >Date  Confirmation</th>
                    <th scope="col" >Nom Vendeur</th>
            
                    <th scope="col" >Numéro</th>
            
                    <th scope="col">Nom Acheteur</th>
            
                    <th scope="col" >Numéro</th>
                  
                    <th scope="col"  >NOM</th>
               
                    <th scope="col">Marque</th>
            
                    <th scope="col">Prix</th>
             
                
            
                    <th scope="col"   >Annuler</th>
                    <th scope="col"   >Confirmer</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let commande of pagedCommandes">
                        <td class="datte">{{ formatDateString(commande.heure) }}</td>
                        <td class="datte">{{ formatDateString(commande.heure2) }}</td>
            
               
                   
                    <td class="vendeur">{{ commande.nom_Vendeur}}</td>
                    <td class="acheteur"></td>
                    <td class="acheteur">{{commande.numvendeur}}</td>
                   
                    <td class="acheteur">{{commande.numacheteur}}</td>
               
                    <td class="article">{{commande.nom_produit}}</td>
                
                    <td class="article">{{commande.marque}}</td>
                  
                    <td class="article">{{commande.prix}}</td>
              
            
              
                    <td  class="annuler"><button  (click)='annuler(commande)' >Annuler</button></td>
                    <td  class="annuler"><button (click)='confirmer(commande)'>Confirmer</button></td>
                  </tr>
                  <!-- Ajoutez d'autres lignes selon vos besoins -->
                </tbody>
              </table>
            </div>
            <div class="pagination">
                <button class="page-button" (click)="previousPage()">Previous</button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button class="page-button" (click)="nextPage()">Next</button>
    
            </div>
        </div>

       
    </div>
</div>