import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacteconfirmer',
  templateUrl: './contacteconfirmer.component.html',
  styleUrls: ['./contacteconfirmer.component.css']
})
export class ContacteconfirmerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
